import { useContext } from 'react';

import { InterviewerContext } from '../../Context';

function useInterviewerProvider() {
  const {
    candidateDetails,
    setCandidateDetails,
    setInterviewerFilters,
    interviewerFilters,
    createCandidateDetails,
    getInterviewQuestions,
    interviewerListOfQuestions,
    setOverlay,
    overlay,
    UpdateCandidateHandler,
    setIsLoading,
    isLoading,
    getCandidateHistory,
    candidateHistory,
    candidateHistoryPayload,
    setToastifyMessage,
    toastifyMessage,
    getCandidateInterviewDetails,
    setExistingCandidateHistory,
    existingCandidateHistory,
  } = useContext(InterviewerContext);

  return {
    candidateDetails,
    setCandidateDetails,
    setInterviewerFilters,
    interviewerFilters,
    createCandidateDetails,
    getInterviewQuestions,
    interviewerListOfQuestions,
    setOverlay,
    overlay,
    UpdateCandidateHandler,
    setIsLoading,
    isLoading,
    getCandidateHistory,
    candidateHistory,
    candidateHistoryPayload,
    setToastifyMessage,
    toastifyMessage,
    getCandidateInterviewDetails,
    setExistingCandidateHistory,
    existingCandidateHistory,
  };
}

export { useInterviewerProvider };
