import { useContext } from "react";

import { InterviewContext } from "../../Context/Provider";

function useProvider() {
  const {
    verifyUserToken,
    userDetails,
    setPopupState,
    popupState,
    createSkill,
    skills,
    getSkills,
    createQuestion,
    mapSkills,
    skillSets,
    setUserDetails,
    getQuestions,
    listOfQuestions,
    getSkillSet,
    listOfSkillset,
    getUserDetails,
    displaySkillsets,
    questionDashboardFilters,
    setQuestionDashboardFilters,
    setIsFilters,
    isFilters,
    deleteQuestion,
    setToastMessage,
    toastMessage,
    deleteSkill,
    deleteMappedSkill,
    editQuestionSet,
    editSkillSet,
    editSkillsetSet,
    getSubSkillObject,
    setIsLoading,
    isLoading,
    displaySkills,
    setListOfQuestions,
    getNavadhitiMembers,
    setNavadhitiMembers,
    navadhitiMembers,
    setInterviewers,
    interviewers,
    updateNavadhitiMembersRoles,
    getInterviewers,
    getIndividualInterviewersEmail,
    individualinterviewersemail,
    setIndividualinterviewersEmail,
    setNavadhitiMembersPayload,
    navadhitiMembersPayload
  } = useContext(InterviewContext);

  return {
    verifyUserToken,
    userDetails,
    setPopupState,
    popupState,
    createSkill,
    skills,
    getSkills,
    createQuestion,
    mapSkills,
    skillSets,
    setUserDetails,
    getQuestions,
    listOfQuestions,
    getSkillSet,
    listOfSkillset,
    getUserDetails,
    displaySkillsets,
    questionDashboardFilters,
    setQuestionDashboardFilters,
    setIsFilters,
    isFilters,
    deleteQuestion,
    setToastMessage,
    toastMessage,
    deleteSkill,
    deleteMappedSkill,
    editQuestionSet,
    editSkillSet,
    editSkillsetSet,
    getSubSkillObject,
    setIsLoading,
    isLoading,
    displaySkills,
    setListOfQuestions,
    getNavadhitiMembers,
    setNavadhitiMembers,
    navadhitiMembers,
    setInterviewers,
    interviewers,
    updateNavadhitiMembersRoles,
    getInterviewers,
    getIndividualInterviewersEmail,
    individualinterviewersemail,
    setIndividualinterviewersEmail,
    setNavadhitiMembersPayload,
    navadhitiMembersPayload,
  };
}

export { useProvider };
