import React from "react";

import PropTypes from "prop-types";
import { Form } from "react-bootstrap";

// import borderColors from "./TextAreaField.scss";

const TextAreaField = (props) => {
    const {
        name,
        label,
        type,
        errorMessage,
        onChange,
        id,
        className,
        onFocus,
        onKeyUp,
        errors,
        required,
        disabled,
        min,
        ...inputProps
    } = props;

    const onKeyDown = (evt) => {
    
    return (
      type === "number" ?
      (evt.key === "e" || evt.key === "-" || evt.key === ".") &&
      evt.preventDefault():(evt.target.value.length  === 0 && evt.key === " " && evt.preventDefault())
    );
  };

    return (
        <>
        <div className="form_Text_Feild" >
            <Form.Group
                className="mb-3"
                // controlId="exampleForm.ControlTextarea1"
            >
                <Form.Label className="label">
                  
                    {label}
                    {required}
                </Form.Label>
                <Form.Control
                // style={{whiteSpace:"nowrap"}}
                id={name}
                    as="textarea"
                    type="text"
                    {...inputProps}
                    disabled={disabled}
                    onKeyDown={onKeyDown}
                    onKeyUp={onKeyUp}
                    onChange={onChange}
                />
            </Form.Group>
            {!!props.error && props.touched && (
                <div style={{ color: "red", marginTop: ".5rem" }}>
                    {props.error}
                </div>
            )}
            </div>
        </>
    );
};

TextAreaField.propTypes = {
    /**
     * to connect to formik context.
     */
    name: PropTypes.string.isRequired,
};

export { TextAreaField };
