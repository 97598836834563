import { useEffect, useState } from "react";

import Report from "../../Assets/Images/Side_bar/report.svg";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router";

function SubMenu({ item, onMouseOut, onMouseOver }) {
    const [open, setOpen] = useState(true);
    const location = useLocation();
    const current_path = location.pathname;

    useEffect(() => {
        (current_path === "/questionDashbard" ||
            current_path === "/addSkills" ||
            current_path === "/skillSets" ||
            current_path === "/addInterviewers") &&
            setOpen(!open);
    }, []);

    const openMenuHandler = (e) => {
        e.stopPropagation();
        setOpen(!open);
    };

    if (item.childrens) {
        return (
            <div className={`sidebar-item`}>
                <div className="sidebar-title">
                    <span>
                        <span className="me-3">{item.icon}</span>
                        {item.title}
                    </span>
                    <img
                        src="https://cdn-icons-png.flaticon.com/128/8213/8213476.png"
                        alt=""
                        height="15px"
                        width="15px"
                        className="SideBar__imageSize me-3 mt-2"
                        onClick={openMenuHandler}
                        role="button"
                    ></img>
                    {/* <i className="bi-chevron-down toggle-btn" onClick={() => setOpen(!open)}></i> */}
                </div>
                <div
                    className={
                        !open ? "sidebar-content open" : "sidebar-content"
                    }
                >
                    {item.childrens.map((child, index) => (
                        <SubMenu
                            key={index}
                            item={child}
                            onMouseOver={onMouseOver}
                            onMouseOut={onMouseOut}
                        />
                    ))}
                </div>
            </div>
        );
    } else {
        return (
            <>
                {current_path === item.path ? (
                    <NavLink
                        to={item.path || "#"}
                        href={item.path || "#"}
                        className={"sidebar-item active open"}
                        onMouseOver={onMouseOver}
                        onMouseOut={onMouseOut}
                        onClick={() => setOpen(false)}
                    >
                        <span className="me-3"> {item.icon}</span>
                        {item.title}
                    </NavLink>
                ) : (
                    <NavLink
                        to={item.path || "#"}
                        className={"sidebar-item plain open"}
                        onMouseOver={onMouseOver}
                        onMouseOut={onMouseOut}
                        onClick={() => setOpen(true)}
                    >

                        <span className="me-3"> {item.icon} </span>
                        {item.title}
                    </NavLink>
                )}
            </>
        );
    }
}

export { SubMenu };
