import React, { useState } from "react";

import { Form, InputGroup } from "react-bootstrap";

import { Label } from "../Label";
import "./InputField.scss";

function InputField(props) {
  const {
    label,
    label1,
    type,
    errorMessage,
    onChange,
    id,
    className,
    onFocus,
    onKeyUp,
    errors,
    required,
    disabled,
    min,
    max,
    prevState,
    postState,
    prevClass,
    postClass,
    oninput,
    ...inputProps
  } = props;

  const onKeyDown = (evt) => {
    
    return (
      type === "number" ?
      (evt.key === "e" || evt.key === "-" || evt.key === ".") &&
      evt.preventDefault():(evt.target.value.length  === 0 && evt.key === " " && evt.preventDefault())
    );
  };

  // const inputChangeHandler = (e) => {
  
  //   if(e.target.value.length === 0 && e.target.value === " "){

  //     e.preventDefault();
  //   } else {
  //     onChange(e);
  //   }
  // }

  return (
    <div className="formInput">
      <Form.Group>
        <div className={`d-flex ${label1 && "justify-content-between"}`}>
          <Label
            name={label}
            classused="Label_normal"
            id="minMarks"
            required={required}
          />
          {label1 && (
            // <div>
              <Label
                name={label1}
                classused="Label_normal"
                id="minMarks"
                required={required}
              />
            // </div>
          )}
        </div>
        <InputGroup className="mb-3">
        <InputGroup.Text className={`p-0 text-black ${props.prevClass}`}>{props.prevState}</InputGroup.Text>
        <Form.Control
          type={type}
          onChange={onChange}
          disabled={disabled}
          onKeyUp={onKeyUp}
          onKeyDown={onKeyDown}
          {...inputProps}
          autoComplete="off"
          onInput={props.oninput}
        />
        <InputGroup.Text className={`p-0 text-black ${props,postClass}`}>{props.postState}</InputGroup.Text>
        </InputGroup>
      </Form.Group>
      {!!props.error && props.touched && (
        <div style={{ color: "red", marginTop: ".5rem" }}>{props.error}</div>
      )}
    </div>
  );
}

export { InputField };