import React from "react";

import Badge from "react-bootstrap/Badge";
import "./Pills.scss";

function Pills({ className, practical, skill, skills, bg,pillClass }) {
    return (
        <>
            <Badge
                pill
                bg={bg}
                displayname={practical}
                className={`mx-1 ${className} ${pillClass}`}
            >
                {practical}
            </Badge>
        </>
    );
}

export { Pills };
