import { useEffect, useReducer } from "react";

import axios from "axios";
import { cloneDeep } from "lodash";
import { useNavigate } from "react-router";

const ACTIONS = {
  set_popup_state: "set_popup_state",
  set_skills: "set_skills",
  set_skill_sets: "set_skill_sets",
  set_user_details: "set_user_details",
  set_list_of_questions: "set_list_of_questions",
  set_display_skillsets: "set_display_skillsets",
  set_question_dashboard_filters: "set_question_dashboard_filters",
  set_is_filters: "set_is_filters",
  set_toast_message: "set_toast_message",
  set_is_loading: "set_is_loading",
  set_display_skills: "set_display_skills",
  set_navadhiti_members: "set_navadhiti_members",
  set_interviwers: "set_interviwers",
  set_individualinterviewers_email: "set_individualinterviewers_email",
  set_navadhiti_members_payload: "set_navadhiti_members_payload",
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.set_popup_state:
      return {
        ...state,
        popupState: action.data,
      };
    case ACTIONS.set_skills:
      return {
        ...state,
        skills: action.data,
      };
    case ACTIONS.set_skill_sets:
      return {
        ...state,
        skillSets: action.data,
      };
    case ACTIONS.set_user_details:
      return {
        ...state,
        userDetails: action.data,
      };
    case ACTIONS.set_list_of_questions:
      return {
        ...state,
        listOfQuestions: action.data,
      };
    case ACTIONS.set_list_of_skillset:
      return {
        ...state,
        listOfSkillset: action.data,
      };
    case ACTIONS.set_display_skillsets:
      return {
        ...state,
        displaySkillsets: action.data,
      };
    case ACTIONS.set_question_dashboard_filters:
      return {
        ...state,
        questionDashboardFilters: action.data,
      };
    case ACTIONS.set_is_filters:
      return {
        ...state,
        isFilters: action.data,
      };
    case ACTIONS.set_toast_message:

      return {
        ...state,
        toastMessage: action.data,
      };
    case ACTIONS.set_is_loading:
      return {
        ...state,
        isLoading: action.data,
      };
    case ACTIONS.set_display_skills:
      return {
        ...state,
        displaySkills: action.data,
      };
    case ACTIONS.set_navadhiti_members:
      return {
        ...state,
        navadhitiMembers: action.data,
      };
    case ACTIONS.set_interviwers:
      return {
        ...state,
        interviewers: action.data,
      };
    case ACTIONS.set_individualinterviewers_email:
      return {
        ...state,
        individualinterviewersemail: action.data,
      };
    case ACTIONS.set_navadhiti_members_payload:
      return {
        ...state,
        navadhitiMembersPayload: action.data,
      };
    default:
  }
};

function useInterview() {
  const INITIAL_STATE = {
    popupState: false,
    skills: [],
    skillSets: [{ value: "ALL", label: "All" }],
    userDetails: JSON.parse(localStorage.getItem("userDetails")) || {},
    listOfQuestions: [],
    listOfSkillset: [],
    displaySkillsets: [],
    questionDashboardFilters: {
      skillLevel: {
        value: "ALL",
        label: "All",
      },
      skillId: {
        value: "ALL",
        label: "All",
      },
      search: "",
      isPractical: false,
      pageNo: 0,
      pageSize: { value: 10, label: 10 },
    },
    isFilters: {
      skillLevel: "",
      skillId: "",
      search: "",
      isPractical: false,
      pageNo: 0,
      pageSize: 10,
    },
    toastMessage: {},
    isLoading: false,
    displaySkills: [],
    navadhitiMembers: [],
    interviewers: [],
    individualinterviewersemail: {},
    navadhitiMembersPayload: {
      search: "",
      role: "",
      pageNo: 0,
      pageSize: 20,
      getAll: false
    }
  };

  const [state, setState] = useReducer(reducer, INITIAL_STATE);
  const candidateDetails = JSON.parse(localStorage.getItem("candidateDetails"))

  const navigate = useNavigate();
  const baseUrl = process.env.React_App_Base_Url + "/api/";

  const setPopupState = (data) => {
    setState({ type: ACTIONS.set_popup_state, data });
  };

  const setSkills = (data) => {
    setState({ type: ACTIONS.set_skills, data });
  };

  const setSkillSets = (data) => {
    setState({ type: ACTIONS.set_skill_sets, data });
  };

  const setUserDetails = (data) => {
    setState({ type: ACTIONS.set_user_details, data: data });
  };

  const setListOfQuestions = (data) => {
    setState({ type: ACTIONS.set_list_of_questions, data });
  };

  const setListOfSkillSet = (data) => {
    setState({ type: ACTIONS.set_list_of_skillset, data });
  };

  const setDisplaySkillSets = (data) => {
    setState({ type: ACTIONS.set_display_skillsets, data });
  };

  const setQuestionDashboardFilters = (data) => {
    setState({ type: ACTIONS.set_question_dashboard_filters, data });
  };

  const setIsFilters = (data) => {
    setState({ type: ACTIONS.set_is_filters, data });
  };

  const setToastMessage = (data) => {

    setState({ type: ACTIONS.set_toast_message, data });
  };

  const setIsLoading = (data) => {
    setState({ type: ACTIONS.set_is_loading, data });
  };

  const setDisplaySkills = (data) => {
    setState({ type: ACTIONS.set_display_skills, data });
  };

  const setNavadhitiMembers = (data) => {
    setState({ type: ACTIONS.set_navadhiti_members, data });
  };

  const setInterviewers = (data) => {
    setState({ type: ACTIONS.set_interviwers, data })
  }

  const setIndividualinterviewersEmail = (data) => {
    setState({ type: ACTIONS.set_individualinterviewers_email, data })
  }

  const setNavadhitiMembersPayload = (data) => {
    setState({ type: ACTIONS.set_navadhiti_members_payload, data })
  }

  const verifyUserToken = (credential) => {
    setIsLoading(true);
    return axios({
      method: "POST",
      url: baseUrl + "auth/token",
      data: { authToken: credential },
    })
      .then((res) => {
        const { data } = res;

        if (data?.status === "success") {
          return res;
        } else {
          setToastMessage({ type: "error", message: data?.responseMsg });
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);

        if (err.response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("userDetails");
          localStorage.removeItem("candidateDetails");
          localStorage.removeItem("totalScores");
          localStorage.removeItem("questionDetails");
          navigate("/");
          setToastMessage({ type: "error", message: err?.response?.data?.message });
        } else {
          setToastMessage({ type: "error", message: err?.response?.statusText });
        }
      });
  };

  const getUserDetails = (credential) => {
    credential ? credential?.pageNo <= 0 && setIsLoading(true) : setIsLoading(true);
    axios({
      method: "GET",
      url: baseUrl + "interview/services/employeeDetails/loggedInEmployee",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${credential}`,
      },
    })
      .then((res) => {
        const { data } = res;
        if (data.status === "success") {
          localStorage.setItem("userDetails", JSON.stringify(data?.data));
          setUserDetails(data?.data);
          getSkills();
        }
        setIsLoading(false);

        return res;
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("userDetails");
          localStorage.removeItem("candidateDetails");
          localStorage.removeItem("totalScores");
          localStorage.removeItem("questionDetails");
          localStorage.removeItem("activeTab");
          navigate("/");
          setToastMessage({ type: "error", message: err?.response?.data?.message });
        } else {
          setToastMessage({ type: "error", message: err?.response?.statusText });
        }
      });
  };

  const createSkill = (data) => {
    setIsLoading(true)
    axios({
      method: "POST",
      url: baseUrl + "interview/services/skill/create",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: data,
    })
      .then((res) => {
        const { data } = res;
        if (data?.status === "success") {
          // Create toast

          setToastMessage({ type: "success", message: data?.responseMsg });
          getSkills();

        } else {
          setToastMessage({ type: "error", message: res?.data?.responseMsg });

        }
        setIsLoading(false)
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("userDetails");
          localStorage.removeItem("candidateDetails");
          localStorage.removeItem("totalScores");
          localStorage.removeItem("questionDetails");
          localStorage.removeItem("activeTab");
          navigate("/");
          setToastMessage({ type: "error", message: err?.response?.data?.message });
        } else {
          setToastMessage({ type: "error", message: err?.response?.statusText });
        }
      });
  };

  const deleteSkill = (data) => {
    axios({
      method: "DELETE",
      url: baseUrl + "interview/services/skill/delete",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      params: { id: data },
    })
      .then((res) => {
        const { data } = res;
        if (data?.status === "success") {
          // Create toast
          getSkills();
        }

        setToastMessage({ type: "success", message: res?.data?.responseMsg });
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("userDetails");
          localStorage.removeItem("candidateDetails");
          localStorage.removeItem("totalScores");
          localStorage.removeItem("questionDetails");
          localStorage.removeItem("activeTab");
          navigate("/");
          setToastMessage({ type: "error", message: err?.response?.data?.message });
        } else {
          setToastMessage({ type: "error", message: err?.response?.statusText });
        }
      });
  };

  // function to convert subskills into label value object
  const getSubSkillObject = (data) => {
    const subSkill = [];
    for (let i = 0; i < data?.length; i++) {
      const skillObject = {
        value: data?.[i]?.skillName,
        label: data?.[i]?.skillName,
        skillIds: data?.[i]?.id
      };
      subSkill.push(skillObject);
    }
    return subSkill;
  };

  // function to convert skills into label value object
  const getSkillObject = (data) => {
    let finalData = [];
    for (let i = 0; i < data?.length; i++) {
      const skillObject = {
        value: data?.[i]?.skillName
          ? data?.[i]?.skillName
          : data?.[i]?.skillSetName,
        label: data?.[i]?.skillName
          ? data?.[i]?.skillName
          : data?.[i]?.skillSetName,
        skillIds: data?.[i]?.id,
      };

      finalData.push(skillObject);
    }

    const filteredData = finalData?.filter(
      (skill) => skill?.isSkilled === true
    );
    return { finalData, filteredData };
  };

  const getSkills = async () => {
    setIsLoading(true);
    const existingFilters = cloneDeep(state.questionDashboardFilters);

    await axios({
      method: "GET",
      url: baseUrl + "interview/services/skill/all",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        const { data } = res;
        if (data?.status === "success") {
          setSkills(data?.data);

          // To get the new object to display skills in dropdown
          if (data?.data?.length > 0) {
            const objectData = getSkillObject(data?.data);
            setSkillSets(objectData?.finalData);

            setDisplaySkillSets([
              ...state.displaySkillsets,
              ...objectData?.filteredData,
            ]);

            getQuestions();
          }
        } else {
          setToastMessage({ type: "error", message: data?.responseMsg });
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("userDetails");
          localStorage.removeItem("candidateDetails");
          localStorage.removeItem("totalScores");
          localStorage.removeItem("questionDetails");
          localStorage.removeItem("activeTab");
          navigate("/");
          setToastMessage({ type: "error", message: err?.response?.data?.message });
        } else {
          setToastMessage({ type: "error", message: err?.response?.statusText });
        }
      });
  };

  const getQuestions = async (data) => {
    data ? data?.pageNo <= 0 && setIsLoading(true) : setIsLoading(true);
    const getQuestions = cloneDeep(state.listOfQuestions);
    const isFilters = cloneDeep(state.isFilters);

    const params = data ? data : isFilters;
    delete params?.totalPages;

    let chatData = [];

    await axios({
      method: "GET",
      url: baseUrl + "interview/services/questions/getQuestions",
      params: params,
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        const { data } = res;

        if (data?.status === "success") {
          // Create toast
          if (data?.currentPage < 1) {
            chatData = data?.data;
          } else {
            chatData = [...getQuestions, ...data?.data];
          }
          setListOfQuestions(chatData);
          params.pageNo = data?.currentPage;
          params.totalPages = data?.totalPages;
          setIsFilters(params);
        } else {
          setListOfQuestions([]);
          setToastMessage({ type: "error", message: data?.responseMsg });
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("userDetails");
          localStorage.removeItem("candidateDetails");
          localStorage.removeItem("totalScores");
          localStorage.removeItem("questionDetails");
          localStorage.removeItem("activeTab");
          navigate("/");
          setToastMessage({ type: "error", message: err?.response?.data?.message });
        } else {
          setToastMessage({ type: "error", message: err?.response?.statusText });
        }
      });
  };

  const getSkillSet = async () => {
    setIsLoading(true);
    await axios({
      method: "GET",
      url: baseUrl + "interview/services/skill/getSkillSets",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        const { data } = res;

        if (data?.status === "success") {
          // Create toast
          setListOfSkillSet(data?.data);
          if (data?.data?.length > 0) {
            const objectData = getSkillObject(data?.data);

            setDisplaySkills(objectData?.finalData);
          }
        } else {
          setListOfSkillSet([]);

        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("userDetails");
          localStorage.removeItem("candidateDetails");
          localStorage.removeItem("totalScores");
          localStorage.removeItem("questionDetails");
          localStorage.removeItem("activeTab");
          navigate("/");
          setToastMessage({ type: "error", message: err?.response?.data?.message });
        } else {
          setToastMessage({ type: "error", message: err?.response?.statusText });
        }
      });
  };

  const createQuestion = (data) => {
    setIsLoading(true)
    axios({
      method: "POST",
      url: baseUrl + "interview/services/questions/create",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: data,
    })
      .then((res) => {
        const { data } = res;

        if (data?.status === "success") {
          // Create toast

          const isFilters = cloneDeep(state.isFilters);
          isFilters.pageNo = 0;
          getQuestions(isFilters);

          setToastMessage({ type: "success", message: data?.responseMsg });
        } else {
          setToastMessage({ type: "error", message: data?.responseMsg });
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("userDetails");
          localStorage.removeItem("candidateDetails");
          localStorage.removeItem("totalScores");
          localStorage.removeItem("questionDetails");
          localStorage.removeItem("activeTab");
          navigate("/");
          setToastMessage({ type: "error", message: err?.response?.data?.message });
        } else {
          setToastMessage({ type: "error", message: err?.response?.statusText });
        }
      });
  };

  const deleteQuestion = (data) => {
    axios({
      method: "DELETE",
      url: baseUrl + "interview/services/questions/delete",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      params: { id: data },
    })
      .then((res) => {
        const { data } = res;
        if (data?.status === "success") {
          // Create toast
          const isFilters = cloneDeep(state.isFilters);
          isFilters.pageNo = 0;
          getQuestions(isFilters);

          setToastMessage({ type: "success", message: data?.responseMsg });
        } else {
          setToastMessage({ type: "error", message: data?.responseMsg });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("userDetails");
          localStorage.removeItem("candidateDetails");
          localStorage.removeItem("totalScores");
          localStorage.removeItem("questionDetails");
          localStorage.removeItem("activeTab");
          navigate("/");
          setToastMessage({ type: "error", message: err?.response?.data?.message });
        } else {
          setToastMessage({ type: "error", message: err?.response?.statusText });
        }
      });
  };

  const mapSkills = (data) => {
    setIsLoading(true)
    axios({
      method: "POST",
      url: baseUrl + "interview/services/skill/addSkillSet",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: data,
    })
      .then((res) => {
        const { data } = res;

        if (data?.status === "success") {
          // Create toast

          setToastMessage({ type: "success", message: res?.data?.responseMsg });
          getSkillSet();

        }
        setIsLoading(false)
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("userDetails");
          localStorage.removeItem("candidateDetails");
          localStorage.removeItem("totalScores");
          localStorage.removeItem("questionDetails");
          localStorage.removeItem("activeTab");
          navigate("/");
          setToastMessage({ type: "error", message: err?.response?.data?.message });
        } else {
          setToastMessage({ type: "error", message: err?.response?.statusText });
        }
      });
  };

  const deleteMappedSkill = (data) => {
    axios({
      method: "DELETE",
      url: baseUrl + "interview/services/skill/deleteSkillSet",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      params: { id: data },
    })
      .then((res) => {
        const { data } = res;
        if (data?.status === "success") {
          // Create toast
          getSkillSet();

          setToastMessage({ type: "success", message: data?.responseMsg });
        } else {
          setToastMessage({ type: "error", message: data?.responseMsg });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("userDetails");
          localStorage.removeItem("candidateDetails");
          localStorage.removeItem("totalScores");
          localStorage.removeItem("questionDetails");
          localStorage.removeItem("activeTab");
          navigate("/");
          setToastMessage({ type: "error", message: err?.response?.data?.message });
        } else {
          setToastMessage({ type: "error", message: err?.response?.statusText });
        }
      });
  };

  const editQuestionSet = async (data) => {
    try {
      const result = await axios({

        method: "GET",
        url: baseUrl + "interview/services/questions/findById",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        params: { id: data },
      });

      return result.data;
    } catch (err) {
      setIsLoading(false);
      if (err.response.status === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("userDetails");
        localStorage.removeItem("candidateDetails");
        localStorage.removeItem("totalScores");
        localStorage.removeItem("questionDetails");
        localStorage.removeItem("activeTab");
        navigate("/");
        setToastMessage({ type: "error", message: err?.response?.data?.message });
      } else {
        setToastMessage({ type: "error", message: err?.response?.statusText });
      }
    }
  };

  const editSkillSet = (data) => {
    axios({
      method: "GET",
      url: baseUrl + "interview/services/skill/findByIdSkill",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      params: { id: data },
    })
      .then((res) => {
        const { data } = res;

        if (data?.status === "success") {
          // Create toast
          // getSkillSet();

          // setToastMessage({type: "success", message:data?.responseMsg});

        }
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("userDetails");
          localStorage.removeItem("candidateDetails");
          localStorage.removeItem("totalScores");
          localStorage.removeItem("questionDetails");
          localStorage.removeItem("activeTab");
          navigate("/");
          setToastMessage({ type: "error", message: err?.response?.data?.message });
        } else {
          setToastMessage({ type: "error", message: err?.response?.statusText });
        }
      });
  };

  const editSkillsetSet = (data) => {
    axios({
      method: "GET",
      url: baseUrl + "interview/services/skill/findByIdSkillSet",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      params: { id: data },
    })
      .then((res) => {
        const { data } = res;
        if (data?.status === "success") {
          // Create toast          
          setToastMessage({ type: "error", message: data?.responseMessage });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("userDetails");
          localStorage.removeItem("candidateDetails");
          localStorage.removeItem("totalScores");
          localStorage.removeItem("questionDetails");
          localStorage.removeItem("activeTab");
          navigate("/");
          setToastMessage({ type: "error", message: err?.response?.data?.message });
        } else {
          setToastMessage({ type: "error", message: err?.response?.statusText });
        }
      });
  };

  // function to convert data into label value object
  const generateArrayOfObject = (data) => {
    const arrayOfObject = [];
    for (let i = 0; i < data?.length; i++) {
      const createObject = {
        value: data?.[i]?.empMailId,
        label: data?.[i]?.empMailId,
        role: data?.[i]?.role,
        name: data?.[i]?.empName,
      };
      arrayOfObject.push(createObject);
    }
    return arrayOfObject;
  };

  // Get all the navadhiti emaiids
  const getNavadhitiMembers = async (filters) => {
    state.navadhitiMembers?.length === 0 && setIsLoading(true);
    const params = filters ? filters : state.navadhitiMembersPayload;
    delete params?.totalPages;

    await axios({
      method: "GET",
      url: baseUrl + "interview/services/employeeDetails/all",
      params,
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        const { data } = res;
        if (data?.status === "success") {
          const initialObject = [{ value: "ALL", label: "All" }]
          const allMembers = generateArrayOfObject(data?.data);
          const finalData = data?.currentPage > 0 ? [...state.navadhitiMembers, ...allMembers] : [...initialObject, ...allMembers]
          setNavadhitiMembers(finalData);

          params.pageNo = data?.currentPage;
          params.totalPages = data?.totalPages;
          setNavadhitiMembersPayload(params);
        } else {
          setNavadhitiMembers([]);
          setInterviewers([]);
        }
        setIsLoading(false)

      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("userDetails");
          localStorage.removeItem("candidateDetails");
          localStorage.removeItem("totalScores");
          localStorage.removeItem("questionDetails");
          localStorage.removeItem("activeTab");
          navigate("/");
          setToastMessage({ type: "error", message: err?.response?.data?.message });
        } else {
          setToastMessage({ type: "error", message: err?.response?.statusText });
        }
      });
  };

  // Get all the navadhiti emaiids
  const updateNavadhitiMembersRoles = async (data, filter) => {
    setIsLoading(true)
    await axios({
      method: "PUT",
      url: baseUrl + "interview/services/employeeDetails/updateById",
      data,
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setIsLoading(false)
        const { data } = res;
        if (data?.status === "success") {
          setToastMessage({ type: "success", message: data?.responseMsg });
          getNavadhitiMembers(filter);
        } else {
          setToastMessage({ type: "error", message: data?.responseMsg });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("userDetails");
          localStorage.removeItem("candidateDetails");
          localStorage.removeItem("totalScores");
          localStorage.removeItem("questionDetails");
          localStorage.removeItem("activeTab");
          navigate("/");
          setToastMessage({ type: "error", message: err?.response?.data?.message });
        } else {
          setToastMessage({ type: "error", message: err?.response?.statusText });
        }
      });
  };

  // function to convert data into label value object
  const generateListOfObject = (data) => {
    const arrayOfObject = [];
    for (let i = 0; i < data?.length; i++) {
      const createObject = {
        value: data?.[i],
        label: data?.[i],
      };
      arrayOfObject.push(createObject);
    }
    return arrayOfObject;
  };

  const getInterviewers = async () => {
    try {
      const result = await axios({

        method: "GET",
        url: baseUrl + "interview/services/email/all",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (result?.data?.status !== "success") {
        setToastMessage({ type: "error", message: result?.data?.responseMsg });
      } else {
        const interviewersToObject = generateListOfObject(result.data?.data?.toEmail);
        const interviewersCCObject = generateListOfObject(result.data?.data?.ccEmail);
        return { interviewersToObject, interviewersCCObject };
      }
    } catch (err) {
      setIsLoading(false);
      if (err.response.status === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("userDetails");
        localStorage.removeItem("candidateDetails");
        localStorage.removeItem("totalScores");
        localStorage.removeItem("questionDetails");
        localStorage.removeItem("activeTab");
        navigate("/");
        setToastMessage({ type: "error", message: err?.response?.data?.message });
      } else {
        setToastMessage({ type: "error", message: err?.response?.statusText });
      }
    }
  }


  const getIndividualInterviewersEmail = async (data, id) => {
    await axios({
      method: "POST",
      url: baseUrl + `interview/services/email/sendEmail/${id}`,
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: {
        "toEmailIds": data.toEmail,
        "ccEmailIds": data.ccEmail
      },
    })
      .then((res) => {
        const { data } = res;
        if (data?.status === "success") {
          const allMembers = generateArrayOfObject(data?.data);
          setIndividualinterviewersEmail(allMembers);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("userDetails");
          localStorage.removeItem("candidateDetails");
          localStorage.removeItem("totalScores");
          localStorage.removeItem("questionDetails");
          localStorage.removeItem("activeTab");
          navigate("/");
          setToastMessage({ type: "error", message: err?.response?.data?.message });
        } else {
          setToastMessage({ type: "error", message: err?.response?.statusText });
        }
      });
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      getSkills();
      getSkillSet();
    }
  }, []);

  return {
    setPopupState,
    popupState: state.popupState,
    verifyUserToken,
    createSkill,
    skills: state.skills,
    getSkills,
    createQuestion,
    editQuestionSet,
    editSkillSet,
    editSkillsetSet,
    mapSkills,
    skillSets: state.skillSets,
    getUserDetails,
    setUserDetails,
    userDetails: state.userDetails,
    getQuestions,
    listOfQuestions: state.listOfQuestions,
    getSkillSet,
    listOfSkillset: state.listOfSkillset,
    displaySkillsets: state.displaySkillsets,
    setQuestionDashboardFilters,
    questionDashboardFilters: state.questionDashboardFilters,
    setIsFilters,
    isFilters: state.isFilters,
    deleteQuestion,
    setToastMessage,
    toastMessage: state.toastMessage,
    deleteSkill,
    deleteMappedSkill,
    getSubSkillObject,
    setIsLoading,
    isLoading: state.isLoading,
    displaySkills: state.displaySkills,
    setListOfQuestions,
    getNavadhitiMembers,
    setNavadhitiMembers,
    navadhitiMembers: state.navadhitiMembers,
    setInterviewers,
    interviewers: state.interviewers,
    updateNavadhitiMembersRoles,
    getInterviewers,
    getIndividualInterviewersEmail,
    individualinterviewersemail: state.individualinterviewersemail,
    setIndividualinterviewersEmail,
    setNavadhitiMembersPayload,
    navadhitiMembersPayload: state.navadhitiMembersPayload,
  };
}

export { useInterview };
