import React, { useState, useRef } from 'react';

import './SidePanel.scss';

function SidePanel(props) {
  const [activeSize, setActiveSize] = useState(false);
  const content = useRef(null);

  return (
    <>
      <div className="SidePanel__sections ">
        <div
          className="SidePanel__screenSize1"
          onClick={props.toggleScreenSize}
        ></div>
        <div
          ref={content}
          className={`SidePanel__screenSize ${activeSize && 'notActive'}`}
        >
          <p className="SidePanel__title">{props.title}</p>
          <div className="SidePanel__text">{props.children}</div>
        </div>
      </div>
    </>
  );
}

export { SidePanel };
