import React, { useState, useEffect, useRef } from "react";

import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";

import companyImage from "../../Assets/Images/Side_bar/navadhiti.svg";
import companyLogo from "../../Assets/Images/Side_bar/logo.png";
import AddQuestions from "../../Assets/Images/Side_bar/questionAddImg.svg";
import AddSkills from "../../Assets/Images/Side_bar/addskills.svg";
import MapSkills from "../../Assets/Images/Side_bar/mapskills.svg";
import DashboardBlack from "../../Assets/Images/Side_bar/dashboard_black.svg";
import Dashboard from "../../Assets/Images/Side_bar/dashboard.svg";
import Interview from "../../Assets/Images/Side_bar/interviewLogo.svg";
import CandidateDetails from "../../Assets/Images/Side_bar/Cadidate.svg";
import Manage from "../../Assets/Images/Side_bar/manage.svg";
import Report from "../../Assets/Images/Side_bar/report.svg";
import AddQuestionsBlack from "../../Assets/Images/Side_bar/questionAddImg_black.svg";
import AddSkillsBlack from "../../Assets/Images/Side_bar/addskills_black.svg";
import MapSkillsBlack from "../../Assets/Images/Side_bar/mapskills_black.svg";
import InterviewBlack from "../../Assets/Images/Side_bar/interviewLogo_black.svg";
import CandidateDetailsBlack from "../../Assets/Images/Side_bar/Cadidate_black.svg";
import ManageBlack from "../../Assets/Images/Side_bar/manage_black.svg";
import ReportBlack from "../../Assets/Images/Side_bar/report_black.svg";
import { Header } from "../Header";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { SubMenu } from "./SubMenu";
import "./Sidebar.scss";

import Offcanvas from "react-bootstrap/Offcanvas";

function Sidebar({ children }) {
    const location = useLocation();
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const [highlight, setHighlight] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [manage, setManage] = useState(false);
    const [home, setHome] = useState(false);
    const [addQuestion, setQuestion] = useState(false);
    const [addSkill, setAddSkill] = useState(false);
    const [mapskill, setMapskill] = useState(false);
    const [interviewer, setInterviewer] = useState(false);
    const [addInterviewer, setAddInterviewer] = useState(false);
    const [reports, setReports] = useState(false);

    const menuItem = [
        {
            path: "/",
            title: (
                <span
                    onMouseOver={() => setHome(true)}
                    onMouseOut={() => setHome(false)}
                >
                    Home
                </span>
            ),
            icon: (
                <img
                    src={home ? DashboardBlack : Dashboard}
                    onMouseOver={() => setHome(true)}
                    onMouseOut={() => setHome(false)}
                    alt=""
                    className="SideBar__imageSize"
                />
            ),
        },
        {
            title: (
                <span
                    onMouseOver={() => setAddInterviewer(true)}
                    onMouseOut={() => setAddInterviewer(false)}
                >
                    Interview
                </span>
            ),
            icon: (
                <img
                    src={addInterviewer ? InterviewBlack : Interview}
                    onMouseOver={() => setAddInterviewer(true)}
                    onMouseOut={() => setAddInterviewer(false)}
                    alt=""
                    className="SideBar__imageSize"
                ></img>
            ),
            path: "/interviewDashboard",
        },

        {
            title: (
                <span
                    onMouseOver={() => setReports(true)}
                    onMouseOut={() => setReports(false)}
                >
                    Reports
                </span>
            ),
            icon: (
                <img
                    src={reports ? ReportBlack : Report}
                    onMouseOver={() => setReports(true)}
                    onMouseOut={() => setReports(false)}
                    alt=""
                    className="SideBar__imageSize"
                ></img>
            ),
            path: "/reports",
        },
        {
            title: (
                <span
                    onMouseOver={() => setManage(true)}
                    onMouseOut={() => setManage(false)}
                >
                    Manage
                </span>
            ),
            icon: (
                <img
                    src={manage ? ManageBlack : Manage}
                    onMouseOver={() => setManage(true)}
                    onMouseOut={() => setManage(false)}
                    alt=""
                    className="SideBar__imageSize"
                ></img>
            ),
            childrens: [
                {
                    title: (
                        <span
                            onMouseOver={() => setAddSkill(true)}
                            onMouseOut={() => setAddSkill(false)}
                        >
                            Skills
                        </span>
                    ),
                    icon: (
                        <img
                            src={addSkill ? AddSkillsBlack : AddSkills}
                            onMouseOver={() => setAddSkill(true)}
                            onMouseOut={() => setAddSkill(false)}
                            alt=""
                            className="SideBar__imageSize"
                        ></img>
                    ),
                    path: "/addSkills",
                },
                {
                    title: (
                        <span
                            onMouseOver={() => setMapskill(true)}
                            onMouseOut={() => setMapskill(false)}
                        >
                            Skillsets
                        </span>
                    ),
                    icon: (
                        <img
                            src={mapskill ? MapSkillsBlack : MapSkills}
                            onMouseOver={() => setMapskill(true)}
                            onMouseOut={() => setMapskill(false)}
                            alt=""
                            className="SideBar__imageSize"
                        ></img>
                    ),
                    path: "/skillSets",
                },
                {
                    title: (
                        <span
                            onMouseOver={() => setQuestion(true)}
                            onMouseOut={() => setQuestion(false)}
                        >
                            Questions
                        </span>
                    ),
                    icon: (
                        <img
                            src={addQuestion ? AddQuestionsBlack : AddQuestions}
                            onMouseOver={() => setQuestion(true)}
                            onMouseOut={() => setQuestion(false)}
                            alt=""
                            className="SideBar__imageSize"
                        ></img>
                    ),
                    path: "/questionDashbard",
                },                                
                {
                    title: (
                        <span
                            onMouseOver={() => setInterviewer(true)}
                            onMouseOut={() => setInterviewer(false)}
                        >
                            Interviewers
                        </span>
                    ),
                    icon: (
                        <img
                            src={
                                interviewer
                                    ? CandidateDetailsBlack
                                    : CandidateDetails
                            }
                            onMouseOver={() => setInterviewer(true)}
                            onMouseOut={() => setInterviewer(false)}
                            alt=""
                            className="SideBar__imageSize"
                        ></img>
                    ),
                    path: "/addInterviewers",
                },
            ],
        },
    ];

    const path = location.pathname;
    const userDetailsLog = localStorage.getItem("userDetails");

    return (
        <>
            <Header className="desktop_View" />
            {userDetailsLog !== null && (
                <div className="QuestionDashboard__sidebar">
                    <nav
                        className={`SideBar__menu ${
                            highlight === menuItem ? "active" : ""
                        }}`}
                        tabIndex="0"
                       
                    >
                        <div className="SideBar__smartphone-menu-trigger"></div>
                        <header className="SideBar__avatar">
                            <img
                                src={companyImage}
                                alt=""
                                width="115px"
                                height="97px"
                            />
                        </header>
                        {menuItem?.map((item, index, event) => (
                            
                                <SubMenu key={index} item={item}>
                                    <NavLink to={item.path}>
                                        <div className="icon">{item.icon}</div>
                                        <div className="link_text">
                                            {item.title}
                                        </div>
                                    </NavLink>
                                </SubMenu>
                            
                        ))}

                        {/* { menuItem.map((item, index) => <SubMenu key={index} item={item} className="active">
              <NavLink
                  to={item.path}
                  key={index}
                  className={({ isActive }) => (isActive ? "link-active" : "links")}
                  activeClassName="link-active"
                >
                  <div className="icon">{item.img}</div>
                  <div className="link_text">{item.name}</div>
                </NavLink></SubMenu>) }            */}
                    </nav>
                    <main>{children}</main>
                </div>
            )}

            {[false].map((expand, index) => (
                <div key={index}>
                    {userDetailsLog !== null && (
                        <Navbar
                            key={expand}
                            expand={expand}
                            className="mb-5 MobileNav mx-3"
                        >
                            <Container className="navbar shadow-sm">
                                <Navbar.Brand href="#">
                                    {" "}
                                    <img
                                        src={companyLogo}
                                        alt=""
                                        width="60px"
                                        height="50px"
                                    />
                                </Navbar.Brand>
                                <Navbar.Toggle
                                    aria-controls={`offcanvasNavbar-expand-${expand}`}
                                    onClick={() => setExpanded(true)}
                                />

                                {expanded && (
                                    <Navbar.Offcanvas
                                        id={`offcanvasNavbar-expand-${expand}`}
                                        aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                                        placement="end"
                                    >
                                        <Offcanvas.Header closeButton>
                                            {/* <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  Offcanvas
                </Offcanvas.Title> */}
                                            <Offcanvas.Title
                                                id={`offcanvasNavbarLabel-expand-${expand}`}
                                            ></Offcanvas.Title>
                                        </Offcanvas.Header>

                                        <Offcanvas.Body className="p-0">
                                            <Header className="mobile_View" />
                                            <Nav className="justify-content-end flex-grow-1 pe-3" onClick={() => setExpanded(false)}>
                                                {menuItem?.map(
                                                    (item, index) => (
                                                        
                                                            <SubMenu
                                                                key={index}
                                                                item={item}
                                                            >
                                                                <NavLink
                                                                    to={
                                                                        item.path
                                                                    }
                                                                >
                                                                    <div className="icon">
                                                                        {
                                                                            item.icon
                                                                        }
                                                                    </div>
                                                                    <div className="link_text">
                                                                        {
                                                                            item.title
                                                                        }
                                                                    </div>
                                                                </NavLink>
                                                            </SubMenu>
                                                        
                                                    )
                                                )}
                                            </Nav>
                                        </Offcanvas.Body>
                                    </Navbar.Offcanvas>
                                )}
                            </Container>
                        </Navbar>
                    )}
                </div>
            ))}
        </>
    );
}

export { Sidebar };
