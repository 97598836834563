import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Container, Row, Col } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroller";
import Table from "react-bootstrap/Table";
import { cloneDeep } from "lodash";

import { useProvider } from "../hooks/useProvider/useProvider";
import { InterviewModal } from "../UI/InterviewModal";
import Delete from "../../Assets/Images/Manage/delete.svg";

import { InputField } from "../UI/InputField";
import leftArrow from "../../Assets/Images/Manage/leftArrow.svg";
import { Loader } from "../UI/Loader";
import { Button } from "../UI/Button";
import { Label } from "../UI/Label";
import "./AddInterviewers.scss";

const AddInterviewers = () => {
    const {
        navadhitiMembers,
        getNavadhitiMembers,
        updateNavadhitiMembersRoles,
        navadhitiMembersPayload,
        isLoading,
    } = useProvider();
    const navigate = useNavigate();
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));

    const [listOfMembers, setListOfMembers] = useState([]); // To list the members to display after removing all option
    const [selectedMembers, setSelectedMembers] = useState([]); // On add member, get list of selected member
    const [isInterviewers, setIsInterviewers] = useState(false); // To toggle between add interview and list of existing interview
    const [isSearch, setIsSearch] = useState(""); // to update search state
    const [over, setOver] = useState(false);

    let membersPayload = cloneDeep(navadhitiMembersPayload);

    // onclick of button and search handler
    const changeHandler = (type, data) => {
        if (type === "search") {
            setIsSearch(data);
            membersPayload.search = data;
        } else if (type === "role") {
            membersPayload.search = "";
            membersPayload.role = data;
        }
        membersPayload.pageNo = 0;
        getNavadhitiMembers(membersPayload);
    };

    // On add interview, update state based on multi select
    const selectHandler = (data) => {
        const newArray = [...selectedMembers];
        const selectedDataIndex = newArray.indexOf(data?.value);

        if (selectedDataIndex === -1) {
            newArray.push(data?.value);
        } else {
            newArray.splice(selectedDataIndex, 1);
        }
        setSelectedMembers(newArray);
    };

    //  To separate newly added mails and removed mails
    const submitHandler = async (data) => {
        let finalObject = {
            newEmails: selectedMembers,
            removedEmails: data ? [data?.value] : [],
        };
        setSelectedMembers([]);
        membersPayload.role = "INTERVIEWER";
        membersPayload.pageNo = 0;
        if (!data || !isInterviewers) {
            membersPayload.search = "";
            setIsSearch("");
        }
        updateNavadhitiMembersRoles(finalObject, membersPayload);
    };

    const totalPages = membersPayload?.totalPages;
    const currentPage = membersPayload?.pageNo;
    const [task1Show, setTask1Show] = useState(false);
    const [deleteValue, setDeleteValue] = useState("");

    // call API on scroll
    const loadMore = async () => {
        const page = currentPage + 1;
        if (page < totalPages) {
            membersPayload.pageNo = page;
            await getNavadhitiMembers(membersPayload);
        }
    };

    //   Go back onclick of back button
    const goBack = async () => {
        setIsSearch("");
        membersPayload.role = "INTERVIEWER";
        membersPayload.search = "";
        membersPayload.pageNo = 0;
        await getNavadhitiMembers(membersPayload);
    };

    // To update the state without ALL option
    useEffect(() => {
        if (navadhitiMembers?.length > 0) {
            const newArray = navadhitiMembers.filter((item) => {
                return item?.value !== "ALL";
            });
            setListOfMembers(newArray);
        }
    }, [navadhitiMembers]);

    // To get allnavadhiti members
    useEffect(() => {
        membersPayload.role = "INTERVIEWER";
        getNavadhitiMembers(membersPayload);
    }, []);

    // If user doesn't have access, then take them back to home page
    // if (userDetails?.role !== "ADMIN") {
    //     navigate("/");
    // }

    // Show loader if loading is true
    if (isLoading) {
        return <Loader />;
    }

    return (
        <div className="AddInterviewers">
            <Row className="align-items-center my-3">
                <Col lg={8} md={8} xs={12} className=" FilterBar__question">
                    {isInterviewers ? (
                        <>
                            <div className="d-flex align-items-center justify-content-start">
                                <span
                                    onClick={() => {
                                        goBack();
                                        setIsInterviewers(false);
                                    }}
                                    className=""
                                    role="button"
                                >
                                    <img
                                        src={leftArrow}
                                        className="w-120 closeImage align-self-center"
                                        alt=""
                                    ></img>
                                </span>
                                <Label
                                    name="Add Interviewers"
                                    classused="Label_bold align-self-center mx-3 my-2"
                                />
                            </div>
                        </>
                    ) : (
                        <Label name="Interviewers" classused="Label_bold" />
                    )}
                </Col>
                <Col xs="12" sm="12" md="4" lg="4" className="">
                    {isInterviewers ? (
                        <InputField
                            id="search"
                            name="search"
                            type="text"
                            placeholder="Search Members"
                            value={isSearch}
                            onChange={(e) => {
                                changeHandler("search", e.target.value);
                            }}
                        />
                    ) : (
                        <InputField
                            id="search"
                            name="search"
                            type="text"
                            placeholder="Search Interviewers"
                            value={isSearch}
                            onChange={(e) => {
                                changeHandler("search", e.target.value);
                            }}
                        />
                    )}
                </Col>
            </Row>
            {/* <Row className="align-items-center justify-content-between">
          <Col
            xs="12"
            sm="12"
            md="10"
            lg="9"
            className="my-3 align-self-center"
          >
            <InputField
              id="search"
              name="search"
              type="text"
              label="select Interviewers"
              value={isSearch}
              onChange={(e) => {
                changeHandler("search", e.target.value);
              }}
            />
          </Col>
        </Row> */}
            <Row>
                <InfiniteScroll
                    loadMore={loadMore}
                    hasMore={membersPayload?.pageNo + 1 < totalPages}
                    loader={<Loader key={0}/>}
                    initialLoad={false}
                    threshold={500}
                    useWindow={true}
                >
                    <Table hover responsive>
                        <thead className="bg-secondary  bg-opacity-25">
                            <tr className="fw-bold align-middle">{isInterviewers ? (
                                    <>
                                        <th className="fw-bold align-middle w-5">
                                         
                                            Select
                                        </th>
                                        <th className="fw-bold align-middle">
                                           
                                            
                                            Name
                                        </th>
                                        
                                       
                                        <th className="d-flex justify-content-between align-items-center align-middle ">
                                            Email{" "}
                                            <>
                                                <div className="d-flex justify-content-end">
                                                    <Button
                                                        value="Add"
                                                        onClick={() => {
                                                            submitHandler();
                                                            setIsInterviewers(
                                                                !isInterviewers
                                                            );
                                                        }}
                                                        disabled={
                                                            selectedMembers?.length ===
                                                            0
                                                        }
                                                        className=" "
                                                        
                                                    />
                                                  
                                                </div>
                                            </>
                                        </th>
                                    </>
                                ) : (
                                    <>
                                        <th className="fw-bold">
                                            Name
                                        </th>
                                        <th className="fw-bold">
                                            Email
                                        </th>
                                        <th className="d-flex justify-content-end ">
                                            <Button
                                                value="Add Interviewers"
                                                onClick={() => {
                                                    setIsInterviewers(true);
                                                    setIsSearch("");
                                                    changeHandler(
                                                        "role",
                                                        "MEMBER"
                                                    );
                                                }}
                                                className=""
                                                variant="secondary"
                                            />
                                        </th>
                                    </>
                                )}
                            </tr>
                        </thead>

                        <tbody className="align-middle">
                            {listOfMembers.length > 0 ? (
                                listOfMembers?.map((data, index) => (
                                    <tr key={index}>{isInterviewers && (
                                            <td className="align-middle mx-auto">
                                                <input
                                                    type="checkbox"
                                                    className="Interviewer_checkbox"
                                                    onClick={() => {
                                                        selectHandler(data);
                                                    }}
                                                    role="button"
                                                />
                                            </td>
                                        )}
                                        <td>{data.name}</td>
                                        <td>{data.value}</td>
                                        {!isInterviewers && (
                                            <td>
                                                <div className="d-flex  justify-content-end">
                                                    <img
                                                        src={Delete}
                                                        alt=""
                                                        className="me-1 icon_Style"
                                                        role="button"
                                                        onClick={() => {
                                                            // submitHandler(data);
                                                            setTask1Show(true);
                                                            setDeleteValue(
                                                                data
                                                            );
                                                        }}
                                                    />
                                                </div>
                                                <InterviewModal
                                                    show={task1Show}
                                                    handleClose={() =>
                                                        setTask1Show(false)
                                                    }
                                                    handleShow={() =>
                                                        setTask1Show(true)
                                                    }
                                                    modalTitle={`Do you want to delete ${deleteValue.value} `}
                                                >
                                                    <Button
                                                        onClick={() =>
                                                            setTask1Show(false)
                                                        }
                                                        className="mt-2 primary_color"
                                                        value="Cancel"
                                                    />
                                                    <Button
                                                        onClick={() => {
                                                            submitHandler(
                                                                deleteValue
                                                            );
                                                            setTask1Show(false);
                                                        }}
                                                        className="mx-2 mt-2 "
                                                        value="Delete"
                                                    />
                                                </InterviewModal>
                                            </td>
                                        )}
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={3} className="text-center">
                                        No data found
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </InfiniteScroll>
            </Row>
        </div>
    );
};

export { AddInterviewers };
