import React from "react";

import "./Loader.scss";

 function Loader() {
  return (
    <div className="spinner-container">
      <div className="loading-spinner">
      </div>
    </div>
  );
}

export {Loader}