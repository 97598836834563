import React, { useEffect, useState } from "react";

import { cloneDeep, isBoolean, isEmpty, isNumber } from "lodash";
import { Row, Col, Container } from "react-bootstrap";

import { Button } from "../UI/Button";
import { useProvider } from "../hooks/useProvider/useProvider";
import leftArrow from "../../Assets/Images/Manage/leftArrow.svg";
import { Login } from "../Auth/Login/Login";
import { InputField } from "../UI/InputField";
import { TextAreaField } from "../UI/TextAreaField";
import { CustomSelect } from "../UI/CustomSelect";
import { Label } from "../UI/Label";
import { InterviewModal } from "../UI/InterviewModal";

import "./AddQuestions.scss";

function AddQuestions({
    question,
    setActiveSize,
    activeSize,
    setActiveSizeData,
}) {
    const {
        createQuestion,
        displaySkillsets,
        skillSets,
        isFilters,
        getQuestions,
    } = useProvider();
    const initialState = {
        skillLevel: "",
        skillId: "",
        isPractical: false,
        question: "",
        answer: "",
        maxScore: "",
    };
    const [isQuestion, setIsQuestion] = useState(initialState);
    const [isErrors, setIsErrors] = useState({});
    const [task1Show, setTask1Show] = useState(false);

    const levelList = [
        { value: "Beginner", label: "Beginner" },
        { value: "Intermediate", label: "Intermediate" },
        { value: "Experienced", label: "Experienced" },
    ];
    const errors = {
        skillLevel: "Please choose skill level",
        skillId: "Please choose skill",
        question: "Please add question",
        answer: "Please add answer",
        maxScore: "Please add maximum marks",
    };
    const errorHandler = (data, fieldName) => {
        if (fieldName) {
            if (
                data === "" ||
                data?.length === 0 ||
                Object.keys(data)?.length === 0
            ) {
                setIsErrors((previousState) => {
                    return {
                        ...previousState,
                        [fieldName]: errors?.[fieldName],
                    };
                });
            } else {
                const errors = isErrors;
                delete errors?.[fieldName];
                setIsErrors(errors);
            }
        } else {
            const isEmpty = {};
            for (const [key, value] of Object.entries(isQuestion)) {
                if (
                    (Array.isArray(value) && value.length === 0) ||
                    (typeof value === "object" &&
                        Object.keys(value)?.length === 0) ||
                    (isNumber(value) && (!value === "" || value === 0)) ||
                    (!isBoolean(value) && (!value > 0 || value?.length === 0))
                ) {
                    isEmpty[key] = errors?.[key];
                    setIsErrors((previousState) => {
                        return { ...previousState, [key]: errors?.[key] };
                    });
                }
            }
            return isEmpty;
        }
    };
    const addQuestionStateHandler = (fieldName, data) => {
        setIsQuestion((previousState) => {
            return { ...previousState, [fieldName]: data };
        });
    };
    // convert skill level array of data into object
    const skillLevelHandler = (data) => {
        let finalData = [];
            const skillObject = {
                value: data,
                label: data,
            };
            finalData.push(skillObject);
        return finalData;
    };
    // Convert selected objects into required format
    const selectedSkillHandler = () => {
        let addquestionObject = isQuestion;
        // let skillValues = [];
        const skillId = isQuestion?.skillId?.skillIds;
        // const skillLevel = isQuestion?.skillLevel;
        // for (let i = 0; i < skillLevel?.length; i++) {
        //     skillValues.push(skillLevel[i]?.value);
        // }
        // const skillConverter = skillValues.join(",");
        addquestionObject["skillId"] = skillId;
        addquestionObject["skillLevel"] = isQuestion?.skillLevel?.value;
       
        return addquestionObject;
    };
    const addQuestionSubmitHandler = (e) => {
        const isEmpty = errorHandler(isQuestion);
        if (Object.keys(isEmpty)?.length === 0) {
            const prevFilterState = cloneDeep(isFilters);
            let addQuestionObject = selectedSkillHandler();
            createQuestion(addQuestionObject);
            prevFilterState.pageNo = 0;
            getQuestions(prevFilterState);
            setActiveSize("");
            setActiveSizeData("");
            setIsErrors({});
        }
    };
    useEffect(() => {
        if (
            // displaySkillsets &&
            question &&
            Object.keys(question || {})?.length > 0
        ) {
            setIsErrors({});
            const skillLevels = skillLevelHandler(question?.skillLevel);
            const filteredData =
                skillSets &&
                skillSets?.filter((skill) => {
                    return skill?.skillIds === question?.skillId;
                });
            let data = question;
            data["skillLevel"] = skillLevels?.[0];
            data["skillId"] = filteredData?.[0];
            delete data?.createdBy;
            delete data?.createdAt;
            delete data?.updatedAt;
            delete data?.updatedBy;
            setIsQuestion(data);
        }
    }, [question]);
  
    if (!localStorage.getItem("token")) {
        return <Login />;
    }
    const closeModal = () => {
        setActiveSize("");
        setActiveSizeData("");
        setIsErrors({});
    };
    const styles = {
        container: (base) => ({
            ...base,
            // flex: 5,
            flexGrow: 1,
            width: "200%",
        }),
    };

    return (
        <>
            <div className="AddQuestions__container">
                <div className="AddQuestions__main-content">
                    <div className="AddQuestions__article-featured py-sm-5">
                        <div className="AddQuestions__controls">
                            <div className="text-center mb-3 font-weight-bold h3 AddQuestions__containerHeading">
                                <div
                                    // onClick={closeModal}
                                    className=""
                                >
                                    <img
                                        src={leftArrow}
                                        className="closeImage"
                                        alt=""
                                        onClick={() => {
                                            setTask1Show(true);
                                        }}
                                    ></img>
                                </div>
                                <InterviewModal
                                    show={task1Show}
                                    handleClose={() => setTask1Show(false)}
                                    handleShow={() => setTask1Show(true)}
                                    modalTitle={`Do you want to exit the form? You will lose the data`}
                                >
                                    <Button
                                        onClick={() => setTask1Show(false)}
                                        className="mt-2 primary_color"
                                        value="Cancel"
                                        type="button"
                                    />
                                    <Button
                                        onClick={() => {
                                            setTask1Show(false);
                                            closeModal();
                                        }}
                                        className="mx-2 mt-2 "
                                        value="Okay"
                                        type="button"
                                    />
                                </InterviewModal>
                                <Label
                                    name={
                                        activeSize === "editQuestion"
                                            ? "Edit Question"
                                            : "Add Question"
                                    }
                                    classused="Label_medium"
                                />
                            </div>
                            <Row className="my-sm-5 justify-content-center">
                                <Col lg={6} md={9} sm={12} className="">
                                    <Row className="">
                                        <Col lg={6} md={6} sm={12} className="">
                                            <div className="AddQuestions____control">
                                                <CustomSelect
                                                    options={levelList}
                                                    placeholder="Select Level"
                                                    value={
                                                        isQuestion?.skillLevel
                                                    }
                                                    label="Skill Level"
                                                    onChange={(data) => {
                                                        errorHandler(
                                                            data,
                                                            "skillLevel"
                                                        );
                                                        addQuestionStateHandler(
                                                            "skillLevel",
                                                            data
                                                        );
                                                    }}
                                                    onBlur={() => {
                                                        errorHandler(
                                                            isQuestion?.skillLevel,
                                                            "skillLevel"
                                                        );
                                                    }}
                                                    styles={styles}
                                                    
                                                    required={
                                                        <sup className="mt-3 mx-1 fs-6 CandidateDetails_required">
                                                            *
                                                        </sup>
                                                    }
                                                />
                                                {isErrors?.skillLevel && (
                                                    <div className="CandidateDetails_required">
                                                        {isErrors?.skillLevel}
                                                    </div>
                                                )}
                                            </div>
                                        </Col>

                                        <Col lg={6} md={6} sm={12} className="">
                                            <div className="AddQuestions____control">
                                                <CustomSelect
                                                    options={skillSets}
                                                    placeholder="Select skill"
                                                    value={isQuestion?.skillId}
                                                    label="Skill"
                                                    onChange={(data) => {
                                                        errorHandler(
                                                            data,
                                                            "skillId"
                                                        );
                                                        addQuestionStateHandler(
                                                            "skillId",
                                                            data
                                                        );
                                                    }}
                                                    onBlur={() => {
                                                        errorHandler(
                                                            isQuestion?.skillId,
                                                            "skillId"
                                                        );
                                                    }}
                                                    styles={styles}
                                                    required={
                                                        <sup className="mt-3 mx-1 fs-6 CandidateDetails_required">
                                                            *
                                                        </sup>
                                                    }
                                                />
                                                {isErrors?.skillId && (
                                                    <div className="CandidateDetails_required">
                                                        {isErrors?.skillId}
                                                    </div>
                                                )}
                                            </div>
                                        </Col>
                                    </Row>
                                    <div
                                        className="question mt-2 mb-3"
                                        style={{ display: "flex" }}
                                    >
                                        <input
                                            type="checkbox"
                                            className="me-2 mt-2"
                                            checked={isQuestion?.isPractical}
                                            onChange={(event) => {
                                                addQuestionStateHandler(
                                                    "isPractical",
                                                    event.target.checked
                                                );
                                            }}
                                        />
                                        <Label
                                            name="Practical"
                                            classused="Label_normal mt-2 mb-0"
                                        />
                                    </div>
                                    <div className="AddQuestions____control">
                                        <TextAreaField
                                            id="question"
                                            name="question"
                                            placeholder="Question"
                                            control="textarea"
                                            label="Question"
                                            value={isQuestion?.question}
                                            onChange={(e) => {
                                                errorHandler(
                                                    e.target.value,
                                                    "question"
                                                );
                                                addQuestionStateHandler(
                                                    "question", e.target.value
                                                );
                                            }}
                                            rows="2"
                                            onBlur={(e) => {
                                                errorHandler(
                                                    e.target.value,
                                                    "question"
                                                );
                                                addQuestionStateHandler(
                                                    "question", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)
                                                );
                                            }}
                                            onKeyUp={(e) => {
                                                errorHandler(
                                                    e.target.value,
                                                    "question"
                                                );
                                            }}
                                            required={
                                                <span className="mt-3 mx-1 fs-6 CandidateDetails_required">
                                                    *
                                                </span>
                                            }
                                        />
                                        {isErrors?.question && (
                                            <div className="CandidateDetails_required">
                                                {isErrors?.question}
                                            </div>
                                        )}
                                    </div>
                                    <div className="AddQuestions____control mb-3">
                                        <TextAreaField
                                            id="answer"
                                            name="answer"
                                            placeholder="Answer"
                                            label="Answer"
                                            value={isQuestion?.answer}
                                            onChange={(e) => {
                                                errorHandler(
                                                    e.target.value,
                                                    "answer"
                                                );  
                                                addQuestionStateHandler(
                                                    "answer", e.target.value
                                                );
                                            }}
                                            rows="2"
                                            onBlur={(e) => {
                                                errorHandler(
                                                    e.target.value,
                                                    "answer"
                                                );

                                                addQuestionStateHandler(
                                                    "answer", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)
                                                );
                                            }}
                                            onKeyUp={(e) => {
                                                errorHandler(
                                                    e.target.value,
                                                    "answer"
                                                );
                                            }}
                                            required={
                                                <span className="mt-3 mx-1 fs-6 CandidateDetails_required">
                                                    *
                                                </span>
                                            }                                 
                                        />
                       {isErrors?.answer && (
                                            <div className="CandidateDetails_required">
                                                {isErrors?.answer}
                                            </div>
                                        )}                 
                                    </div>
                                    <div className="AddQuestions__MarksControl">
                                        <InputField
                                            id="first-name"
                                            type="number"
                                            label="Marks: 1 - 10"
                                            // label1="Max: 10"
                                            value={isQuestion?.maxScore}
                                            onChange={(e) => {
                                                if (
                                                    e.target.value === "" ||
                                                    (parseInt(e.target.value) >
                                                        0 &&
                                                        parseInt(
                                                            e.target.value
                                                        ) <= 10)
                                                ) {
                                                    errorHandler(
                                                        e.target.value,
                                                        "maxScore"
                                                    );
                                                    addQuestionStateHandler(
                                                        "maxScore",
                                                        parseInt(e.target.value)
                                                    );
                                                }
                                            }}
                                            onBlur={(e) => {
                                                errorHandler(
                                                    e.target.value,
                                                    "maxScore"
                                                );
                                            }}
                                            onKeyUp={(e) => {
                                                errorHandler(
                                                    e.target.value,
                                                    "maxScore"
                                                );
                                            }}
                                            required={
                                                <span className="mt-3 mx-1 fs-6 CandidateDetails_required">
                                                    *
                                                </span>
                                            }
                                        />
                                        {isErrors?.maxScore && (
                                            <div className="CandidateDetails_required">
                                                {isErrors?.maxScore}
                                            </div>
                                        )}
                                    </div>
                                    <div className="AddQuestions__modalButton">
                                        <Button
                                              onClick={() => {
                                                setTask1Show(true);
                                            }}
                                            value="Cancel"
                                            variant="secondary"
                                            className={`me-3`}
                                        />
                                        <Button
                                            onClick={() => {
                                                addQuestionSubmitHandler();
                                            }}
                                            type="button"
                                            value={
                                                activeSize === "editQuestion"
                                                    ? "Update"
                                                    : "Add"
                                            }
                                            className={` `}
                                            variant="secondary"
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export { AddQuestions };
