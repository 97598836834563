import React, { useEffect, useState } from "react";

import { Col, Container, Row } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import InfiniteScroll from "react-infinite-scroller";
import { cloneDeep } from "lodash";
import { Fragment } from 'react';

import { Label } from "../Label";
import { useInterviewerProvider } from "../../hooks/useInterviewerProvider/useInterviewerProvider";
import { Loader } from "../Loader";
import { CustomSelect } from "../CustomSelect";
import { useProvider } from "../../hooks/useProvider/useProvider";
import "./CustomTable.scss";

function CustomTable() {
    const {
        getCandidateHistory,
        candidateHistory,
        candidateHistoryPayload,
        isLoading,
    } = useInterviewerProvider();

    const { navadhitiMembers, getNavadhitiMembers, navadhitiMembersPayload } =
        useProvider();

    const [interviewerEmail, setInterviewerEmail] = useState({
        value: "ALL",
        label: "All",
    });
    const [interviewerDataIndex, setInterviewerDataIndex] = useState("");

    const loadMore = () => {
        const page = candidateHistoryPayload?.pageNo + 1;
        if (page < candidateHistoryPayload?.totalPages) {
            let filters = candidateHistoryPayload;
            filters.pageNo = page;

            getCandidateHistory(filters);
        }
    };

    const candidateHistoryHandler = (data) => {
        setInterviewerEmail(data);

        let filters = candidateHistoryPayload;
        filters.pageNo = 0;
        filters.email = data?.value !== "ALL" ? data?.value : "";
        getCandidateHistory(filters);
    };

    useEffect(() => {
        const newData = cloneDeep(navadhitiMembersPayload);
        newData.pageNo = 0;
        newData.getAll = true;
        newData.role = "INTERVIEWER";
        getNavadhitiMembers(newData);
        candidateHistoryHandler(interviewerEmail);
    }, []);

    return (
        <div className="table_section">
            <Row className="justify-content-between">
                <Col xs="12" sm="12" md="7" lg="7" className="my-md-3 px-4">
                    <Label
                        name="Candidate Details"
                        classused="Label_bold text-left my-4"
                    />
                </Col>
                <Col
                    xs="12"
                    sm="14"
                    md="4"
                    lg="4"
                    className="my-xs-1 my-md-3 align-self-center mb-3"
                >
                    <CustomSelect
                        onChange={(data) => {
                            candidateHistoryHandler(data);
                        }}
                        options={navadhitiMembers}
                        value={interviewerEmail}
                        id="reactSeclect"
                    />
                </Col>
            </Row>
            {candidateHistoryPayload?.pageNo === 0 && isLoading ? (
                <Loader />
            ) : (
                <InfiniteScroll
                    loadMore={loadMore}
                    hasMore={
                        candidateHistoryPayload?.pageNo + 1 <
                        candidateHistoryPayload?.totalPages
                    }
                    loader={<Loader key={0} />}
                    initialLoad={false}
                    threshold={500}
                    useWindow={true}
                >
                    <div className="table-responsive">
                        {/* <table class="table table-hover fold-table"> */}
                        <Table className="fold-table" responsive>
                            <thead className=" sticky-top">
                                <tr className="h6 fw-bold sticky position-sticky Label_medium bg-secondary  bg-opacity-25 align-middle">
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Contact Number</th>
                                    <th>Skillset</th>
                                    <th>Interviewer Name</th>
                                    <th>Interviewer Email</th>
                                    <th>Skill Level</th>
                                </tr>
                            </thead>
                            <tbody>
                                {candidateHistory?.length > 0 ? (
                                    candidateHistory?.map((item, index) => {
                                        return (
                                            <Fragment key={index}>
                                                <tr
                                                    onClick={() =>
                                                        setInterviewerDataIndex(
                                                            index ===
                                                                interviewerDataIndex
                                                                ? ""
                                                                : index
                                                        )
                                                    }
                                                    className={` ${interviewerDataIndex ===
                                                        index &&
                                                        "secondary_color "
                                                        }`}
                                                    role="button"
                                                >
                                                    <td>
                                                        {item?.candidateName}
                                                    </td>
                                                    <td>
                                                        {item?.candidateEmail}
                                                    </td>
                                                    <td>{item?.candidateContactNumber}</td>
                                                    <td>
                                                        {item?.skillSet?.skillSetName}
                                                    </td>
                                                    <td>
                                                        {`${item?.interviewDetails?.[0]?.employeeDetails?.empName} ${item?.interviewDetails?.length > 1 && " + " + `${item?.interviewDetails?.length - 1}` + " more..."}`}
                                                    </td>

                                                    <td>
                                                        {`${item?.interviewDetails?.[0]?.employeeDetails?.empMailId} ${item?.interviewDetails?.length > 1 && " + " + `${item?.interviewDetails?.length - 1}` + " more..."}`}
                                                    </td>
                                                    <td>{item?.skillLevel}</td>
                                                </tr>
                                                <tr
                                                    className={`fold ${interviewerDataIndex ===
                                                        index && "open"
                                                        }`}
                                                >
                                                    <td colSpan="7">
                                                        {item?.interviewDetails?.map((interview, index) => {


                                                            return (
                                                                <div className="fold-content border p-3 rounded border-secondary mb-2">
                                                                    <Label name={`Interview Round - ${item?.interviewDetails?.length - index}`} classused="Label_medium mb-3 fs-5" />
                                                                    <>
                                                                        <Table
                                                                            className="fold-table"
                                                                            responsive
                                                                        >
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td>
                                                                                        <p>
                                                                                            <b>
                                                                                                Interviewer Name
                                                                                                :
                                                                                            </b>{" "}
                                                                                            {
                                                                                                interview?.employeeDetails?.empName
                                                                                            }
                                                                                        </p>
                                                                                    </td>
                                                                                    <td>
                                                                                        <p>
                                                                                            <b>
                                                                                                Scheduled
                                                                                                On
                                                                                                :
                                                                                            </b>{" "}
                                                                                            {
                                                                                                interview?.scheduledOn
                                                                                            }
                                                                                        </p>
                                                                                    </td>
                                                                                    <td>
                                                                                        {" "}
                                                                                        <p>
                                                                                            <b>
                                                                                                Gained
                                                                                                Marks
                                                                                                :
                                                                                            </b>{" "}
                                                                                            {
                                                                                                interview?.totalScore
                                                                                            }
                                                                                        </p>
                                                                                    </td>
                                                                                    <td>
                                                                                        {" "}
                                                                                        <p>
                                                                                            <b>
                                                                                                Max
                                                                                                Marks
                                                                                                :
                                                                                            </b>{" "}
                                                                                            {
                                                                                                interview?.maxScore
                                                                                            }
                                                                                        </p>
                                                                                    </td>
                                                                                    <td>
                                                                                        {" "}
                                                                                        <p>
                                                                                            <b>
                                                                                                Current
                                                                                                CTC
                                                                                                :
                                                                                            </b>{" "}
                                                                                            {
                                                                                                item?.currentCtc
                                                                                            }
                                                                                        </p>
                                                                                    </td>
                                                                                    <td>
                                                                                        {" "}
                                                                                        <p>
                                                                                            <b>
                                                                                                {" "}
                                                                                                Expected
                                                                                                CTC
                                                                                                :
                                                                                            </b>{" "}
                                                                                            {
                                                                                                item?.expectedCtc
                                                                                            }
                                                                                        </p>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </Table>
                                                                        <div className="fixed_Table">
                                                                            <Table
                                                                                className="fold-table"
                                                                                striped
                                                                                responsive
                                                                            >
                                                                                <thead
                                                                                    className="sticky-top"
                                                                                    style={{
                                                                                        position:
                                                                                            "sticky",
                                                                                        top: 0,
                                                                                    }}
                                                                                >
                                                                                    <tr>
                                                                                        <th className="header">
                                                                                            Question
                                                                                        </th>
                                                                                        <th className="header">
                                                                                            Gained
                                                                                            Marks
                                                                                        </th>
                                                                                        <th className="header">
                                                                                            Max
                                                                                            Marks
                                                                                        </th>
                                                                                        <th className="header">
                                                                                            Skill
                                                                                            Name
                                                                                        </th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {interview?.score?.length >
                                                                                        0 ? (
                                                                                        interview?.score?.map(
                                                                                            (
                                                                                                question,
                                                                                                index
                                                                                            ) => {
                                                                                                return (
                                                                                                    <tr
                                                                                                        key={
                                                                                                            index
                                                                                                        }
                                                                                                    >
                                                                                                        <td
                                                                                                            data-toggle="tooltip"
                                                                                                            data-placement="bottom"
                                                                                                            title={
                                                                                                                question?.question?.question
                                                                                                            }
                                                                                                        >
                                                                                                            {question?.question?.question?.substring(
                                                                                                                0,
                                                                                                                100
                                                                                                            )}
                                                                                                            {question
                                                                                                                ?.question?.question?.question
                                                                                                                ?.length >=
                                                                                                                100
                                                                                                                ? "..."
                                                                                                                : null}
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            {
                                                                                                                question?.obtainedMarks
                                                                                                            }
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            {
                                                                                                                question?.question?.maxScore
                                                                                                            }
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            {
                                                                                                                question?.question?.skill?.skillName
                                                                                                            }
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                );
                                                                                            }
                                                                                        )
                                                                                    ) : (
                                                                                        <tr>
                                                                                            <td
                                                                                                colSpan={
                                                                                                    4
                                                                                                }
                                                                                                className="text-center"
                                                                                            >
                                                                                                No
                                                                                                data
                                                                                                found
                                                                                            </td>
                                                                                        </tr>
                                                                                    )}
                                                                                </tbody>
                                                                            </Table>
                                                                        </div>
                                                                    </>
                                                                </div>
                                                            )
                                                        })}
                                                    </td>
                                                </tr>
                                            </Fragment>
                                        );
                                    })
                                ) : (
                                    <tr>
                                        <td
                                            colSpan={10}
                                            className="text-center"
                                        >
                                            No data found
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </div>
                </InfiniteScroll>
            )}
        </div>
    );
}

export default CustomTable;
