import React from "react";

import { Modal } from "react-bootstrap";

import 'bootstrap/dist/css/bootstrap.css';
import "./InterviewModal.scss";

function InterviewModal(props) {
  const { show, handleClose,handleSubmit, size, modalTitle,modalButton } = props;
  return (
    <Modal onClose={handleClose} size={size} show={show} onHide={handleClose} className="text-center" backdrop="static">
      <Modal.Header className="align-self-center">
        <Modal.Title className="text-center fw-bold">{modalTitle}</Modal.Title>
        {/* <p className="btn-modal-close" onClick={() => handleClose()}>
          <i className="fa fa-times text-danger"></i>
        </p> */}
      </Modal.Header> 
      <Modal.Body className="align-self-center">{props.children}</Modal.Body>
  

    </Modal>
  );
};

export { InterviewModal }
