import React from "react";

import { useInterview } from "../hooks/useInterview/useInterview";
import { useInterviewer } from "../hooks/useInterviewer/useInterviewer";

const InterviewContext = React.createContext({});
const InterviewerContext = React.createContext({});

function Provider({ children }) {
    const InterviewPortalHandler = useInterview();
    const InterviewerPortalHandler = useInterviewer();

    return (
        <>
            <InterviewContext.Provider value={InterviewPortalHandler}>
                <InterviewerContext.Provider value={InterviewerPortalHandler}>
                    {children}
                </InterviewerContext.Provider>
            </InterviewContext.Provider>
        </>
    );
}

export { Provider, InterviewContext, InterviewerContext };
