import React, { useEffect, useState } from "react";

import { Routes, Route, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { ActionType } from "./ActionType/ActionType";
import { QuestionDashboard } from "./QuestionDashboard/QuestionDashboard";


import { InterviewDashboard } from "./InterviewDashboard";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { AddQuestions } from "./AddQuestions";
import { AddSkills } from "./AddSkills";
import { MapSkills } from "./MapSkills";
import { useProvider } from "./hooks/useProvider/useProvider";
import { Tooltips } from "./Tooltips/Tooltips";
import { CandidateProgressDashboard } from "./InterviewDashboard/CandidateProgressDashboard";
import { SideNavbar } from "./SideNavbar/SideNavbar";
import { Sidebar } from "./Sidebar";
import { MeetingDashboard } from "./InterviewDashboard/MeetingDashboard";
import CustomTable from "./UI/CustomTable/CustomTable";
import { ResultData } from "./InterviewDashboard/ResultData";
import "../Assets/Fonts/Nunito/static/Nunito-Light.ttf";
import { Header } from "./Header";

import { useInterviewerProvider } from "./hooks/useInterviewerProvider/useInterviewerProvider";
import { AddInterviewers } from "./AddInterviewers/AddInterviewers";
import "./Portal.scss";

function Portal() {
    const { toastMessage, setToastMessage } = useProvider();
    const { toastifyMessage, setToastifyMessage } = useInterviewerProvider();

    const [sideBarStatus, setSideBarStatus] = useState(false);
    const navigate = useNavigate()

    useEffect(() => {
        if (Object.keys(toastMessage || {})?.length > 0) {
            if (toastMessage?.type === "success") {
                toast.success(toastMessage?.message);
            } else {
                toast.error(toastMessage?.message);
            }
            setTimeout(() => {
                setToastMessage({});
            }, 2000);
        }
    }, [toastMessage]);

    useEffect(() => {
        if (Object.keys(toastifyMessage || {})?.length > 0) {
            if (toastifyMessage?.type === "success") {
                toast.success(toastifyMessage?.message);
            } else {
                toast.error(toastifyMessage?.message);
            }
            setTimeout(() => {
                setToastifyMessage({});
            }, 2000);
        }
    }, [toastifyMessage]);

    const findpath = window.location.pathname;

    useEffect(() => {
        if (
            findpath === "/" ||
            findpath === "/resultData"
        ) {
            setSideBarStatus(false);
        } else {
            setSideBarStatus(true);
        }
    }, [window.location.pathname]);
    let isActiveTab = localStorage.getItem("activeTab");
    let isTotalMarks = localStorage.getItem("totalScores");
    
    return (
        <>
            <Sidebar />

            <div className="Portal_Section">
                <Routes>
                    <Route path="/" element={<ActionType />} />
                    <Route
                        path="/questionDashbard"
                        element={<QuestionDashboard />}
                    />
                    <Route
                        path="interviewDashboard"
                        element={isActiveTab ? <MeetingDashboard /> : isTotalMarks ? <CandidateProgressDashboard /> : <InterviewDashboard />}
                    />
                    <Route path="addSkills" element={<AddSkills />} />
                    <Route path="skillSets" element={<MapSkills />} />
                    <Route
                        path="CandidateProgressDashboard"
                        element={<CandidateProgressDashboard />}
                    />
                    <Route
                        path="MeetingDashboard"
                        element={<MeetingDashboard />}
                    />
                    <Route path="reports" element={<CustomTable />} />
                    <Route
                        path="addInterviewers"
                        element={<AddInterviewers />}
                    />
                </Routes>
            </div>
            <ToastContainer
                position="bottom-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </>
    );
}

export { Portal };
