import React, { useState } from "react";

import Select from "react-select";
import { Form } from "react-bootstrap";

import { Label } from "../Label";
import "./CustomSelect.scss";

const CustomSelect = (props) => {
  // const handleChange = (value) => {
  //   // this is going to call setFieldValue and manually update values.topcis
  //   props.onChange("skillSetName", value);
  // };

  // const handleBlur = () => {
  //   // this is going to call setFieldTouched and manually update touched.topcis
  //   props.onBlur("skillSetName", true);
  // };

  return (
    <>
      <div className="CustomSelect" id={props.id}>
        <Form.Group>
          <div className={"d-flex"}>
            <Label
              name={props.label}
              classused="Label_normal"
              id="minMarks"
            ></Label>
            {props.required}
          </div>
        </Form.Group>
        <Select
          id="color"
          
          options={props.options}
          isMulti={props.isMulti}
          onChange={props.onChange}
          onBlur={props.onBlur}
          value={props.value}
          onSelect={props.onSelect}
          isDisabled={props.isDisabled}
        />
        {!!props.error && props.touched && (
          <div style={{ color: "red", marginTop: ".5rem" }}>{props.error}</div>
        )}
      </div>
    </>
  );
};
export { CustomSelect };
