import React, { useState } from 'react';

import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import Delete from '../../../Assets/Images/Manage/delete.svg';
import Edit from '../../../Assets/Images/Manage/edit.svg';
import { InterviewModal } from '../../UI/InterviewModal';
import { Button } from '../Button';
import './Cards.scss';

function Cards({ skillName, editSkill, deleteSkill, className }) {
  const [task1Show, setTask1Show] = useState(false);

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {skillName}
    </Tooltip>
  );

  return (
    <>
      <div
        className={`Cards d-flex align-items-center justify-content-between ${
          className && 'action'
        }`}
      >
        <OverlayTrigger
          placement="right"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip}
        >
          <div>{skillName.substring(0,15)}
          {skillName.trim().length > 15 ? "..." : null}
          </div>
        </OverlayTrigger>
        <div className="Cards_cardsButton d-flex align-items-center">
          <span onClick={editSkill}>
            <img src={Edit} alt="" className='icon_Style'/>
          </span>

          <span
            onClick={() => {
              setTask1Show(true);
            }}
          >
            <img src={Delete} alt="" className="mx-2 icon_Style"></img>
          </span>
          <InterviewModal
            show={task1Show}
            handleClose={() => setTask1Show(false)}
            handleShow={() => setTask1Show(true)}
            modalTitle={`Do you want to delete this Skill?`}
          >
            <Button
              onClick={() => setTask1Show(false)}
              className="mt-2 primary_color"
              value="Cancel"
              type="button"
            />
            <Button
              onClick={() => {
                setTask1Show(false);
                deleteSkill();
              }}
              className="mx-2 mt-2"
              value="Delete"
              type="button"
            />
          </InterviewModal>
        </div>
      </div>
    </>
  );
}

export { Cards };
