import React from "react";
import { BrowserRouter } from "react-router-dom";

import { Provider } from "./Components/Context";
import { Portal } from "./Components/Portal";
import './Assets/Fonts/Nunito/static/Nunito-Light.ttf';
import './App.css';

function App() {
  return (
    <>
      <BrowserRouter>
        <Provider> 
          <Portal />    
        </Provider>
      </BrowserRouter>
    </>
  );
}

export default App;