import React from "react";

import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import { useProvider } from "../hooks/useProvider/useProvider";
import { Login } from "../Auth/Login/Login";
import companyImage from "../../Assets/Images/homeImage.svg";
import { Loader } from "../UI/Loader";
import "./ActionType.scss";

function ActionType() {
    const { isTokenActive, isLoading } = useProvider();
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));

    if (isLoading) {
        return <Loader />;
    }
    if (!localStorage.getItem("token") && !isTokenActive) {
        return <Login />;
    }

    return (
        <div className="ActionType">
            {/* <nav className="navbar navbar-expand-lg navbar-dark fixed-top">
        <img src={companyLogo} alt="alternative" height="80px" />
      </nav> */}
            <Container className="py-5 my-5">
                <Row className="justify-content-between align-items-center">
                    <Col xs="12" sm="12" lg="6" xxl="6" className="text-center">
                        <h1 className="mt-5">
                            {/* Please Select One
                            <br />
                            Process To Start With */}
                            Welcome to Pratibha <br />Talent Acquisition Portal
                        </h1>
                        <div className="pt-5  align-self-center">
                            <img
                                className=" mx-auto img-fluid hide_md mb-5"
                                src={companyImage}
                                alt="alternative"
                            />
                            {/* <Link
                                to="/interviewDashboard"
                                className="ActionType__buttonStyling"
                            >
                                <button className="primary_color px-5 py-2 rounded d-flex align-items-center align-self-start mb-3 ActionType__signinButton">
                                    <img
                                        src={interviewImage}
                                        alt=""
                                        className="ActionType__signinButtonLogo"
                                    ></img>{" "}
                                    <div className="pl-3 text-light text-start">
                                        Take Interview
                                    </div>
                                </button>
                            </Link>
                            <Link
                                to="/questionDashbard"
                                className="ActionType__buttonStyling"
                            >
                                <button
                                    className="primary_color px-5 py-2 mb-3 rounded d-flex align-items-center align-self-start ActionType__signinButton "
                                    size="lg"
                                >
                                    <img
                                        src={questionImage}
                                        alt=""
                                        className="ActionType__signinButtonLogo"
                                    ></img>{" "}
                                    <div className="pl-3 text-light text-start">
                                        Manage Questions & Skills
                                    </div>
                                </button>
                            </Link>
                            <Link
                                to="/reports"
                                className="ActionType__buttonStyling"
                            >
                                <button
                                    className="primary_color px-5 py-2 mb-3 rounded d-flex align-items-center align-self-start ActionType__signinButton "
                                    size="lg"
                                >
                                    <img
                                        src={reportImage}
                                        alt=""
                                        className="ActionType__signinButtonLogo"
                                    ></img>{" "}
                                    <div className="pl-3 text-light text-start">
                                        Report
                                    </div>
                                </button>
                            </Link>
                            {Object.keys(userDetails || {}).length > 0 &&
                                userDetails?.role === "ADMIN" && (
                                    <Link
                                        to="/addInterviewers"
                                        className="ActionType__buttonStyling"
                                    >
                                        <button
                                            className="primary_color px-5 py-2 rounded rounded-pill d-flex align-items-center align-self-start ActionType__signinButton "
                                            size="lg"
                                        >
                                            <img
                                                src={addMember}
                                                alt=""
                                                className="ActionType__signinButtonLogo"
                                            ></img>{" "}
                                            <div className="pl-3 text-light text-start">
                                                Add Interviewers
                                            </div>
                                        </button>
                                    </Link>
                                )} */}
                        </div>
                    </Col>
                    <Col xs="12" sm="12" lg="6">
                        <img
                            className="img-fluid hide_xs"
                            src={companyImage}
                            alt="alternative"
                        />
                        {/* <div className="pt-5 hide_md align-self-center ">
              <Link
                to="/interviewDashboard"
                className="ActionType__buttonStyling mx-auto"
              >
                <button className="primary_color px-5 py-2 rounded d-flex align-items-center align-self-start mb-3 ActionType__signinButton mx-auto">
                  <img
                    src={interviewImage}
                    alt=""
                    className="ActionType__signinButtonLogo"
                  ></img>{" "}
                  <div className="pl-3 text-light">Take Interview</div>
                </button>
              </Link>
              <Link
                to="/questionDashbard"
                className="ActionType__buttonStyling"
              >
                <button
                  className="primary_color px-5 py-2 rounded d-flex align-items-center align-self-start mb-3 ActionType__signinButton mx-auto"
                  size="lg"
                >
                  <img
                    src={questionImage}
                    alt=""
                    className="ActionType__signinButtonLogo"
                  ></img>{" "}
                  <div className="pl-3 text-light">
                    <div>Question Dashboard</div>
                  </div>
                </button>
              </Link>
              <Link to="/reports" className="ActionType__buttonStyling">
                <button
                  className="primary_color px-5 py-2  mb-3 rounded d-flex align-items-center align-self-start ActionType__signinButton mx-auto "
                  size="lg"
                >
                  <img
                    src={reportImage}
                    alt=""
                    className="ActionType__signinButtonLogo"
                  ></img>{" "}
                  <div className="pl-3 text-light">Reports</div>
                </button>
              </Link>
              {Object.keys(userDetails || {}).length > 0 &&
                userDetails?.role === "ADMIN" && (
                  <Link to="/addmember" className="ActionType__buttonStyling">
                    <button
                      className="add px-5 py-2 rounded d-flex align-items-center align-self-start ActionType__signinButton mx-auto "
                      size="lg"
                    >
                      <img
                        src={reportImage}
                        alt=""
                        className="ActionType__signinButtonLogo"
                      ></img>{" "}
                      <div className="pl-3 text-light">Add Interviewers</div>
                    </button>
                  </Link>
                )}
            </div> */}
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export { ActionType };
