import React, { useEffect, useState } from "react";

import { Container, Row, Col } from "react-bootstrap";

import companyLogo from "../../../Assets/Images/ND-logo.png";
import { useInterview } from "../../hooks/useInterview/useInterview";
import { useProvider } from "../../hooks/useProvider/useProvider";
import companyImage from "../../../Assets/Images/homeImage.svg";
import { Loader } from "../../UI/Loader";
import "./Login.scss";

function Login() {
    /* global google */

    // const { verifyUserToken } = useInterview();
    const { getUserDetails, isLoading, verifyUserToken } = useProvider();
    // const [loginStatus, setLoginStatus] = useState(false);

    // To veriify the login credential
    useEffect(() => {
        function handleCallbackResponse(response) {
            verifyUserToken(response?.credential)
                .then((res) => {
                    const { data } = res;
                    if (data?.status === "success" && data?.data !== "") {
                        localStorage.setItem("token", data?.data);
                        getUserDetails(data?.data);
                        // setLoginStatus(true);
                    }
                })
                .catch((err) => console.log(err));
        }

        google.accounts.id.initialize({
            client_id: process.env.React_App_Google_Auth,
            callback: handleCallbackResponse,
        });

        google.accounts.id.renderButton(document.getElementById("signInDiv"), {
            theme: "dark",
            size: "large",
        });

        google.accounts.id.prompt();
    }, []);

    return (
        <div className="Login">
            <Container className="p-3">
                {/* <nav className="navbar navbar-expand-lg navbar-dark LoginDashboard__navbarCustom my-3">
                    <img src={companyLogo} alt="alternative" height="80px" />
                </nav> */}
                <Row className="my-sm-3">
                    <Col md="6" className="align-self-center my-sm-5">
                        <div className="text-left">
                            <h2>Welcome to Pratibha</h2>
                            <h1 className="p-large">
                                Talent Acquisition Portal
                            </h1>

                            <button className="border border-0" id="signInDiv">
                                <img
                                    src="https://cdn-icons-png.flaticon.com/128/300/300221.png"
                                    alt=""
                                    width="25px"
                                    height="25px"
                                />
                                <span className="pl-3">
                                    Sign in with Google
                                </span>
                            </button>
                            <img
                                className=" mx-auto img-fluid hide_md mt-5"
                                src={companyImage}
                                alt="alternative"
                            />
                        </div>
                    </Col>
                    <Col md="6" className="my-5">
                        <div className=" mx-auto">
                            {/* <img
                                className="mx-auto img-fluid"
                                src={companyImage}
                                alt="alternative"
                            /> */}
                            <img
                                className=" mx-auto img-fluid hide_xs mb-5"
                                src={companyImage}
                                alt="alternative"
                            />
                        </div>
                    </Col>
                </Row>
            </Container>

        </div>
    );
}

export { Login };
