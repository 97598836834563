import React, { useEffect, useState } from "react";

import { cloneDeep } from "lodash";
import _ from "lodash";

import { Label } from "../../UI/Label";
import InfiniteScroll from "react-infinite-scroller";
import { AccordionWrapper } from "../../UI/AccordionWrapper";
import { LabelField } from "../../UI/LabelField";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useProvider } from "../../hooks/useProvider/useProvider";
import { Container, Row, Col, Form } from "react-bootstrap";
import { InterviewAccordionContent } from "../InterviewAccordionContent/InterviewAccordionContent";
import { useInterviewerProvider } from "../../hooks/useInterviewerProvider/useInterviewerProvider";
import { InterviewAnswer } from "../InterviewAnswer";
import { InterviewModal } from "../../UI/InterviewModal";
import Accordion from "react-bootstrap/Accordion";
import { AnswerStatus } from "../AnswerStatus/AnswerStatus";
import { useNavigate } from "react-router";
import { Button } from "../../UI/Button";
import { Loader } from "../../UI/Loader";
import SearchString from "../../UI/SearchString/SearchString";
import { useLocation } from "react-router-dom";
import { CustomSelect } from "../../UI/CustomSelect";
import "./MeetingDashboard.scss";

function MeetingDashboard() {
    const location = useLocation();
    const {
        skills,
    } = useProvider();
    const {
        getInterviewQuestions,
        interviewerListOfQuestions,
        interviewerFilters,
        setInterviewerFilters,
        isLoading,
    } = useInterviewerProvider();
    const navigate = useNavigate();

    const [activeSize, setActiveSize] = useState(""); // To call the right side component
    const [activeSizeData, setActiveSizeData] = useState(""); // Pass data to the right side component
    const [marks, setMarks] = useState(0); // To displlay the marks given for the opened question
    const [totalScore, setTotalScore] = useState({}); // To display the total score
    const [task1Show, setTask1Show] = useState(false);
    const [loaded, setLoaded] = useState(true);
    const [askedQuestion, setAskedQuestion] = useState([]); // store the list of questions asked

    const candidateData = JSON.parse(localStorage.getItem("candidateDetails")); // get data from localstorage. this will help on refreshing of page
    const candidateScores = JSON.parse(localStorage.getItem("totalScores")); // get data from localstorage. this will help on refreshing of page
    const questionDetails = JSON.parse(localStorage.getItem("questionDetails")); // get the list of questions which is is asked
    const activeTab = parseInt(localStorage.getItem("activeTab"));
    const currentDate = new Date().getHours();
    const indexOfInterviewDetails =
        candidateData?.interviewDetails?.length > 1
            ? candidateData?.interviewDetails?.length - 1
            : 0;

    //   On search and is practical change update the state
    let clonedFilter = cloneDeep(interviewerFilters);

    // Function that waits for given miliseconds after user stop typing and updates state and hits the API
    const searchStr = _.debounce((data) => {
        clonedFilter.search = data;
        clonedFilter.skillLevel = clonedFilter?.skillLevel?.value;
        getInterviewQuestions(clonedFilter);
    }, 600);

    // object to send as props to search component
    const searchCompObj = {
        onChange: searchStr,
    };

    // On change of practical call below function
    const filterHandler = (fieldName, data) => {
        if (fieldName === "skillLevel") {
            clonedFilter.skillLevel = data?.value;
        } else {
            clonedFilter.skillLevel = clonedFilter.skillLevel?.value;
            clonedFilter[fieldName] = data;
        }
        clonedFilter.pageNo = 0;
        setInterviewerFilters(clonedFilter);
        getInterviewQuestions(clonedFilter);
    };

    //   filter and get the the skill
    const filterSkills = (item) => {
        // get skill id using name
        const fetchId = skills?.filter(
            (skill) =>
                skill?.id ===
                candidateData?.interviewDetails?.[indexOfInterviewDetails]?.interviewSkills?.[item]?.id
        );
        return fetchId;
    };

    //   Call get question API if tab change handler
    const tabChangeHandler = (item) => {
        localStorage.setItem("activeTab", item);
        const data = filterSkills(item);
        const filters = cloneDeep(interviewerFilters);
        filters.skillId = data?.[0]?.id;
        filters.skillLevel = filters?.skillLevel?.value;
        setInterviewerFilters(filters);
        getInterviewQuestions(filters);
    };

    // Check whether the question is existed or not
    const askedQuestionIndex = (questionId) => {
        const existingQuestionIndex = askedQuestion?.findIndex(
            (score) => score?.questionId === questionId
        );
        return existingQuestionIndex;
    };
    const levelList = [
        { value: "Beginner", label: "Beginner" },
        { value: "Intermediate", label: "Intermediate" },
        { value: "Experienced", label: "Experienced" },
    ];

    // To return the answer status for every question
    const answerStatusHandler = (id) => {
        const finalData = askedQuestion;

        const answerExist = finalData?.filter((question) => {
            return question?.questionId === id;
        });
        return answerExist?.length > 0 ? answerExist?.[0]?.status : "";
    };

    const questionAnswerHandler = (givenMarks, status) => {
        const finalData = questionDetails;

        const data = {
            questionId: activeSizeData?.id,
            obtainedMarks: givenMarks,
            maxScore: activeSizeData?.maxScore,
            // skillName: activeSizeData?.skillName,
            status: status,
        };

        // Check whether the question is existed or not
        const existingDataIndex = finalData?.findIndex(
            (score) => score?.questionId === data?.questionId
        );

        // To update the total score and out of score
        if (existingDataIndex !== -1) {
            finalData.splice(existingDataIndex, 1, data);
        } else {
            // add every new question details
            finalData.push(data);
        }

        setAskedQuestion(finalData);
        localStorage.setItem("questionDetails", JSON.stringify(finalData));

        // calculate and update the total score
        let updatedTotalScore = {
            obtainedMarks: 0,
            maxScore: 0,
        };

        for (let i = 0; i < questionDetails?.length; i++) {
            updatedTotalScore.obtainedMarks +=
                questionDetails?.[i]?.obtainedMarks;
            updatedTotalScore.maxScore += questionDetails?.[i]?.maxScore;
        }
        localStorage.setItem("totalScores", JSON.stringify(updatedTotalScore));
        setTotalScore(updatedTotalScore);
    };

    const loadMore = () => {
        const page = interviewerFilters?.pageNo + 1;
        if (page < interviewerFilters?.totalPages) {
            let filters = interviewerFilters;
            filters.pageNo = page;
            getInterviewQuestions(filters);
        }
    };

    // on add of candidate details get the question
    useEffect(() => {
        if (Object.keys(candidateData || {}).length > 0 && skills?.length > 0) {
            const data = filterSkills(activeTab);
            if (data?.length > 0) {
                let filters = cloneDeep(interviewerFilters);
                filters.skillId = data?.[0]?.id;
                filters.skillLevel = candidateData?.skillLevel;

                setTotalScore(candidateScores);
                if (filters?.skillLevel && filters?.skillId) {
                    getInterviewQuestions(filters);
                }
            }
        }
    }, [skills]);

    // To update the state on page load with local storage data
    useEffect(() => {
        setAskedQuestion(questionDetails);
    }, []);

    return (
        <>
            <div className="MeetingDashboard my-5">
                <div className={`FilterBar__content `}>
                    <Container
                        className="FilterBar__questionAccordion "
                    >
                        <Row
                            className={`Heade__Content ${activeSize && "active"
                                }`}
                        >
                            <Col
                                xs="12"
                                sm="12"
                                md="12 "
                                lg="8"
                                className="align-self-center lg-8"
                            >
                                <Label
                                    name={`Good ${currentDate >= 0 && currentDate < 12
                                        ? "Morning"
                                        : currentDate >= 12 &&
                                            currentDate < 16
                                            ? "Afternoon"
                                            : "Evening"
                                        } “Interviewer” `}
                                    classused="Label_bold text-left"
                                />
                            </Col>

                            <Col
                                xs="12"
                                sm="12"
                                md="12"
                                lg="4"
                                className="align-self-end lg-3"
                            >
                                {candidateData?.interviewDetails?.[indexOfInterviewDetails]?.interviewSkills?.length > 0 && (
                                    <>
                                        <Row>
                                            <Col>
                                                {" "}
                                                <Form className="">
                                                    <Form.Control
                                                        type="Text"
                                                        aria-label="TotalMarks"
                                                        className="markInputBox text-center m-1"
                                                        size="sm"
                                                        disabled
                                                        placeholder={`${totalScore?.obtainedMarks
                                                            ? totalScore?.obtainedMarks
                                                            : 0
                                                            } / ${totalScore?.maxScore
                                                                ? totalScore?.maxScore
                                                                : 0
                                                            }`}
                                                    />
                                                </Form>
                                            </Col>
                                            <Col className="d-grid my-1">
                                                <Button
                                                    onClick={() =>
                                                        setTask1Show(true)
                                                    }
                                                    size="lg"
                                                    className=" "
                                                    value="End"
                                                    variant="danger"
                                                    disabled={
                                                        totalScore?.maxScore ===
                                                        0
                                                    }
                                                />
                                            </Col>
                                        </Row>

                                        <InterviewModal
                                            show={task1Show}
                                            handleClose={() =>
                                                setTask1Show(false)
                                            }
                                            handleShow={() =>
                                                setTask1Show(true)
                                            }
                                            modalTitle={`Do you want to end this Interview?`}
                                        >
                                            <Button
                                                onClick={() =>
                                                    setTask1Show(false)
                                                }
                                                className="mt-2 primary_color px-sm-5"
                                                value="No"
                                                type="submit"
                                            />

                                            <Button
                                                onClick={() => {
                                                    setTask1Show(false);
                                                    setLoaded(!loaded);
                                                    localStorage.removeItem(
                                                        "activeTab"
                                                    );
                                                    navigate(
                                                        "/CandidateProgressDashboard"
                                                    );
                                                }}
                                                className="mx-2 mt-2 px-sm-5"
                                                value="Yes"
                                                type="submit"
                                            />
                                        </InterviewModal>
                                    </>
                                )}
                            </Col>
                        </Row>
                        <Row className="align-items-center justify-content-end">
                            <Col
                                xs="12"
                                sm="6"
                                md="4"
                                className="align-items-center justify-content-end ms-5"
                            >
                                <CustomSelect
                                    id="skillLevel"
                                    options={levelList}
                                    placeholder="Select skill"
                                    value={interviewerFilters?.skillLevel}
                                    onChange={(data) => {
                                        filterHandler("skillLevel", data);
                                    }}
                                    role="button"
                                />
                            </Col>
                        </Row>
                        <Form className="py-5">
                            <Tabs
                                onSelect={(index) => tabChangeHandler(index)}
                                selectedIndex={activeTab}
                            >
                                <Row>
                                    <Col
                                        xs="12"
                                        lg="8"
                                        className="d-flex align-items-center"
                                    >
                                        <TabList>
                                            {candidateData?.interviewDetails?.[indexOfInterviewDetails]?.interviewSkills &&
                                                candidateData?.interviewDetails?.[indexOfInterviewDetails]?.interviewSkills.map(
                                                    (item, index) => {
                                                        return (
                                                            <Tab key={index}>
                                                                {
                                                                    item?.skillName
                                                                }
                                                            </Tab>
                                                        );
                                                    }
                                                )}
                                        </TabList>
                                    </Col>
                                    <Col
                                        xs="12"
                                        lg="4"
                                        className="d-flex align-items-center justify-content-end"
                                    >
                                        {candidateData?.interviewDetails?.[indexOfInterviewDetails]?.interviewSkills?.length > 0 && (
                                            <Form className="d-flex w-100">
                                                <SearchString
                                                    {...searchCompObj}
                                                />
                                                <Form.Group className="ml-3 float-right">
                                                    <Form.Check
                                                        type="checkbox"
                                                        className="checkBox"
                                                        checked={
                                                            interviewerFilters?.isPractical
                                                        }
                                                        onChange={(e) =>
                                                            filterHandler(
                                                                "isPractical",
                                                                e.target.checked
                                                            )
                                                        }
                                                    />
                                                </Form.Group>
                                                <Form.Label className="label ml-2 d-flex align-items-center mb-0">
                                                    Practical
                                                </Form.Label>
                                            </Form>
                                        )}
                                    </Col>
                                    <Col
                                        xs="12"
                                        md="12"
                                        lg="12"
                                        className="py-3 "
                                    >
                                        {isLoading ? (
                                            <Loader />
                                        ) : (
                                            <InfiniteScroll
                                                loadMore={loadMore}
                                                hasMore={
                                                    interviewerFilters?.pageNo +
                                                    1 <
                                                    interviewerFilters?.totalPages
                                                }
                                                loader={<Loader />}
                                                initialLoad={false}
                                                threshold={500}
                                                useWindow={true}
                                            >
                                                {candidateData?.interviewDetails?.[indexOfInterviewDetails]?.interviewSkills ? (
                                                    candidateData?.interviewDetails?.[indexOfInterviewDetails]?.interviewSkills.map(
                                                        (item, index) => {
                                                            return (
                                                                <TabPanel
                                                                    key={index}
                                                                >
                                                                    <Accordion>
                                                                        {interviewerListOfQuestions?.length >
                                                                            0 ? (
                                                                            interviewerListOfQuestions.map(
                                                                                (
                                                                                    item,
                                                                                    index
                                                                                ) => {
                                                                                    return (
                                                                                        <div
                                                                                            key={
                                                                                                index
                                                                                            }
                                                                                        >
                                                                                            <AccordionWrapper
                                                                                                key={
                                                                                                    index
                                                                                                }
                                                                                                className={`${askedQuestionIndex(
                                                                                                    item?.id
                                                                                                ) !==
                                                                                                    -1 &&
                                                                                                    "MeetingDashboardQuestion--disabled"
                                                                                                    } ${activeSizeData?.id ===
                                                                                                    item?.id &&
                                                                                                    "action"
                                                                                                    }`}
                                                                                            >
                                                                                                {/* Desktop view */}
                                                                                                <InterviewAccordionContent
                                                                                                    key={
                                                                                                        index
                                                                                                    }
                                                                                                    activeSize={
                                                                                                        activeSize
                                                                                                    }
                                                                                                    title={
                                                                                                        item?.question
                                                                                                    }
                                                                                                    practical={
                                                                                                        item?.isPractical ===
                                                                                                        true && (
                                                                                                            <>
                                                                                                                Practical
                                                                                                            </>
                                                                                                        )
                                                                                                    }
                                                                                                    skills={
                                                                                                        item?.skillName
                                                                                                    }
                                                                                                    questionLevel={
                                                                                                        item?.skillLevel
                                                                                                    }
                                                                                                    content={
                                                                                                        item
                                                                                                    }
                                                                                                    displayData={(
                                                                                                        type
                                                                                                    ) => {
                                                                                                        setMarks(
                                                                                                            askedQuestionIndex(
                                                                                                                item?.id
                                                                                                            ) !==
                                                                                                                -1
                                                                                                                ? questionDetails[
                                                                                                                    askedQuestionIndex(
                                                                                                                        item?.id
                                                                                                                    )
                                                                                                                ]
                                                                                                                    ?.obtainedMarks
                                                                                                                : item?.maxScore
                                                                                                        );
                                                                                                        setActiveSizeData(
                                                                                                            item
                                                                                                        );
                                                                                                        setActiveSize(
                                                                                                            "answer"
                                                                                                        );
                                                                                                    }}
                                                                                                    disable={
                                                                                                        activeSizeData?.id ===
                                                                                                        item?.id
                                                                                                    }
                                                                                                    classUsed={`${askedQuestionIndex(
                                                                                                        item?.id
                                                                                                    ) !==
                                                                                                        -1
                                                                                                        ? "d-block"
                                                                                                        : "d-none"
                                                                                                        }`}
                                                                                                />
                                                                                                {/* Mobile view */}
                                                                                            </AccordionWrapper>
                                                                                            {activeSizeData?.id ===
                                                                                                item?.id && (
                                                                                                    <div className="p-3 border border-top-0 mb-3 rounded-bottom mt--1 ">
                                                                                                        {
                                                                                                            activeSizeData?.answer
                                                                                                        }
                                                                                                        <AnswerStatus
                                                                                                            setMarks={
                                                                                                                setMarks
                                                                                                            }
                                                                                                            activeSizeData={
                                                                                                                activeSizeData
                                                                                                            }
                                                                                                            questionAnswerHandler={
                                                                                                                questionAnswerHandler
                                                                                                            }
                                                                                                            setActiveSizeData={
                                                                                                                setActiveSizeData
                                                                                                            }
                                                                                                            answerStatus={answerStatusHandler(
                                                                                                                item?.id
                                                                                                            )}
                                                                                                            marks={
                                                                                                                marks
                                                                                                            }
                                                                                                            classUsed={`${askedQuestionIndex(
                                                                                                                item?.id
                                                                                                            ) !==
                                                                                                                -1
                                                                                                                ? "d-block"
                                                                                                                : "d-none"
                                                                                                                }`}
                                                                                                        />
                                                                                                    </div>
                                                                                                )}
                                                                                        </div>
                                                                                    );
                                                                                }
                                                                            )
                                                                        ) : (
                                                                            <AccordionWrapper>
                                                                                <LabelField
                                                                                    label="No data found"
                                                                                    id="noData"
                                                                                />
                                                                            </AccordionWrapper>
                                                                        )}
                                                                    </Accordion>
                                                                </TabPanel>
                                                            );
                                                        }
                                                    )
                                                ) : (
                                                    <AccordionWrapper>
                                                        <LabelField
                                                            label="No data found"
                                                            id="noData"
                                                        />
                                                    </AccordionWrapper>
                                                )}
                                            </InfiniteScroll>
                                        )}
                                    </Col>
                                </Row>
                            </Tabs>
                        </Form>
                    </Container>
                </div>
            </div>
        </>
    );
}
export { MeetingDashboard };
