import React, { useState, useEffect } from "react";

import { cloneDeep } from "lodash";
import { Row, Col, Container, Form } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import InfiniteScroll from "react-infinite-scroller";

import { useProvider } from "../hooks/useProvider/useProvider";
import { AddQuestions } from "../AddQuestions";
import { AccordionWrapper } from "../UI/AccordionWrapper";
import { SidePanel } from "../UI/SidePanel/SidePanel";
import leftArrow from "../../Assets/Images/Manage/leftArrow.svg";
import { CustomSelect } from "../UI/CustomSelect";
import { Button } from "../UI/Button";
import Edit from "../../Assets/Images/Manage/edit.svg";
import Delete from "../../Assets/Images/Manage/delete.svg";
import AccordionContent from "../AccordionContent/AccordionContent";
import { Label } from "../UI/Label";
import { Loader } from "../UI/Loader";
import "./QuestionDashboard.scss";
import { Login } from "../Auth/Login/Login";

function QuestionDashboard() {
    const {
        getQuestions,
        questionDashboardFilters,
        setQuestionDashboardFilters,

        listOfQuestions,
        setIsFilters,
        isFilters,
        deleteQuestion,
        skillSets,
        editQuestionSet,
        isLoading,
        isTokenActive,
    } = useProvider();
    const [activeSize, setActiveSize] = useState("");
    const [activeSizeData, setActiveSizeData] = useState("");
    const questionFilters = cloneDeep(questionDashboardFilters);

    const levelList = [
        { value: "All", label: "All" },
        { value: "Beginner", label: "Beginner" },
        { value: "Intermediate", label: "Intermediate" },
        { value: "Experienced", label: "Experienced" },
    ];

    const skillSet = [{ value: "ALL", label: "All" }, ...skillSets];

    const pageSizeArray = [];
    for (let i = 1; i <= 5; i++) {
        pageSizeArray.push({ value: i * 10, label: i * 10 });
    }

    const filtersHandler = (fieldName, data) => {
        setActiveSize("");
        const prevFilterState = cloneDeep(isFilters);

        if (fieldName === "skillLevel") {
            prevFilterState.skillLevel =
                data?.value === "All" ? "" : data?.value;
        } else if (fieldName === "skillId") {
            prevFilterState.skillId = data?.skillIds ? data?.skillIds : "";
        } else {
            prevFilterState[fieldName] = data;
        }
        prevFilterState.pageNo = 0;
        // setIsFilters(prevFilterState);
        getQuestions(prevFilterState);

        setQuestionDashboardFilters({ ...questionFilters, [fieldName]: data });
    };

    const deleteQuestionHandler = (data) => {
        const prevFilterState = cloneDeep(isFilters);
        setActiveSize("");
        deleteQuestion(data);
        prevFilterState.pageNo = 0;
        getQuestions(prevFilterState);
    };

    const closeModal = (e) => {
        setActiveSize("");
        setActiveSizeData("");
    };
    

    const getQuestionDataByIdHandler = async (type, data) => {
        const newData = await editQuestionSet(data);

        if (newData?.status === "success") {
            let resultData = newData?.data;
            const skillData = newData?.data?.skill;
            delete resultData?.skill;
            resultData.skillId = skillData?.id;
            setActiveSizeData(resultData);
            setActiveSize(type === "answer" ? "answer" : "editQuestion");
        }
    };

    const loadMore = () => {
        const page = isFilters?.pageNo + 1;
        if (page < isFilters?.totalPages) {
            let filters = isFilters;
            filters.pageNo = page;
            getQuestions(filters);
        }
    };
    if (!localStorage.getItem("token") && !isTokenActive) {
        return <Login />;
    }
    return (
        <>
            <div className="QuestionDashboard">
                <div className={`FilterBar__content ${activeSize && "active"}`}>
                    <Row className="my-5">
                        <Col
                            lg={4}
                            md={4}
                            sm={6}
                            className={`FilterBar__question ${
                                activeSize && "active"
                            }`}
                        >
                            <Label
                                name="Skill Level"
                                classused="Label_normal"
                            />

                            <CustomSelect
                                id="skillLevel"
                                options={levelList}
                                placeholder="Select skill"
                                value={questionDashboardFilters?.skillLevel}
                                onChange={(data) => {
                                    filtersHandler("skillLevel", data);
                                }}
                                role="button"
                            />
                        </Col>
                        <Col
                            lg={4}
                            md={4}
                            sm={6}
                            className={`FilterBar__question ${
                                activeSize && "active"
                            }`}
                        >
                            <Label name="Skills" classused="Label_normal" />
                            <CustomSelect
                                id="skills"
                                options={skillSet}
                                placeholder="Select skill"
                                value={questionDashboardFilters?.skillId}
                                onChange={(data) => {
                                    filtersHandler("skillId", data);
                                }}
                                role="button"
                            />
                        </Col>
                        <Col
                            lg={4}
                            md={4}
                            sm={6}
                            className={`d-flex justify-content-start align-items-center mt-4 FilterBar__question ${
                                activeSize && "active"
                            }`}
                        >
                            <input
                                id="practical"
                                checked={questionDashboardFilters?.isPractical}
                                type="checkbox"
                                onChange={(data) => {
                                    filtersHandler(
                                        "isPractical",
                                        data.target.checked
                                    );
                                }}
                                className="InputCheckBox"
                                role="button"
                            />
                            <Label
                                name="Practical"
                                classused="Label_normal mx-2 mb-0"
                            />
                        </Col>
                    </Row>
                    <div className="FilterBar__questionAccordion">
                        <div className="">
                            <Container className="FilterBar__questionAccordionTitle">
                                <Row className="">
                                    <Col
                                        lg={8}
                                        md={8}
                                        xs={6}
                                        className={`d-flex align-items-center FilterBar__question ${
                                            activeSize && "active"
                                        }`}
                                    >
                                        <Label
                                            name="Questions"
                                            classused="Label_medium"
                                        />
                                    </Col>
                                    <Col
                                        lg={4}
                                        md={4}
                                        xs={6}
                                        className={`d-flex justify-content-end button_active ${
                                            activeSize && "active"
                                        }`}
                                    >
                                        <Button
                                            onClick={() => {
                                                setActiveSizeData({});
                                                setActiveSize("question");
                                            }}
                                            className={` ${
                                                activeSize && "active"
                                            }`}
                                            value="Add Question"
                                            variant="secondary"
                                            // disabled={activeSize !== ""}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col
                                        xs="12"
                                        md="12"
                                        lg="12"
                                        className="py-3"
                                    >
                                        {isLoading ? (
                                            <Loader />
                                        ) : (
                                            <InfiniteScroll
                                                loadMore={loadMore}
                                                hasMore={
                                                    isFilters?.pageNo + 1 <
                                                    isFilters?.totalPages
                                                }
                                                loader={<Loader />}
                                                initialLoad={false}
                                                threshold={500}
                                                useWindow={true}
                                                
                                            >
                                                {listOfQuestions?.length > 0 ? (
                                                    listOfQuestions.map(
                                                        (item, index) => {
                                                            return (
                                                                <div key={index}>
                                                                <AccordionWrapper
                                                                    className={
                                                                        activeSizeData?.id ===
                                                                            item?.id &&
                                                                        "action"
                                                                    }
                                                                    key={index}
                                                                >
                                                                    <AccordionContent
                                                                        key={
                                                                            index
                                                                        }
                                                                        activeSize={
                                                                            activeSize
                                                                        }
                                                                        // setActiveSize={setActiveSize}
                                                                        title={
                                                                            item?.question
                                                                        }
                                                                        practical={
                                                                            item?.isPractical ===
                                                                                true && (
                                                                                <>
                                                                                    Practical
                                                                                </>
                                                                            )
                                                                        }
                                                                        skills={[
                                                                            item?.skillName,
                                                                        ]}
                                                                        questionLevel={
                                                                            item?.skillLevel
                                                                        }
                                                                        content={
                                                                            item?.answer
                                                                        }
                                                                        displayData={(
                                                                            type
                                                                        ) => {
                                                                            getQuestionDataByIdHandler(
                                                                                type,
                                                                                item?.id
                                                                            );
                                                                        }}
                                                                        disable={
                                                                            activeSizeData?.id ===
                                                                            item?.id
                                                                        }
                                                                        deleteQuestionHandler={() => {
                                                                            deleteQuestionHandler(
                                                                                item?.id
                                                                            );
                                                                        }}
                                                                    />
                                                           
                                                                </AccordionWrapper>
                                                                {activeSize ===
                                                                        "answer" &&
                                                                        activeSizeData?.id ===
                                                                            item?.id && (
                                                                            <div
                                                                                className="p-3 border border-top-0 mb-3 show_answer rounded-bottom hide_content_sm"
                                                                            >
                                                                                {
                                                                                    activeSizeData?.answer
                                                                                }
                                                                            </div>
                                                                        )}
                                                                </div>
                                                            );
                                                        }
                                                    )
                                                ) : (
                                                    <div className="FilterBar__notFound">
                                                        No data found
                                                    </div>
                                                )}
                                            </InfiniteScroll>
                                        )}
                                    </Col>
                                </Row>
                            </Container>
                            {activeSize !== "answer" && (
                            <div
                                className={`FilterBar__screenSize ${
                                    activeSize && "notActive"
                                }`}
                            >
                                <SidePanel>
                                    {activeSize === "question" ||
                                    activeSize === "editQuestion" ? (
                                        <AddQuestions
                                            activeSize={activeSize}
                                            setActiveSize={setActiveSize}
                                            question={
                                                activeSizeData && activeSizeData
                                            }
                                            setActiveSizeData={
                                                setActiveSizeData
                                            }
                                        />
                                    ) : (
                                        activeSize === "answer" && (
                                            // <QuestionAnswer activeSizeData={activeSizeData} />

                                            <>
                                                
                                            </>
                                        )
                                    )}
                                </SidePanel>
                            </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export { QuestionDashboard };