import React, { useState } from "react";

import { Row, Col, Card } from "react-bootstrap";
import { differenceWith } from "lodash";
import Accordion from "react-bootstrap/Accordion";

import { useProvider } from "../hooks/useProvider/useProvider";
import { AccordionWrapper } from "../UI/AccordionWrapper";
import { SidePanel } from "../UI/SidePanel/SidePanel";
import leftArrow from "../../Assets/Images/Manage/leftArrow.svg";
import { InputField } from "../UI/InputField";
import { CustomSelect } from "../UI/CustomSelect";
import { Button } from "../UI/Button";
import AccordionContent from "../AccordionContent/AccordionContent";
import { Label } from "../UI/Label";
import Edit from "../../Assets/Images/Manage/edit.svg";
import Delete from "../../Assets/Images/Manage/delete.svg";
import { Loader } from "../UI/Loader";
import "./MapSkills.scss";
import { Login } from "../Auth/Login/Login";
import { Pills } from "../UI/Pills";

const MapSkills = () => {
  const {
    mapSkills,
    skillSets,
    deleteMappedSkill,
    listOfSkillset,
    skillsets,
    editSkillsetSet,
    isLoading,
    setIsLoading,
    isTokenActive,  
    skills
    
  } = useProvider();

  const [activeSize, setActiveSize] = useState("");
  const initialState = {
    skillSetName: "",
    skillIds: [],
  };
  const [skillMatches, setSkillMatches] = useState([]);
  const [mapSkillset, setMapSkillset] = useState(initialState);
  const [isErrors, setIsErrors] = useState(initialState);

  const errors = {
    skillSetName: "Please add Skill set name",
    skillSetNameExist: "This Skill name is already exist",
    skillIds: "Please add Skills",
  };

  const mapSkillStateHandler = (fieldName, data) => {
    setMapSkillset((previousState) => {
      return { ...previousState, [fieldName]: data };
    });
    if (fieldName === "skillSetName" && data !== "") {
      let matchedSkill = [];
      listOfSkillset.map((item) => {
        if (item.skillSetName.toLowerCase().includes(data.toLowerCase())) {
          matchedSkill.push(item.skillSetName);
        }
      });
      setSkillMatches(matchedSkill);
    } else {
      setSkillMatches([]);
    }
  };

  // filter to get the list of skills based on the selected value
  const selectedSkillHandler = () => {
    let mapSkillObject = mapSkillset;
    let skillIds = [];
    const selectedSkills = mapSkillset?.skillIds;
    for (let i = 0; i < selectedSkills?.length; i++) {
      skillIds.push(selectedSkills[i]?.skillIds);

    }
    mapSkillObject["skillSetName"] = mapSkillset?.skillSetName;
    
    mapSkillObject["skillIds"] = skillIds;
    return mapSkillObject;
  };

  const errorHandler = (data, fieldName) => {
    if (fieldName) {
      const isMatching = skillMatches?.filter(
        (item) => item?.toLowerCase() === data?.toLowerCase()
      );
      const fieldKey =
        fieldName === "skillSetName"
          ? isMatching?.length > 0
            ? "skillSetNameExist"
            : fieldName
          : fieldName;
      if (data === "" || data?.length === 0 || isMatching?.length > 0) {
        setIsErrors((previousState) => {
          return { ...previousState, [fieldName]: errors?.[fieldKey] };
        });
      } else {
        const errors = isErrors;
        delete errors?.[fieldName];
        setIsErrors(errors);
      }
    } else {
      const isEmpty = {};
      for (const [key, value] of Object.entries(mapSkillset)) {
        if (value?.length === 0) {
          isEmpty[key] = errors?.[key];
          setIsErrors((previousState) => {
            return { ...previousState, [key]: errors?.[key] };
          });
        }
      }
      return isEmpty;
    }
  };

  const handleSkillCategory = (e) => {
    const isEmpty = errorHandler(mapSkillset);
    if (Object.keys(isEmpty || {})?.length === 0) {
      setIsLoading(true);
      const updatedSkills = selectedSkillHandler();

      mapSkills(updatedSkills);
      setActiveSize("");
      setMapSkillset(initialState);
      setIsErrors({});
    }
  };

  const closeModal = () => {
    setActiveSize("");
    setMapSkillset(initialState);
    setIsErrors({});
  };

  const deleteMappedSkillHandler = (data) => {
    deleteMappedSkill(data);
  };

  const getSkillsetDataByIdHandler = (data) => {
    editSkillsetSet(data);
  };

  const getSubSkillObjectConverter = (data) => {
    const subSkill = [];
    for (let i = 0; i < data?.length; i++) {
      const filteredData = skills.filter((item) => item?.id === data?.[i]?.id)

      if(filteredData?.length > 0){
        const skillObject = {
          value: filteredData?.[0]?.skillName,
          label: filteredData?.[0]?.skillName,
          skillIds: filteredData?.[0]?.id,
        };
        subSkill.push(skillObject);
      }
    }
    return subSkill;
  };

  const existingSkillHandler = (data) => {
    setIsErrors({});
    setSkillMatches([]);
    const skillSetData = getSubSkillObjectConverter(data?.skills);

    // var filteredSkillSet = differenceWith(
    //   skillSetData,
    //   skillsets,
    //   function (skills, skillset) {
    //     return skills["value"] === skillset["value"];
    //   }
    // );
    const isSkills = mapSkillset;
    isSkills.id = data?.id;
    isSkills.skillSetName = data?.skillSetName;
    isSkills.skillIds = skillSetData;
    setMapSkillset({ ...mapSkillset, ...isSkills });
  };

  const styles = {
    container: (base) => ({
      ...base,
      // flex: 5,
      flexGrow: 1,
      width: "200%",
    }),
  };

  if (isLoading) {
    return <Loader />;
  }
  if (!localStorage.getItem("token") && !isTokenActive) {
    return <Login />;
}
  return (
    <>
      <div className="MapSkillsDashboard">
        <div className={`MapSkills__content ${activeSize && "active"}`}>
          <div className="MapSkills__questionAccordion">
            <div className="mobileSize_QuestionDiv">
              <div className="MapSkills__questionAccordionTitle">
                <Row className="my-5">
                  <Col
                    lg={8}
                    md={8}
                    xs={6}
                    className={`d-flex align-items-center  ${
                      activeSize && "active"
                    }`}
                  >
                    <Label name="Skillsets" classused="Label_medium" />
                  </Col>
                  <Col
                    lg={4}
                    md={4}
                    xs={6}
                    className={`d-flex justify-content-end MapSkills__buttonSize ${
                        activeSize && "active"
                    }`}
                  >
                    <Button
                      onClick={() => {
                        setActiveSize("mapSkill");
                        setMapSkillset(initialState);
                      }}
                      className={` ${
                        activeSize && "active"
                      }`}
                      value="Add Skillset"
                      variant="secondary"
                      disabled={activeSize !== ""}
                    />
                  </Col>
                </Row>
              </div>

              <div
                className={`FilterBar__screenSize ${activeSize && "notActive"}`}
              >
                <SidePanel>
                  {(activeSize === "mapSkill" ||
                    activeSize === "editmapSkill") && (
                    <div className="MapSkill__container MapSkill__container-main py-sm-5">
                      <main className="MapSkill__main-content">
                        <form className="MapSkill__article-featured">
                          <div className="MapSkill__controls">
                            <div className="text-center mb-3 font-weight-bold h3 MapSkill__containerHeading">
                              {" "}
                              <div
                                onClick={closeModal}
                                className=""
                              >
                                <img
                                  src={leftArrow}
                                  className="closeImage"
                                  alt=""
                                ></img>
                              </div>
                              <Label
                                name={
                                  activeSize === "editmapSkill"
                                    ? "Edit Skillset"
                                    : "Add Skillset"
                                }
                                classused="Label_medium"
                              />
                            </div>
                            <Row className="align-items-center justify-content-center my-sm-5">
                        <Col
                            xs="12"
                            sm="12"
                            md="9"
                            lg="6"
                            className="my-md-3 align-self-center"
                        >
                            
                            <div className="MapSkill__control mb-3">
                              <InputField
                                id="skillSetName"
                                name="skillSetName"
                                type="text"
                                label="Skillset"
                                value={mapSkillset?.skillSetName}
                                onChange={(e) => {
                                  mapSkillStateHandler(
                                    "skillSetName", e.target.value
                                  );
                                  errorHandler(e.target.value, "skillSetName");
                                }}
                                disabled={activeSize === "editmapSkill"}
                                onBlur={(e) => {
                                  errorHandler(e.target.value, "skillSetName");
                                  mapSkillStateHandler("skillSetName", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))
                                }}
                                onKeyUp={(e) => {
                                  errorHandler(e.target.value, "skillSetName");
                                }}
                                required={
                                  <span
                                    className="mt-3 fs-6 CandidateDetails_required"
                                  >
                                    *
                                  </span>
                                }
                              />
                              {isErrors?.skillSetName && (
                                <div className="CandidateDetails_required">
                                  {isErrors?.skillSetName}
                                </div>
                              )}
                            </div>
                            {skillMatches?.length > 0 && (
                              <Card className="my-3 ulStyling">
                                <Card.Body className="p-0">
                                  <ul className="p-3">
                                    {skillMatches.map((item,index) => {
                                      return (
                                        <li className="my-0 border-bottom mb-3" key={index}>
                                          {item}
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </Card.Body>
                              </Card>
                            )}

                            <div className="MapSkill__control">
                              <CustomSelect
                                id="skills"
                                options={skillSets}
                                placeholder="Select skill"
                                value={mapSkillset?.skillIds}
                                label="Skills"
                                onChange={(data) => {
                                  errorHandler(data, "skillIds");
                                  mapSkillStateHandler("skillIds", data);
                                }}
                                onBlur={(e) => {
                                  errorHandler(mapSkillset?.skillIds, "skillIds");
                                }}
                                required={
                                    <sup
                                      className="mt-3 mx-1 fs-6 CandidateDetails_required"
                                    >
                                      *
                                    </sup>
                                  }
                                styles={styles}
                                isMulti
                              />
                              {isErrors?.skillIds && (
                                <div className="CandidateDetails_required">
                                  {isErrors?.skillIds}
                                </div>
                              )}
                            </div>
                            <div className="MapSkill__modalButton d-flex justify-content-end">
                              <Button
                                type="button"
                                onClick={closeModal}
                                className="me-3 mt-4 primary_color"
                                value="Cancel"
                                variant="secondary"
                              />
                              <Button
                                type="button"
                                onClick={() => {
                                  handleSkillCategory();
                                }}
                                className="mt-4 "
                                value={
                                  activeSize === "editmapSkill"
                                    ? "Update"
                                    : "Add Skillset"
                                }
                                variant="secondary"
                              />
                            </div>
                            </Col>
                            </Row>
                          </div>
                        </form>
                      </main>
                    </div>
                  )}
                </SidePanel>
              </div>
            </div>
            <Row className="mobileSize_div">
              <Col lg={12} xs={12}>
                {listOfSkillset?.length > 0 ? (
                  listOfSkillset.map((item, index) => {
                    return (
                      item?.skills?.length > 0 && (
                        <AccordionWrapper
                          className={mapSkillset?.id === item?.id && "action"}
                          key={index}
                        >
                          <AccordionContent
                            key={index}
                            activeSize={activeSize}
                            setActiveSize={setActiveSize}
                            title={item?.skillSetName}
                            skills={item?.skills}
                            displayData={() => {
                              existingSkillHandler(item);

                              setActiveSize("editmapSkill");
                              getSkillsetDataByIdHandler(item?.id);
                            }}
                            deleteQuestionHandler={() => {
                              deleteMappedSkillHandler(item?.id);
                              setActiveSize("");
                            }}
                            disable={mapSkillset?.id?.skillId === item?.skillId}
                            // questionLevel={item?.skills}
                          />
                     
                        </AccordionWrapper>
                      )
                    );
                  })
                ) : (
                  <div className="MapSkill__notFound">No data found</div>
                )}
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

export { MapSkills };
