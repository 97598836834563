import React, { useEffect, useState } from "react";

import { Label } from "../../UI/Label";
import { Stack, Form } from "react-bootstrap";
import { Button } from "../../UI/Button";
import "./AnswerStatus.scss";

function AnswerStatus({
  setMarks,
  activeSizeData,
  // setAnswerStatus,
  questionAnswerHandler,
  answerStatus,
  closeModal,
  setActiveSizeData,
  marks,
}) {

  const [answered, setAnswered] = useState("");

  useEffect(()=> {
    setAnswered(answerStatus);
  }, []);
  
  return (
    <div className="AnswerStatus">
      <div className="mt-4">
        <Label name="Correct Answer?" classused="Label_medium mb-3 fs-5" />
        <Stack direction="horizontal" gap={5}>
          <>
            <div className="InterviewAnswer__radio">
              <label className="InterviewAnswer__radioLabel">
                <input
                  type="radio"
                  value="Yes"
                  className="RadioButton"
                  onChange={() => {
                    // setMarks(activeSizeData?.maxScore);
                    // setAnswerStatus({id: activeSizeData?.id, status:"Yes"});
                    // questionAnswerHandler(activeSizeData?.maxScore, "Yes");
                    setAnswered("Yes");
                  }}
                  checked={answered === "Yes"}
                />
                <span className="mt-1 px-2">Yes</span>
              </label>
            </div>

            <div className="InterviewAnswer__radio">
              <label className="InterviewAnswer__radioLabel">
                <input
                  type="radio"
                  value="No"
                  size="lg"
                  className="RadioButton"
                  onChange={() => {
                    setMarks(0);
                    // setAnswerStatus({id: activeSizeData?.id, status:"No"});
                    questionAnswerHandler(0,"No");
                    setAnswered("No");

                    
                    setActiveSizeData({});
                    
                    
                  }}
                  
                  checked={answered === "No"}
                />
                <span className="mt-1 px-2">No</span>
              </label>
            </div>
          </>
        </Stack>
        {answered === "Yes" && (
          <>
            <Form.Group className="my-3">
              <div className="d-flex justify-content-between">
                <Label name={`Marks: 1 - ${activeSizeData?.maxScore}`} classused="Label_normal" id="minMarks" />
                {/* <Label
                  name={`Max: ${activeSizeData?.maxScore}`}
                  classused="Label_normal"
                  id="minMarks"
                /> */}
              </div>
              <Form.Control
                type="number"
                value={marks}
                onChange={(e) => {
                  if (e.target.value <= activeSizeData?.maxScore) {
                    setMarks(e.target.value);
                  }
                }}
                max={activeSizeData?.maxScore}
                min={1}
              />
            </Form.Group>
            <div className="d-flex justify-content-end">
              <Button
                type="button"
                onClick={(e) => {
                  setActiveSizeData({});
                  questionAnswerHandler(parseInt(marks),"Yes");
                  // closeModal();
                 
                }}
                size="size"
                className=" my-2 flex-end align-self-end float-right "
                value="Submit"
                variant="secondary"
                disabled={!marks && true}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}
export { AnswerStatus };