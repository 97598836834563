import React, { useState } from "react";

import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Accordion from "react-bootstrap/Accordion";

import Answer from "../../Assets/Images/Manage/answer.svg";
import Edit from "../../Assets/Images/Manage/edit.svg";
import Delete from "../../Assets/Images/Manage/delete.svg";
import { InterviewModal } from "../UI/InterviewModal";
import { Pills } from "../UI/Pills";
import { Button } from "../UI/Button";
import "./AccordionContent.scss";
import { Col, Row } from "react-bootstrap";

const AccordionContent = ({
    displayData,
    activeSize,
    setActiveSize,
    questionLevel,
    title,
    practical,
    content,
    deleteQuestionHandler,
    deleteMappedSkill,
    id,
    skills,
    disable,
    pillClass,
}) => {
    const [active, setActive] = useState(false);
    const [task1Show, setTask1Show] = useState(false);

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {title}
        </Tooltip>
    );

    return (
        <>
            <div className="hide_content_sm">
                <Row className="justify-content-between hide_content_sm">
                    <Col lg={8} md={8} className="d-flex align-items-center">
                        <div className="d-flex align-items-center">
                            <OverlayTrigger
                                placement="right"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltip}
                            >
                                <span className="ChatItemUser__name accordion__title mx-1">
                                    {title && title.substring(0, 30)}
                                    {title && title.length > 30 ? "..." : null}
                                </span>
                            </OverlayTrigger>
                            {/* </p> */}
                        </div>
                        <div className="">
                            {/* practical */}
                            {practical && (
                                <Pills
                                    practical={practical}
                                    style={{
                                        border: practical && "1px solid red",
                                    }}
                                    className="accordion__pillStyling pill_color"
                                    bg="#F6A541"
                                />
                            )}
                            {}

                            {/* <div> */}

                            {/* skill */}
                            {skills &&
                                skills.map((item, index) => {
                                    return (
                                        <Pills
                                            key={index}
                                            practical={typeof item === "object" ? item?.skillName : item}
                                            // practical={item?.skillName}
                                            className="accordion__pillStyling"
                                            
                                            bg="secondary"
                                        />
                                    );
                                })}
                            {/* </div> */}

                            {/* skillLevel */}
                           
                            {questionLevel && (
                                <Pills
                                    practical={questionLevel}
                                    style={{
                                        border: practical && "1px solid red",
                                    }}
                                    className="accordion__pillStyling secondary_color text-dark"
                                    pillClass={pillClass}
                                    bg="danger"
                                />
                            )}
                        </div>
                    </Col>
                    <Col lg={4} md={4} sm={6}>
                        <div className="accordion__action d-flex align-items-center justify-content-end">
                            {content && (
                                <div
                                    className={` ${
                                        active && "notActive"
                                    } mx-2 icon_Style d-flex `}
                                    onClick={() => {
                                        // setActiveSize("answer");
                                        displayData("answer");
                                    }}
                                    disabled={
                                        activeSize === "answer" && disable
                                    }
                                >
                                    {" "}
                                    <img
                                        className="icon_Style me-2"
                                        src={Answer}
                                        alt=""
                                    ></img>{" "}
                                    <span
                                        className={`hide_content_sm hide_span`}
                                    >
                                        Answer
                                    </span>
                                </div>
                            )}

                            <div
                                onClick={() => {
                                    // setActiveSize("editQuestion");
                                    displayData("editQuestion");
                                }}
                                disabled={
                                    (activeSize === "editQuestion" ||
                                        activeSize === "editmapSkill") &&
                                    disable
                                }
                                className="mx-2 icon_Style d-flex"
                            >
                                <img
                                    className="icon_Style me-2"
                                    src={Edit}
                                    alt=""
                                />{" "}
                                {/* <span
                                className={` ${
                                    activeSize && "d-none"
                                } hide_content_sm`}
                            >
                                Edit
                            </span> */}
                                <span className={`hide_content_sm hide_span`}>
                                    Edit
                                </span>
                            </div>

                            <div
                                onClick={() => {
                                    setTask1Show(true);
                                }}
                                className="mx-2 icon_Style d-flex"
                            >
                                <img
                                    className="me-1 icon_Style"
                                    src={Delete}
                                    alt=""
                                />
                                <span className={`hide_content_sm hide_span`}>
                                    Delete
                                </span>
                            </div>
                            <InterviewModal
                                show={task1Show}
                                handleClose={() => setTask1Show(false)}
                                handleShow={() => setTask1Show(true)}
                                modalTitle={`Do you want to delete?`}
                            >
                                <Button
                                    onClick={() => setTask1Show(false)}
                                    className="mt-2 primary_color"
                                    value="Cancel"
                                />
                                <Button
                                    onClick={() => {
                                        deleteQuestionHandler();
                                        setTask1Show(false);
                                    }}
                                    className="mx-2 mt-2"
                                    value="Delete"
                                />
                            </InterviewModal>
                        </div>
                    </Col>
                </Row>
            </div>
            <Accordion className="bg_color hide_content_md my-1">
                <Accordion.Item eventKey="0" className="my-1">
                    <Accordion.Header className="bg_color  text-dark">
                        {title}
                    </Accordion.Header>
                    <Accordion.Body>
                        {practical && (
                            <Pills
                                practical={practical}
                                style={{
                                    border: practical && "1px solid red",
                                }}
                                className="accordion__pillStyling"
                                pillClass={pillClass}
                                bg="danger"
                            />
                        )}
                        {questionLevel && (
                            <Pills
                                practical={questionLevel}
                                style={{
                                    border: practical && "1px solid red",
                                }}
                                className="accordion__pillStyling secondary_color text-dark"
                                pillClass={pillClass}
                                bg="danger"
                            />
                        )}
                        {/* {questionLevel &&
                                questionLevel.map((item, index) => {
                                    return (
                                        
                                            <Pills
                                                key={index}
                                                practical={item}
                                                className="accordion__pillStyling secondary_color text-dark"
                                                bg="#F6A541"
                                                pillClass={pillClass}
                                            />
                                            
                                        
                                    );
                                    
                                })} */}

                        {skills &&
                            skills.map((item, index) => {
                                return (
                                    <Pills
                                        key={index}
                                        practical={typeof item === "object" ? item?.skillName : item}
                                        className="accordion__pillStyling"
                                        bg="secondary"
                                        pillClass={pillClass}
                                    />
                                );
                            })}
                        {/* skillLevel */}

                        {content}

                        <div className="d-flex justify-content-end">
                            <span
                                onClick={() => {
                                    // setActiveSize("editQuestion");
                                    displayData("editQuestion");
                                }}
                                disabled={
                                    (activeSize === "editQuestion" ||
                                        activeSize === "editmapSkill") &&
                                    disable
                                }
                                className="mx-1"
                            >
                                <img
                                    className="me-1 mt-1 iconSize icon_Style "
                                    src={Edit}
                                    alt=""
                                />{" "}
                            </span>
                            <span
                                onClick={() => {
                                    setTask1Show(true);
                                }}
                                className="mx-1"
                            >
                                <img
                                    className="mx-2 iconDeleteSize icon_Style"
                                    src={Delete}
                                    alt=""
                                />
                            </span>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </>
    );
};

export default AccordionContent;
