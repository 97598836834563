import React, { useState } from "react";
import { Row, Col,Container } from "react-bootstrap";

import { useProvider } from "../hooks/useProvider/useProvider";
import { Login } from "../Auth/Login/Login";
import { SidePanel } from "../UI/SidePanel/SidePanel";
import { Cards } from "../UI/Cards";
import leftArrow from "../../Assets/Images/Manage/leftArrow.svg";
import { InputField } from "../UI/InputField";
import { Button } from "../UI/Button";
import Card from "react-bootstrap/Card";
import { Label } from "../UI/Label";
import { Loader } from "../UI/Loader";
import "./AddSkills.scss";

function AddSkills() {
  const {
    createSkill,
    skills,
    deleteSkill,
    editSkillSet,
    isLoading,
    setIsLoading,
  } = useProvider();
  const [activeSize, setActiveSize] = useState("");

  const initialState = {
    skillName: "",
    isSkilled: false,
  };
  const [skillData, setSkillData] = useState(initialState);
  const [skillMatches, setSkillMatches] = useState([]);
  const [isErrors, setIsErrors] = useState({});

  const errors = {
    skillName: "Please add Skill name",
    skillNameExist: "This Skill name is already exist",
  };

  const errorHandler = (data, fieldName) => {
    if (fieldName) {
      const isMatching = skillMatches?.filter(
        (item) => item?.toLowerCase() === data?.toLowerCase()
      );
      const fieldKey = isMatching?.length > 0 ? "skillNameExist" : fieldName;
      if (data?.length === 0 || isMatching?.length > 0) {
        setIsErrors((previousState) => {
          return { ...previousState, [fieldName]: errors?.[fieldKey] };
        });
      } else {
        const errors = isErrors;
        delete errors?.[fieldName];
        setIsErrors(errors);
      }
    } else {
      const isEmpty = {};
      for (const [key, value] of Object.entries(skillData)) {
        if (value?.length === 0) {
          isEmpty[key] = errors?.[key];
          setIsErrors((previousState) => {
            return { ...previousState, [key]: errors?.[key] };
          });
        }
      }
      return isEmpty;
    }
  };

  const deleteSkillHandler = (data) => {
    deleteSkill(data);
  };

  const editSkillHandler = (data) => {
    setSkillMatches([]);
    const isSkills = skillData;
    isSkills.id = data?.id;
    isSkills.skillName = data?.skillName;
    isSkills.isSkilled = data?.isSkilled;
    setSkillData({ ...isSkills, ...isSkills });
    setIsErrors({});
  };

  const submitHandler = () => {
    const isEmpty = errorHandler(skillData);
    if (Object.keys(isEmpty)?.length === 0) {
        setIsLoading(true)
      createSkill(skillData);
      setActiveSize("");
      setSkillData(initialState);
      setSkillMatches([]);
      setIsErrors({});
    }
  };

  const skillChangeHandler = (fieldName, data) => {
    setSkillData((previousState) => {
      return { ...previousState, [fieldName]: data };
    });

    if (data !== "") {
      let matchedSkill = [];
      skills.map((item) => {
        if (item.skillName?.toLowerCase().includes(data?.toLowerCase())) {
          matchedSkill.push(item.skillName);
        }
      });
      setSkillMatches(matchedSkill);
    } else {
      setSkillMatches([]);
    }
  };

  const getSkillDataByIdHandler = (data) => {
    editSkillSet(data);
  };

  const closeModal = () => {
    setActiveSize("");
    setSkillData(initialState);
    setSkillMatches([]);
    setIsErrors({});
  };

  if (isLoading) {
    return <Loader />;
  }

  if (!localStorage.getItem("token")) {
    return <Login />;
  }

  return (
    <>
      <div className="AddSkillsDashboard">
        <div className={`AddSkills__content ${activeSize && "active"}`}>
          <div className="AddSkills__questionAccordion">
            <div className="AddSkills__skillDiv">
              <div className="AddSkills__questionAccordionTitle">
                <Row
                  className={`my-5  AddSkills__buttonSize ${
                    activeSize && "active"
                  }`}
                >
                  <Col
                    lg={8}
                    md={8}
                    xs={6}
                    className={`d-flex align-items-center  ${
                      activeSize && "active"
                    }`}
                  >
                    <Label name="Skills" classused="Label_medium" />
                  </Col>
                  <Col
                    lg={4}
                    md={4}
                    xs={6}
                    className={`d-flex justify-content-end AddSkill__mobileButton ${
                        activeSize && "active"
                    }`}
                  >
                    <Button
                      onClick={() => {
                        setActiveSize("skills");
                      }}
                      className={` ${
                        activeSize && "active"
                      }`}
                      variant="secondary"
                      value="Add Skill"
                      disabled={activeSize !== ""}
                    />
                  </Col>
                </Row>
              </div>
              <Row className="mobileSize_div">
                <Col lg={12} xs={12}>
                  <Row>
                    {skills?.length > 0 ? (
                      skills.map((item,index) => {
                        return (
                          <Col
                            lg={3}
                            md={4}
                            sm={6}
                            xs={12}
                            className="d-flex align-items-center"
                            key={index}
                          >
                            <Cards
                              skillName={item?.skillName}
                              deleteSkill={() => {
                                deleteSkillHandler(item?.id);
                                setActiveSize("");
                              }}
                              editSkill={() => {
                                editSkillHandler(item);
                                setActiveSize("editSkill");
                                getSkillDataByIdHandler(item?.id);
                              }}
                              className={
                                skillData &&
                                skillData?.id === item?.id &&
                                "action"
                              }
                            />
                          </Col>
                        );
                      })
                    ) : (
                      <div className="AddSkill__notFound">No data found</div>
                    )}
                  </Row>
                </Col>
              </Row>

              <div
                className={`FilterBar__screenSize ${activeSize && "notActive"}`}
              >
                <SidePanel>
                  {(activeSize === "skills" || activeSize === "editSkill") && (
                    <div className="AddSkill__container AddSkill__container-main py-sm-5">
                      {/* <main className="AddSkill__main-content">
                        <form className="AddSkill__article-featured">
                          <div className="AddSkill__controls">
                            <div className="text-center mb-3 font-weight-bold h3 AddSkill__containerHeading">
                              {" "}
                              <div
                                onClick={closeModal}
                                className="AddSkill__closeImage"
                              >
                                <img
                                  src={leftArrow}
                                  className="AddSkill__headingImage"
                                  alt=""
                                ></img>
                              </div>
                              <Label
                                name={
                                  activeSize !== "skills"
                                    ? "Update Skill"
                                    : "Add Skill"
                                }
                                classused="Label_medium"
                              />
                            </div>
                            <div className="AddSkill__control">
                              <InputField
                                id="skillName"
                                name="skillName"
                                type="text"
                                label="Category"
                                value={skillData?.skillName}
                                onChange={(e) => {
                                  skillChangeHandler(
                                    "skillName",
                                    e.target.value
                                  );
                                  errorHandler(e.target.value, "skillName");
                                }}
                                onBlur={(e) => {
                                  errorHandler(e.target.value, "skillName");
                                }}
                                onKeyUp={(e) => {
                                  errorHandler(e.target.value, "skillName");
                                }}
                                required={
                                  <span
                                    className={`mt-1 fs-6 ${
                                      isErrors.skillName &&
                                      "CandidateDetails_required"
                                    }`}
                                  >
                                    *
                                  </span>
                                }
                              />
                              {isErrors?.skillName && (
                                <div className="CandidateDetails_required">
                                  {isErrors?.skillName}
                                </div>
                              )}

                              {/* display if string matches skills */}
                              {/* {skillMatches?.length > 0 && (
                                <Card className="my-3 ulStyling">
                                  <Card.Body className="p-0">
                                    <ul className="p-3">
                                      {skillMatches.map((item,index) => {
                                        return (
                                          <li className="my-0 border-bottom mb-3" key={index}>
                                            {item}
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  </Card.Body>
                                </Card>
                              )}

                              <div className="AddSkill__modalButtons d-flex justify-content-end">
                                <Button
                                  type="button"
                                  onClick={closeModal}
                                  variant="secondary"
                                  className="me-3 mt-4"
                                  value="Cancel"
                                />

                                <Button
                                  type="button"
                                  onClick={() => {
                                    submitHandler();
                                  }}
                                  size="lg"
                                  variant="secondary"
                                  className="mt-4 btn px-4 btn rounded btn-lg fs-6 text-light primary_color"
                                  value={
                                    activeSize !== "skills"
                                      ? "Update Skill"
                                      : "Add Skill"
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </form> */}
                      {/* </main> */}
                      <Container>
                    <Row className="align-items-center my-sm-5">
                        <Col
                            lg={12}
                            md={12}
                            xs={12}
                            className="align-self-center FilterBar__question"
                        >
                           <div style={{display:"flex"}}>
                           <span
                                onClick={closeModal}
                                className=""
                                role="button"
                              >
                                <img
                                  src={leftArrow}
                                  className="closeImage"
                                  alt=""
                                ></img>
                              </span>
                             <Label
                                name={
                                  activeSize !== "skills"
                                    ? "Update Skill"
                                    : "Add Skill"
                                }
                                classused="Label_medium"
                              />
                           </div>
                        </Col>
                    </Row>
                    <Row className="align-items-start justify-content-center ">
                        <Col
                            xs="12"
                            sm="12"
                            md="6"
                            lg="6"
                            className="align-self-center"
                        >
                              <InputField
                                id="skillName"
                                name="skillName"
                                type="text"
                                label="Category"
                                value={skillData?.skillName}
                                onChange={(e) => {
                                  skillChangeHandler(
                                    "skillName", e.target.value
                                  );
                                  errorHandler(e.target.value, "skillName");
                                }}
                                onBlur={(e) => {
                                  errorHandler(e.target.value, "skillName");
                                  skillChangeHandler("skillName", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))
                                }}
                                onKeyUp={(e) => {
                                  errorHandler(e.target.value, "skillName");
                                }}
                                required={
                                  <span
                                    className="mt-1 fs-6 CandidateDetails_required"
                                  >
                                    *
                                  </span>
                                }
                              />
                              {isErrors?.skillName && (
                                <div className="CandidateDetails_required">
                                  {isErrors?.skillName}
                                </div>
                              )}

                               {/* display if string matches skills */}
                               {skillMatches?.length > 0 && (
                                <Card className="my-3 ulStyling">
                                  <Card.Body className="p-0">
                                    <ul className="p-3">
                                      {skillMatches.map((item,index) => {
                                        return (
                                          <li className="my-0 border-bottom mb-3" key={index}>
                                            {item}
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  </Card.Body>
                                </Card>
                              )}
                              <div className="d-flex justify-content-sm-end justify-content-md-end submit_button">
                           <Button
                                  type="button"
                                  onClick={closeModal}
                                  variant="secondary"
                                  className="me-3 mt-4 primary_color"
                                  value="Cancel"
                                />
                            <Button
                                  type="button"
                                  onClick={() => {
                                    submitHandler();
                                  }}
                                  size="lg"
                                  variant="secondary"
                                  className="mt-4"
                                  value={
                                    activeSize !== "skills"
                                      ? "Update Skill"
                                      : "Add Skill"
                                  }
                                />
                           </div>
                        </Col>
                        {/* <Col
                            xs="12"
                            sm="12"
                            md="6"
                            lg="5"
                            className="submit_button align-self-start"
                        >
                           <div className="d-flex justify-content-sm-end justify-content-md-start submit_button">
                           <Button
                                  type="button"
                                  onClick={closeModal}
                                  variant="secondary"
                                  className="me-3 mt-4 px-5 btn btn-lg fs-6 "
                                  value="Cancel"
                                />
                            <Button
                                  type="button"
                                  onClick={() => {
                                    submitHandler();
                                  }}
                                  size="lg"
                                  variant="secondary"
                                  className="mt-4 btn px-5 btn rounded btn-lg fs-6 text-light primary_color"
                                  value={
                                    activeSize !== "skills"
                                      ? "Update Skill"
                                      : "Add Skill"
                                  }
                                />
                           </div>
                        </Col> */}
                    </Row>
                </Container>
                    </div>
                  )}
                </SidePanel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export { AddSkills };
