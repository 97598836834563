import React, { useState } from "react";

import { Form } from "react-bootstrap";

const SearchString = (props) => {
  const [searchedData, setSearchedData] = useState("");

  const onChangeHandler = (text) => {
    setSearchedData(text);
    props.onChange(text);
  };

  return (
    <>
      <Form.Control
        type="search"
        placeholder="Search"
        className="SearchInput"
        aria-label="Search"
        size="sm"
        value={searchedData}
        onChange={(e) => onChangeHandler(e.target.value)}
      />
    </>
  );
};

export default SearchString;
