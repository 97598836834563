import React, { useEffect, useState, useRef } from "react";

import { Container, Row, Col, Image, Form } from "react-bootstrap";
import { withFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import TimeRange from "react-time-range";
import { useLocation } from "react-router-dom";

import InterDashboardImage from "../../Assets/Images/Group 1580.svg";
import { Label } from "../UI/Label/Label";
import { useProvider } from "../hooks/useProvider/useProvider";
import { CustomSelect } from "../UI/CustomSelect";
import { useInterviewerProvider } from "../hooks/useInterviewerProvider/useInterviewerProvider";
import { cloneDeep, filter } from "lodash";
import { InputField } from "../UI/InputField";
import { Button } from "../UI/Button";
import "./InterviewDashboard.scss";
import { Login } from "../Auth/Login/Login";
import { Loader } from "../UI/Loader";
import InterviewDashboardSkeleton from "./InterviewDashboardSkeleton";

const formikEnhancer = withFormik({
    validationSchema: Yup.object().shape({
        candidateName: Yup.string()
            .min(3, "Name should be at least 3 characters long") /* .trim() */
            .label("Invalid email address")
            .required("Name is required!"),
        candidateEmail: Yup.string()
            .email()
            .trim()
            .required("Email is required!"),
        candidateContactNumber: Yup.string()
            .matches(/^[6-9]\d{9}$/, {
                message: "Please enter valid number.",
                excludeEmptyString: false,
            })
            .trim()
            .label("Invalid phone Number")
            .required("Mobile Number is required!"),

        skillLevel: Yup.array()
            .min(1, "Pick one level")
            .nullable()
            .of(
                Yup.object().shape({
                    label: Yup.string().required(),
                    value: Yup.string().required(),
                })
            ),

        skillSetId: Yup.array()
            .min(1, "Please Select Skills")
            .nullable()
            .when("interviewSkillIds", {
                is: true,
                then: Yup.array().required("Field is required"),
                otherwise: Yup.array().nullable().notRequired(),
            }),
        interviewSkillIds: Yup.array()
            .min(1, "Pick one mapped skill")
            .nullable()
            .of(
                Yup.object().shape({
                    label: Yup.string().required(),
                    value: Yup.string().required(),
                })
            ),
    }),
    mapPropsToValues: (props) => ({
        candidateName: "",
        skillLevel: [],
        candidateEmail: "",
        candidateContactNumber: "",
        skillSetId: [],
        interviewSkillIds: [],
        currentCtc: "",
        scheduledOn: "",
        certification: "",
        previousExperience: "",
        noticePeriod: "",
    }),
    mapPropsToErrors: () => ({
        candidateName: "required",
    }),

    displayName: "InterviewDashboard",
})(InterviewDashboard);

function InterviewDashboard(props) {
    const {
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
    } = props;
    const { skillSets, listOfSkillset, getSubSkillObject, displaySkills } =
        useProvider();
    const { createCandidateDetails, candidateDetails, isTokenActive, getCandidateHistory, candidateHistoryPayload, getCandidateInterviewDetails, UpdateCandidateHandler, setIsLoading, isLoading, setToastifyMessage, existingCandidateHistory } =
        useInterviewerProvider();
    const navigate = useNavigate();
    const current = new Date();
    const currentDate = current.toISOString().slice(0, 10);
    const currentTime = new Date(
        new Date().setHours(new Date().getHours() + 1)
    );
    const [userData, setUserData] = useState({
        candidateName: "",
        skillLevel: "",
        candidateEmail: "",
        candidateContactNumber: "",
        skillSetId: "",
        interviewSkillIds: [],
        scheduledOn: moment(currentDate).format('DD-MM-YYYY'),
        previousExperience: 0,
        scheduledStartTime: current,
        scheduledEndTime: currentTime,
        noticePeriod: 0,
    });
    const [timeData, setTimeData] = useState({});
    const [experienceInYears, setExperienceInYears] = useState({ value: 0, label: "Select" });
    const [experienceInMonths, setExperienceInMonths] = useState({ value: 0, label: "Select" });
    const [validateEmailId, setValidateEmailId] = useState(false);
    const ref = useRef();

    const levelList = [
        { value: "Beginner", label: "Beginner" },
        { value: "Intermediate", label: "Intermediate" },
        { value: "Experienced", label: "Experienced" },
    ];

    const previousYearExperienceList = [];
    for (let i = 0; i <= 30; i++) {
        previousYearExperienceList.push({ value: i, label: i + `${i > 1 ? " years" : " year"}` })
    }

    const previousMonthExperienceList = [];
    for (let i = 1; i <= 11; i++) {
        previousMonthExperienceList.push({ value: i, label: i + `${i > 1 ? " months" : " month"}` })
    }

    const inputHandler = (fieldName, data, event) => {
        setUserData((previousState) => {
            return { ...previousState, [fieldName]: data };
        });
    };

    //   get skillset based on selection of skill
    const filtersHandler = (fieldName, data) => {
        const finalData = listOfSkillset?.filter((skill) => {
            return skill?.id === data?.skillIds;
        });
        const getMappedSkillSet = getSubSkillObject(finalData?.[0]?.skills);

        inputHandler(fieldName, getMappedSkillSet);
        inputHandler("skillId", getMappedSkillSet?.[0]?.skillId);
    };

    //   conver custom skillset to comma separated values
    const skillSetNameHandler = (data) => {
        const finalData = [];

        for (let i = 0; i < data?.length; i++) {
            finalData.push(data?.[i]?.skillIds);
        }
        return finalData;
    };

    const addCandidateDetailsHandler = async (e) => {
        if (Object.keys(errors || {}).length <= 0) {
            let data = cloneDeep(userData);
            data.skillSetId = userData?.skillSetId?.skillIds;
            data.skillLevel = userData?.skillLevel?.value;
            data.interviewSkillIds = skillSetNameHandler(
                userData?.interviewSkillIds
            );
            data.scheduledEndTime = moment(data?.scheduledEndTime).format("hh:mm:ss")
            data.scheduledStartTime = moment(data?.scheduledStartTime).format("hh:mm:ss")
            userData?.id ? UpdateCandidateHandler(data) : createCandidateDetails(data);
        }
    };

    const actionHandler = () => {
        navigate("/");
    };

    // on add of candidate details get the question
    useEffect(() => {
        if (
            Object.keys(errors || {}).length === 0 &&
            Object.keys(candidateDetails || {}).length > 0
        ) {
            localStorage.setItem("activeTab", 0);
            localStorage.setItem("questionDetails", JSON.stringify([]));
            localStorage.setItem(
                "totalScores",
                JSON.stringify({ obtainedMarks: 0, maxScore: 0 })
            );
            navigate("/MeetingDashboard");
        }
    }, [candidateDetails]);

    const experienceConverter = (type, data) => {
        let totalExperience = "";
        if (type === "years") {
            totalExperience = data * 12 + experienceInMonths?.value;
        } else {
            totalExperience = experienceInYears?.value * 12 + data;
        }
        inputHandler(
            "previousExperience",
            totalExperience
        );
    }

    useEffect(() => {
        if (Object.keys(userData?.skillSetId || {})?.length > 0) {
            setFieldValue("skillSetId", null);
        } else if (Object.keys(userData?.skillLevel || {})?.length > 0) {
            setFieldValue("skillLevel", null);
        } else if (userData?.interviewSkillIds?.length > 0 || []) {
            setFieldValue("interviewSkillIds", null);
        }
    }, [
        userData?.skillSetId,
        userData?.interviewSkillIds,
        userData?.skillLevel,
    ]);

    // Get array of skills and value, label object
    const skillObjectConverter = (data) => {
        const finalObject = [];
        for (let i = 0; i < data?.length; i++) {
            finalObject.push({ value: data?.[i]?.skillName, label: data?.[i]?.skillName, skillIds: data?.[i]?.id })
        }
        return finalObject;
    }



    const verifyExistingCandidate = async (e) => {
        handleBlur(e);

        const pattern = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{1,})+$/);
        if (pattern.test(e.target.value) && ref.current !== e.target.value) {
            setValidateEmailId(true);
            ref.current = e.target.value;
            const params = { email: userData?.candidateEmail };
            const newData = await getCandidateInterviewDetails(params)
            if (newData?.status === "success") {
                const isLength = newData?.results?.length > 0;
                const indexOfInterviewDetails =
                    newData?.results?.length > 1
                        ? newData?.results?.length - 1
                        : 0;
                let resultData = newData?.results?.[indexOfInterviewDetails];
                const latestInterviewSkills = resultData?.interviewSkillIds?.interviewDetails?.length > 1 ? resultData?.interviewSkillIds?.interviewDetails?.length - 1 : 0;

                isLength && inputHandler("id", resultData?.id);
                inputHandler("candidateEmail", isLength ? resultData?.candidateEmail : userData?.candidateEmail);
                inputHandler("candidateName", isLength ? resultData?.candidateName : userData?.candidateName);
                inputHandler("candidateContactNumber", isLength ? resultData?.candidateContactNumber : userData?.candidateContactNumber);
                inputHandler("skillLevel", isLength ? { label: resultData?.skillLevel, value: resultData?.skillLevel } : userData?.skillLevel);
                inputHandler("skillSetId", isLength ? { label: resultData?.skillSet?.skillSetName, value: resultData?.skillSet?.skillSetName, skillIds: resultData?.id } : userData?.skillSetId);
                inputHandler("interviewSkillIds", isLength ? skillObjectConverter(resultData?.interviewDetails?.[latestInterviewSkills]?.interviewSkills) : userData?.interviewSkillIds);
                inputHandler("noticePeriod", isLength ? resultData?.noticePeriod : userData?.noticePeriod);

                const inYears = Math.floor(resultData?.previousExperience / 12);
                const inMonths = resultData?.previousExperience % 12
                setExperienceInMonths(isLength ? { value: inMonths, label: inMonths + `${inMonths > 1 ? " months" : " month"}` } : experienceInMonths);
                setExperienceInYears(isLength ? { value: inYears, label: inYears + `${inYears > 1 ? " years" : " year"}` } : experienceInYears);

                setFieldValue("candidateEmail", isLength ? resultData?.candidateEmail : userData?.candidateEmail);
                setFieldValue("candidateName", isLength ? resultData?.candidateName : userData?.candidateName);
                setFieldValue("candidateContactNumber", isLength ? resultData?.candidateContactNumber : userData?.candidateContactNumber);
                setFieldValue("skillLevel", isLength ? { label: resultData?.skillLevel, value: resultData?.skillLevel } : userData?.skillLevel);
                setFieldValue("skillSetId", isLength ? { label: resultData?.skillSet?.skillSetName, value: resultData?.skillSet?.skillSetName, skillIds: resultData?.id } : userData?.skillSetId);
                setFieldValue("noticePeriod", isLength ? resultData?.noticePeriod : userData?.noticePeriod)

                setValidateEmailId(false);
                setToastifyMessage({
                    type: isLength ? "success" : "error",
                    message: isLength ? "Successfully fetched existing candidate data" : "Couldn't find the existing details"
                })
            }
        }
    }

    const location = useLocation();
    if (!localStorage.getItem("token") && !isTokenActive) {
        return <Login />;
    }

    if (isLoading) {
        return <Loader />;
    }

    return (
        <>
            <div className="InterviewDashboard my-1">
                <Container className="align-self-center">
                    <div className=" d-sm-flex justify-content-between align-items-center">
                        <Label
                            name="Candidate Details"
                            classused="Label_bold"
                        />
                    </div>

                    <Row className="mt-3">
                        <Col
                            xs="12"
                            lg="8"
                            md="12"
                            sm="12"
                            className="InterviewDashboard__container"
                        >
                            {validateEmailId ? <InterviewDashboardSkeleton /> :
                                <Form>
                                    <Row>
                                        <Col
                                            xs="12"
                                            md="6"
                                            lg="6"
                                            className="my-2 px-4"
                                        >
                                            <InputField
                                                id="2"
                                                ref={ref}
                                                name="candidateEmail"
                                                type="email"
                                                placeholder="Candidate Email"
                                                className="InterviewDashboard__input"
                                                errorMessage="It should be a valid email address!"
                                                label="Email"
                                                onChange={(e) =>
                                                    inputHandler(
                                                        "candidateEmail",
                                                        e.target.value
                                                    )
                                                }
                                                required={
                                                    <span className="ms-1 my-2 mx-0 fs-6 CandidateDetails_required">
                                                        *
                                                    </span>
                                                }
                                                value={
                                                    userData?.candidateEmail
                                                }
                                                onBlur={verifyExistingCandidate}
                                                onKeyUp={handleChange}
                                                error={errors.candidateEmail}
                                                touched={touched.candidateEmail && String(touched.candidateName)}
                                            />
                                        </Col>

                                        <Col
                                            xs="12"
                                            md="6"
                                            lg="6"
                                            className="my-2 px-4"
                                        >
                                            <InputField
                                                id="candidateName"
                                                name="candidateName"
                                                type="text"
                                                placeholder="Username"
                                                required={
                                                    <span className="ms-1 my-2 mx-0 fs-6 CandidateDetails_required">
                                                        *
                                                    </span>
                                                }
                                                label="Name"
                                                onChange={(e) => {
                                                    inputHandler(
                                                        "candidateName", e.target.value
                                                    );
                                                }}
                                                value={
                                                    userData?.candidateName
                                                }
                                                onBlur={(e) => {
                                                    handleBlur(e);
                                                    inputHandler("candidateName", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))
                                                }
                                                }
                                                onKeyUp={handleChange}
                                                error={errors.candidateName}
                                                touched={touched.candidateName && String(touched.candidateName)}
                                                disabled={existingCandidateHistory?.candidateName}
                                            />
                                        </Col>

                                        <Col
                                            xs="12"
                                            md="6"
                                            lg="6"
                                            className="my-2 px-4"
                                        >
                                            <InputField
                                                id="3"
                                                name="candidateContactNumber"
                                                type="number"
                                                placeholder="Contact Number"
                                                label="Contact Number"
                                                onChange={(e) =>
                                                    inputHandler(
                                                        "candidateContactNumber",
                                                        e.target.value
                                                    )
                                                }
                                                value={
                                                    userData
                                                        ?.candidateContactNumber
                                                }
                                                onBlur={handleBlur}
                                                onKeyUp={handleChange}
                                                error={
                                                    errors.candidateContactNumber
                                                }
                                                touched={
                                                    touched.candidateContactNumber && String(touched.candidateName)
                                                }
                                                required={
                                                    <span className="ms-1 my-2 mx-0 fs-6 CandidateDetails_required">
                                                        *
                                                    </span>
                                                }
                                                disabled={existingCandidateHistory?.candidateContactNumber}
                                            />
                                        </Col>

                                        <Col
                                            xs="12"
                                            md="6"
                                            lg="6"
                                            className="my-2 px-4"
                                        >

                                            <CustomSelect
                                                label="Skill Level"
                                                onChange={(data) => {
                                                    inputHandler(
                                                        "skillLevel",
                                                        data
                                                    );
                                                    setFieldValue(
                                                        "skillLevel",
                                                        data
                                                    );
                                                }}
                                                options={levelList}
                                                value={userData?.skillLevel}
                                                onBlur={() => {
                                                    setFieldTouched(
                                                        "skillLevel",
                                                        true
                                                    );
                                                }}
                                                error={errors.skillLevel}
                                                touched={touched.skillLevel && String(touched.candidateName)}
                                                required={
                                                    <sup className="mt-3 mx-1 fs-6 CandidateDetails_required">
                                                        *
                                                    </sup>
                                                }
                                                isDisabled={existingCandidateHistory?.skillLevel}
                                            />
                                        </Col>

                                        <Col
                                            xs="12"
                                            md="6"
                                            lg="6"
                                            className="my-2 px-4"
                                        >

                                            <CustomSelect
                                                label=" Skillset Name"
                                                onChange={(data, e) => {
                                                    inputHandler(
                                                        "skillSetId",
                                                        data
                                                    );
                                                    setFieldValue(
                                                        "skillSetId",
                                                        data
                                                    );
                                                    filtersHandler(
                                                        "interviewSkillIds",
                                                        data
                                                    );
                                                    setFieldValue(
                                                        "interviewSkillIds",
                                                        data
                                                    );
                                                }}
                                                options={displaySkills}
                                                value={
                                                    userData?.skillSetId
                                                }
                                                onBlur={() => {
                                                    setFieldTouched(
                                                        "skillSetId",
                                                        true
                                                    );
                                                }}
                                                required={
                                                    <sup className="mt-3 mx-1 fs-6 CandidateDetails_required">
                                                        *
                                                    </sup>
                                                }
                                                isMulti={false}
                                                error={errors.skillSetId}
                                                touched={touched.skillSetId && String(touched.candidateName)}
                                                isDisabled={existingCandidateHistory?.skillSet}
                                            />
                                        </Col>
                                        <Col
                                            xs="12"
                                            md="6"
                                            lg="6"
                                            className="my-2 px-4"
                                        >

                                            <CustomSelect
                                                label="Custom Skillset Name"
                                                id="interviewSkillIds"
                                                onBlur={() => {
                                                    setFieldTouched(
                                                        "interviewSkillIds",
                                                        true
                                                    );
                                                }}
                                                options={skillSets}
                                                placeholder="Select skill"
                                                className="InterviewDashboard__input"
                                                value={userData?.interviewSkillIds}
                                                onChange={(data) => {
                                                    inputHandler(
                                                        "interviewSkillIds",
                                                        data
                                                    );
                                                    setFieldValue(
                                                        "interviewSkillIds",
                                                        data
                                                    );
                                                }}
                                                isMulti
                                                error={
                                                    Object.keys(
                                                        userData?.interviewSkillIds ||
                                                        {}
                                                    ).length === 0 &&
                                                    errors.interviewSkillIds
                                                }
                                                touched={touched.interviewSkillIds && String(touched.candidateName)}
                                                isDisabled={
                                                    Object.keys(
                                                        userData?.skillSetId || {}
                                                    ).length === 0
                                                }
                                                required={
                                                    <sup className="mt-3 mx-1 fs-6 CandidateDetails_required">
                                                        *
                                                    </sup>
                                                }
                                            />
                                        </Col>

                                        <Col
                                            xs="12"
                                            md="6"
                                            lg="6"
                                            className="my-2 px-4"
                                        >
                                            <InputField
                                                id="5"
                                                name="scheduleOn"
                                                type="date"
                                                placeholder="Schedule Date"
                                                label="Schedule Date"
                                                onChange={(e) =>
                                                    inputHandler(
                                                        "scheduledOn",
                                                        e.target.value
                                                    )
                                                }
                                                value={currentDate}
                                                disabled
                                            />
                                        </Col>

                                        <Col
                                            xs="12"
                                            md="6"
                                            lg="6"
                                            className="my-2 px-4"
                                        >
                                            <Label
                                                name="Scheduled Time"
                                                classused="Label_normal"
                                            />

                                            <TimeRange
                                                startMoment={timeData?.startTime}
                                                endMoment={
                                                    timeData?.endTime || currentTime
                                                }
                                                minuteIncrement={30}
                                                onChange={(e) => {
                                                    e.endTime === undefined
                                                        ? inputHandler(
                                                            "scheduledStartTime",
                                                            e.startTime
                                                        )
                                                        : inputHandler(
                                                            "scheduledEndTime",
                                                            e.endTime
                                                        );
                                                    setTimeData(e);
                                                }}
                                                sameIsValid={false}
                                                startLabel=" "
                                                endLabel="To"
                                                use24Hours={false}
                                            />
                                        </Col>

                                        <Col
                                            xs="12"
                                            md="6"
                                            lg="6"
                                            className="my-2 px-4"
                                        >
                                            <Row>
                                                <Label
                                                    name="Work Experience"
                                                    classused="Label_light fw-bold align-self-center"
                                                />
                                                <Col xs="6">
                                                    <CustomSelect
                                                        label=""
                                                        onChange={(data) => {
                                                            setExperienceInYears(data);
                                                            experienceConverter("years", data.value);
                                                        }}
                                                        options={previousYearExperienceList}
                                                        value={experienceInYears}
                                                        isDisabled={existingCandidateHistory?.previousExperience >= 0}
                                                    />

                                                </Col>
                                                <Col xs="6">
                                                    <CustomSelect
                                                        label=" "
                                                        onChange={(data) => {
                                                            setExperienceInMonths(data);
                                                            experienceConverter("months", data.value);
                                                        }}
                                                        options={previousMonthExperienceList}
                                                        value={experienceInMonths}
                                                        isDisabled={existingCandidateHistory?.previousExperience >= 0}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col
                                            xs="12"
                                            md="6"
                                            lg="6"
                                            className="my-2 px-4"
                                        >
                                            <InputField
                                                id="10"
                                                name="noticePeriod"
                                                type="number"
                                                placeholder="Notice Period"
                                                className="InterviewDashboard__input"
                                                label="Notice Period"
                                                onChange={(e) =>
                                                    inputHandler(
                                                        "noticePeriod",
                                                        parseInt(e.target.value)
                                                    )
                                                }
                                                value={userData?.noticePeriod}
                                                onKeyUp={handleChange}
                                                postState="Days"
                                                postClass="p-2"
                                                disabled={existingCandidateHistory?.noticePeriod}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="justify-content-end mt-4 ">
                                        <Col xs="12" md="6" lg="6">
                                            <Row className="row_gutter px-3">
                                                <Col className="m-0 d-grid">
                                                    {" "}
                                                    <Button
                                                        onClick={actionHandler}
                                                        size="lg"
                                                        className="btn-block mb-2 primary_color"
                                                        value="Back"
                                                        variant="secondary"
                                                        type="button"
                                                    />
                                                </Col>
                                                <Col className="d-grid">
                                                    <Button
                                                        onClick={() => {
                                                            handleSubmit();
                                                            addCandidateDetailsHandler();
                                                        }}
                                                        size="lg"
                                                        className="btn-block mb-2"
                                                        value="Start"
                                                        variant="secondary"
                                                        type="button"
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Form>
                            }
                        </Col>
                        <Col lg="4" className="align-self-end hide_xs">
                            <Image
                                src={InterDashboardImage}
                                className="fluid hide_xs"
                                fluid="true"
                                width={1000}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export { formikEnhancer as InterviewDashboard };