import React from "react";

import "./Label.scss";

function Label({ name, classused, id, required }) {
  return (
    <>
      <div className="Label_section">
        <h3 className={classused} htmlFor={id}>
          {name}
          <span>{required}</span>
        </h3>
      </div>
    </>
  );
}

export { Label };
