import React from "react";

import { Label } from "../../UI/Label";
import { Stack, Form } from "react-bootstrap";
import leftArrow from "../../../Assets/Images/Manage/leftArrow.svg";
import { AnswerStatus } from "../AnswerStatus/AnswerStatus";

import "./InterviewAnswer.scss";

const InterviewAnswer = ({
    closeModal,
    activeSizeData,
    setMarks,
    setAnswerStatus,
    questionAnswerHandler,
    answerStatus,
    marks,
    setActiveSizeData,
}) => {
    return (
        <div className="InterviewAnswer py-5">
            <div className="mb-2 d-flex">
                {" "}
                <button
                    onClick={()=> {
                        closeModal();
                        setActiveSizeData({});
                    }}
                    className="FilterBar__closeImage mx-2"
                >
                    <img
                        src={leftArrow}
                        className="FilterBar__headingImage"
                        alt=""
                    ></img>
                </button>
                <Label name=" Answer" classused="Label_medium" />
            </div>
            {/* <h4>  {activeSizeData?.question}</h4> */}
            <Label name={activeSizeData?.question} classused="Label_normal" />
            <div
                className="FilterBar__answerCardContainer p-3 border border-secondary border-2 rounded"
                border="dark"
                
            >
                
                <div className="FilterBar__answerCard">
                    {activeSizeData?.answer}
                </div>
            </div>
                <AnswerStatus
                    setMarks={setMarks}
                    closeModal={closeModal}
                    activeSizeData={activeSizeData}
                    setAnswerStatus={setAnswerStatus}
                    setActiveSizeData={setActiveSizeData}
                    questionAnswerHandler={questionAnswerHandler}
                    answerStatus={answerStatus}
                    marks={marks}
                />
        </div>
    );
};

export { InterviewAnswer };
