import React, { useEffect, useReducer } from "react";
import axios from "axios";
import { useNavigate } from "react-router";
import { useProvider } from "../useProvider/useProvider";
import { cloneDeep } from "lodash";

const ACTIONS = {
    set_toastify_message: "set_toastify_message",
    set_candidateDetails: "set_candidateDetails",
    set_interviewer_filters: "set_interviewer_filters",
    set_interviewer_list_of_questions: "set_interviewer_list_of_questions",
    set_overlay_state: "set_overlay_state",
    set_is_loading: "set_is_loading",
    set_candidate_history: "set_candidate_history",
    set_candidate_history_payload: "set_candidate_history_payload",
    set_existing_candidate_history: "set_existing_candidate_history",
};

const reducer = (state, action) => {
    switch (action.type) {
        case ACTIONS.set_toastify_message:

            return {
                ...state,
                toastifyMessage: action.data,
            };
        case ACTIONS.set_candidateDetails:
            return {
                ...state,
                candidateDetails: action.data,
            };
        case ACTIONS.set_interviewer_filters:
            return {
                ...state,
                interviewerFilters: action.data,
            };
        case ACTIONS.set_interviewer_list_of_questions:
            return {
                ...state,
                interviewerListOfQuestions: action.data,
            };
        case ACTIONS.set_overlay_state:
            return {
                ...state,
                overlay: action.data,
            };
        case ACTIONS.set_is_loading:
            return {
                ...state,
                isLoading: action.data,
            };
        case ACTIONS.set_candidate_history:
            return {
                ...state,
                candidateHistory: action.data,
            };
        case ACTIONS.set_candidate_history_payload:
            return {
                ...state,
                candidateHistoryPayload: action.data,
            };
        case ACTIONS.set_existing_candidate_history:
            return {
                ...state,
                existingCandidateHistory: action.data,
            };
        default:
    }
};

function useInterviewer() {
    const INITIAL_STATE = {
        toastifyMessage: {},
        candidateDetails: {},
        interviewerFilters: {
            skillLevel: "",
            skillId: "",
            search: "",
            isPractical: false,
            pageNo: 0,
            pageSize: 10,
        },
        interviewerListOfQuestions: [],
        overlay: false,
        isLoading: false,
        candidateHistory: [],
        candidateHistoryPayload: {
            pageNo: 0,
            pageSize: 10,
            email: "",
        },
        existingCandidateHistory: [],
    };
    const [state, setState] = useReducer(reducer, INITIAL_STATE);
    const navigate = useNavigate();
    const baseUrl = process.env.React_App_Base_Url + "/api/";

    const setToastifyMessage = (data) => {
        setState({ type: ACTIONS.set_toastify_message, data });
    };

    const setCandidateDetails = (data) => {
        setState({ type: ACTIONS.set_candidateDetails, data });
    };

    const setInterviewerFilters = (data) => {
        setState({ type: ACTIONS.set_interviewer_filters, data });
    };

    const setInterviewerListOfQuestions = (data) => {
        setState({ type: ACTIONS.set_interviewer_list_of_questions, data });
    };

    const setOverlay = (data) => {
        setState({ type: ACTIONS.set_overlay_state, data });
    };
    const setIsLoading = (data) => {
        setState({ type: ACTIONS.set_is_loading, data });
    };

    const setCandidateHistory = (data) => {
        setState({ type: ACTIONS.set_candidate_history, data });
    };

    const setCandidateHistoryPayload = (data) => {
        setState({ type: ACTIONS.set_candidate_history_payload, data });
    };

    const setExistingCandidateHistory = (data) => {
        setState({ type: ACTIONS.set_existing_candidate_history, data });
    };

    const createCandidateDetails = async (data) => {
        await axios({
            method: "POST",
            url: baseUrl + "interview/services/candidate/create",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            data: data,
        })
            .then((res) => {
                const { data } = res;
                if (data?.status === "success") {
                    localStorage.setItem(
                        "candidateDetails",
                        JSON.stringify(data?.data)
                    );
                    setCandidateDetails(data?.data);
                } else {
                    setIsLoading(false);
                    setToastifyMessage({ type: "error", message: data?.responseMsg });
                }
            })
            .catch((err) => {
                setIsLoading(false);
                if (err.response.status === 401) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("userDetails");
                    localStorage.removeItem("candidateDetails");
                    localStorage.removeItem("totalScores");
                    localStorage.removeItem("questionDetails");
                    localStorage.removeItem("activeTab");
                    navigate("/");
                    setToastifyMessage({ type: "error", message: err?.response?.data?.message });
                } else {
                    setToastifyMessage({ type: "error", message: err?.response?.statusText });
                }
            });
    };

    const getInterviewQuestions = async (data) => {
        setIsLoading(true);
        const interviewQuestions = cloneDeep(state.interviewerListOfQuestions);
        const isFilters = cloneDeep(state.interviewerFilters);

        const params = data ? data : isFilters;
        delete params?.totalPages;

        await axios({
            method: "GET",
            url: baseUrl + "interview/services/questions/getQuestions",
            params: params,
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        })
            .then((res) => {
                const { data } = res;
                let chatData = [];
                if (data?.status === "success") {
                    // Create toast

                    if (data?.currentPage < 1) {
                        chatData = data?.data;
                    } else {
                        chatData = [...interviewQuestions, ...data?.data];
                    }
                    setInterviewerListOfQuestions(chatData);

                    params.pageNo = data?.currentPage;
                    params.totalPages = data?.totalPages;                    
                    params.skillLevel = { label: params.skillLevel, value: params.skillLevel }
                    setInterviewerFilters(params);                    
                } else {
                    params.skillLevel = { label: params.skillLevel, value: params.skillLevel }
                    setInterviewerFilters(params);
                    setInterviewerListOfQuestions([]);
                }            
                setIsLoading(false);    
            })
            .catch((err) => {
                setIsLoading(false);
                if (err.response.status === 401) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("userDetails");
                    localStorage.removeItem("candidateDetails");
                    localStorage.removeItem("totalScores");
                    localStorage.removeItem("questionDetails");
                    localStorage.removeItem("activeTab");
                    navigate("/");
                    setToastifyMessage({ type: "error", message: err?.response?.data?.message });
                } else {
                    setToastifyMessage({ type: "error", message: err?.response?.statusText });
                }
            });
    };

    const UpdateCandidateHandler = async (data) => {
        try {
            setIsLoading(true);
            const result = await axios({
                method: "PUT",
                url: baseUrl + "interview/services/candidate/update",
                data,
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            });
            if (result?.data?.status !== "success") {
                setToastifyMessage({
                    type: "error",
                    message: result?.data?.responseMsg,
                });
            } else {
                localStorage.setItem(
                    "candidateDetails",
                    JSON.stringify(result?.data?.data)
                );
                setCandidateDetails(result?.data?.data);
            }
            setIsLoading(false);
            return result.data;
        } catch (err) {
            setIsLoading(false);
            if (err?.response?.status === 401) {
                localStorage.removeItem("token");
                localStorage.removeItem("userDetails");
                localStorage.removeItem("candidateDetails");
                localStorage.removeItem("totalScores");
                localStorage.removeItem("questionDetails");
                localStorage.removeItem("activeTab");
                navigate("/");
                setToastifyMessage({ type: "error", message: err?.response?.data?.message });
            } else {
                setToastifyMessage({ type: "error", message: err?.response?.statusText });
            }
        }
    };

    const getCandidateHistory = async (data) => {
        setIsLoading(true);
        const params = data ? data : state.candidateHistoryPayload;
        delete params?.totalPages;

        await axios({
            method: "GET",
            url: baseUrl + "interview/services/candidate/all",
            params: params,
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        })
            .then((res) => {
                const { data } = res;
                let candidateData = [];
                if (data?.status === "success") {
                    if (data?.currentPage < 1) {
                        candidateData = data?.results;
                    } else {
                        candidateData = [
                            ...state.candidateHistory,
                            ...data?.results,
                        ];
                    }
                    setCandidateHistory(candidateData);
                    params.totalPages = data?.totalPages;
                    setCandidateHistoryPayload(params);
                } 
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
                if (err.response.status === 401) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("userDetails");
                    localStorage.removeItem("candidateDetails");
                    localStorage.removeItem("totalScores");
                    localStorage.removeItem("questionDetails");
                    localStorage.removeItem("activeTab");
                    navigate("/");
                    setToastifyMessage({ type: "error", message: err?.response?.data?.message });
                } else {
                    setToastifyMessage({ type: "error", message: err?.response?.statusText });
                }
            });
    };

    const getCandidateInterviewDetails = async (data) => {
        try {
            const result = await axios({
                method: "GET",
                url: baseUrl + "interview/services/candidate/getCandidateByEmail",
                params: data,
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            });

            if (result?.data?.status !== "success") {
                setToastifyMessage({
                    type: "error",
                    message: result?.data?.responseMsg,
                });
            } else {
                setExistingCandidateHistory(result?.data?.results?.[0]);
            }
            return result.data;
        } catch (err) {
            setIsLoading(false);
            if (err?.response?.status === 401) {
                localStorage.removeItem("token");
                localStorage.removeItem("userDetails");
                localStorage.removeItem("candidateDetails");
                localStorage.removeItem("totalScores");
                localStorage.removeItem("questionDetails");
                localStorage.removeItem("activeTab");
                navigate("/");
                setToastifyMessage({ type: "error", message: err?.response?.data?.message });
            } else {
                setToastifyMessage({ type: "error", message: err?.response?.statusText });
            }
        }
    };

    return {
        createCandidateDetails,
        setCandidateDetails,
        candidateDetails: state.candidateDetails,
        getInterviewQuestions,
        interviewerListOfQuestions: state.interviewerListOfQuestions,
        setInterviewerFilters,
        interviewerFilters: state.interviewerFilters,
        setOverlay,
        overlay: state.overlay,
        UpdateCandidateHandler,
        setIsLoading,
        isLoading: state.isLoading,
        getCandidateHistory,
        candidateHistory: state.candidateHistory,
        setCandidateHistoryPayload,
        candidateHistoryPayload: state.candidateHistoryPayload,
        setToastifyMessage: setToastifyMessage,
        toastifyMessage: state.toastifyMessage,
        getCandidateInterviewDetails,
        setExistingCandidateHistory,
        existingCandidateHistory: state.existingCandidateHistory,
    };
}

export { useInterviewer };
