import React from 'react'

import './LabelField.scss'

function LabelField({ id, label,style, className, ...rest }) {
    return (
      <div className={`labelField ${className}`}>
        <label className="labelField__label" htmlFor={id}  style={{
        ...style
      }}>
          {label}
        </label>
      </div>
    );
  }

export  {LabelField }