import React, { useState } from "react";

import { Button } from "react-bootstrap";
import "./Button.scss";

const Buttons = ({ value, onClick, className, disabled, size, variant,type }) => {
    const [activeSize, setActiveSize] = useState(false);

    // const buttonHandler = (event) => {
    //     onClick(event);
    //     setActiveSize(true);
    // };
    return (
        <>
            <Button
                className={`btn btn-secondary btn-lg px-4 rounded fs-6 ${className}`}
                onClick={onClick}
                disabled={disabled}
                size={size}
                variant={variant}
                type={type}
            >
                {value}
            </Button>
        </>
    );
};

export { Buttons as Button };
