import React, { useState } from "react";

import Answer from "../../../Assets/Images/Manage/answer.svg";
import checkmark from "../../../Assets/Images/checkmark.svg";
import { Container, Row, Col } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import "./InterviewAccordionContent.scss";
import { Pills } from "../../UI/Pills";

function InterviewAccordionContent({
    displayData,
    activeSize,
    practical,
    title,
    content,
    questionLevel,
    answer,
    classUsed,
    setActiveSizeData,
    port,
}) {
    return (
        <div className="InterviewAccordionContent">
            <Container className="">
                <Row className="justify-content-between align-items-center">
                    <Col
                        xs="8"
                        sm="8"
                        lg="8"
                        className="align-self-center my-1 py-0"
                    >
                        <div className="accordion__title d-flex align-items-center">
                            {title}
                            {/* <div className=" d-flex align-items-center"> */}
                            {practical && (
                                <Pills
                                    practical={practical}
                                    style={{
                                        border: practical && "1px solid red",
                                    }}
                                    // className="accordion__pillStyling"
                                    // bg="danger"
                                    className="accordion__pillStyling pill_color mx-2"
                                    bg="#F6A541"
                                />
                            )}
                            <Pills
                                practical={questionLevel}
                                className="accordion__pillStyling secondary_color text-dark"
                                bg="#F6A541"
                            />
                        </div>
                    </Col>

                    <Col
                        xs="4"
                        sm="4"
                        lg="4"
                        className="text-end d-flex justify-content-end"
                    >
                        <div
                            className="Link"
                            onClick={() => {
                                displayData("answer");
                            }}
                        >
                            {" "}
                            <img
                                className={`${classUsed} mx-2 my-1`}
                                src={checkmark}
                                alt=""
                            ></img>{" "}
                        </div>
                        {content && (
                            <div
                                className="Link icon_Style"
                                onClick={() => {
                                    displayData("answer");
                                }}
                            >
                                <img
                                    className="icon_Style hide_content_md"
                                    src={Answer}
                                    alt=""
                                ></img>
                                <span className="hide_content_sm">Answer</span>
                            </div>
                        )}
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
export { InterviewAccordionContent };
