import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router";
import { Container, Row, Col, Image, Form } from "react-bootstrap";
import { cloneDeep } from "lodash";

import CandidateProgressDashboardImage from "../../../Assets/Images/Group 1586.png";
import { Label } from "../../UI/Label/Label";
import { useInterviewerProvider } from "../../hooks/useInterviewerProvider/useInterviewerProvider";
import { InputField } from "../../UI/InputField";
import { TextAreaField } from "../../UI/TextAreaField";
import { withFormik } from "formik";
import { Button } from "../../UI/Button";
import * as Yup from "yup";
import "./CandidateProgressDashboard.scss";
import { CustomSelect } from "../../UI/CustomSelect";
import { useProvider } from "../../hooks/useProvider/useProvider";
import { Loader } from "../../UI/Loader";
import { InterviewModal } from "../../UI/InterviewModal";

const formikEnhancer = withFormik({
    validationSchema: Yup.object().shape({
        feedBack: Yup.string()
            .label("Invalid Feedback")
            .min(3, "Minimum 3 Characterrs are required")
            .required("Feedback is required!"),
        listOfInterviewers: Yup.array()
            .min(1, "Pick one Mail Id")
            .nullable()
            .of(
                Yup.object().shape({
                    label: Yup.string().required(),
                    value: Yup.string().required(),
                })
            ),
    }),
    mapPropsToValues: (props) => ({
        feedBack: "",
    }),
    mapPropsToErrors: () => ({
        feedBack: "required",
        listOfInterviewers: "required",
    }),
    displayName: "InterviewDashboard",
})(CandidateProgressDashboard);

function CandidateProgressDashboard(props) {
    const {
        touched,
        errors,
        handleChange,
        handleBlur,
        setFieldValue,
        setFieldTouched,
        handleSubmit,
        values,
    } = props;
    const { setOverlay, overlay, UpdateCandidateHandler, setCandidateDetails, existingCandidateHistory } =
        useInterviewerProvider();
    const {
        getNavadhitiMembers,
        getInterviewers,
        getIndividualInterviewersEmail,
        navadhitiMembersPayload,
        navadhitiMembers,
        isLoading,
    } = useProvider();
    const navigate = useNavigate();

    const userDetails = JSON.parse(localStorage.getItem("candidateDetails"));
    const grandTotal = JSON.parse(localStorage.getItem("totalScores"));
    const questionDetails = JSON.parse(localStorage.getItem("questionDetails"));
    const indexOfInterviewDetails =
        userDetails?.interviewDetails?.length > 1
            ? userDetails?.interviewDetails?.length - 1
            : 0;

    const [candidateProgressData, setCandidateProgressData] = useState({
        currentCtc: "",
        expectedCtc: "",
        feedback: "",
        interviewDuration: "",
        isShortlisted: true,
    });
    const [listOfInterviewers, setListOfInterviewers] = useState([]); // List of interviewers
    const [selectedInterviewers, setSelectedInterviewers] = useState([]); // List of selected interviewers
    const [selectedCCInterviewers, setSelectedCCInterviewers] = useState([]); // List of selected interviewers
    const [task1Show, setTask1Show] = useState(false);

    const inputHandler = (fieldName, data) => {
        setCandidateProgressData((previousState) => {
            return { ...previousState, [fieldName]: data };
        });
    };

    useEffect(() => {
        Object.keys(candidateProgressData?.feedback).length <= 0 &&
            setFieldValue(true);
    }, [candidateProgressData?.feedback]);

    // Convert object to array
    const getArray = (data) => {
        const newArray = [];
        for (let i = 0; i < data?.length; i++) {
            newArray.push(data[i].value);
        }
        return newArray;
    };

    const UpdateCandidateDetails = async () => {
        if (
            Object.keys(errors || {}).length <= 0 &&
            candidateProgressData?.feedback !== ""
        ) {
            const data = {
                id: userDetails?.id,
                interviewDetailsId: userDetails?.interviewDetails?.[indexOfInterviewDetails]?.id,
                totalScore: grandTotal?.obtainedMarks,
                maxScore: grandTotal?.maxScore,
                interviewerComment: candidateProgressData.feedback.trim(),
                currentCtc: candidateProgressData.currentCtc,
                expectedCtc: candidateProgressData.expectedCtc,
                interviewDuration: candidateProgressData?.interviewDuration,
                isShortlistedInterviewRound: candidateProgressData?.isShortlisted,
                score: questionDetails,
            };
            const result = await UpdateCandidateHandler(data);

            if (result?.status === "success") {
                setTask1Show(true);
                localStorage.removeItem("totalScores");
                localStorage.removeItem("questionDetails");
                localStorage.removeItem("candidateDetails");
                localStorage.removeItem("activeTab");

                const finalData = getArray(selectedInterviewers);
                const finalData1 = getArray(selectedCCInterviewers);
                const mailData = {
                    toEmail: [...finalData],
                    ccEmail: [...finalData1],
                };
                await getIndividualInterviewersEmail(mailData, userDetails?.interviewDetails?.[indexOfInterviewDetails]?.id);
                setCandidateDetails({});
                setOverlay(true);
            }
        }
    };

    // Remove all from the data
    useEffect(() => {
        if (navadhitiMembers.length > 0) {
            const newData = navadhitiMembers.filter(
                (interviewer) => interviewer.value !== "ALL"
            );
            setListOfInterviewers(newData);
        }
    }, [navadhitiMembers]);

    // Call api to get all interviewers
    useEffect(() => {
        async function fetchData() {
            const newData = await getInterviewers();

            setSelectedInterviewers(
                newData.interviewersToObject
            );
            setSelectedCCInterviewers(newData.interviewersCCObject);
        }

        inputHandler("currentCtc", userDetails?.currentCtc);
        inputHandler("expectedCtc", userDetails?.expectedCtc);

        fetchData();

        const newData = cloneDeep(navadhitiMembersPayload);
        newData.pageNo = 0;
        newData.getAll = true;
        newData.role = "";
        getNavadhitiMembers(newData);
    }, []);

    const actionHandler = () => {
        navigate("/");
    };

    if (isLoading) {
        return <Loader />;
    }

    return (
        <>
            <div className="CandidateProgressDashboard my-5">
                <Container className="align-self-center">
                    <div className=" d-sm-flex justify-content-between align-items-center word-break">
                        <Label
                            name={`Candidate Progress of ${userDetails?.name}`}
                            classused="Label_bold"
                        />
                    </div>

                    <Row className="mt-3 justify-content-between">
                        <Col xs="12" lg="8">
                            <Form>
                                <Row>
                                    <Col
                                        xs="12"
                                        sm="12"
                                        lg="12"
                                        className="my-2"
                                    >
                                        <CustomSelect
                                            id="listOfInterviewers"
                                            options={listOfInterviewers}
                                            value={selectedInterviewers}
                                            label=" To "
                                            isMulti
                                            isDisabled={true}
                                        />
                                    </Col>
                                    <Col
                                        xs="12"
                                        sm="12"
                                        lg="12"
                                        className="my-2"
                                    >
                                        <CustomSelect
                                            id="listOfCCInterviewers"
                                            options={listOfInterviewers}
                                            value={selectedCCInterviewers}
                                            onChange={(data) => {
                                                setSelectedCCInterviewers(data);
                                                setFieldValue(
                                                    "listOfInterviewers",
                                                    data
                                                );
                                            }}
                                            label="Cc"
                                            onBlur={() => {
                                                setFieldTouched(
                                                    "listOfInterviewers",
                                                    true
                                                );
                                            }}
                                            required={
                                                <sub className="feedback_required fs-6">
                                                    *
                                                </sub>
                                            }
                                            error={errors.listOfInterviewers}
                                            touched={touched.listOfInterviewers && String(touched.listOfInterviewers)}
                                            isMulti
                                        />
                                    </Col>
                                    <Col xs="12" sm="6" lg="6" className="my-2">
                                        <InputField
                                            id="currentCtc"
                                            name="currentCtc"
                                            placeholder="Eg: 3,00,000"
                                            label="Current CTC"
                                            value={
                                                candidateProgressData?.currentCtc
                                            }
                                            type="number"
                                            onChange={(e) => {
                                                inputHandler(
                                                    "currentCtc",
                                                    e.target.value === " "
                                                        ? ""
                                                        : parseInt(
                                                            e.target.value
                                                        )
                                                );
                                            }}
                                            prevState="INR"
                                            prevClass="p-2"
                                            disabled={userDetails?.currentCtc >= 0}
                                        />
                                    </Col>
                                    <Col xs="12" sm="6" lg="6" className="my-2">
                                        <InputField
                                            id="expectedCtc"
                                            name="expectedCtc"
                                            type="number"
                                            placeholder="Eg: 6,00,000"
                                            label="Expected CTC"
                                            value={
                                                candidateProgressData?.expectedCtc
                                            }
                                            onChange={(e) => {
                                                inputHandler(
                                                    "expectedCtc",
                                                    e.target.value === " "
                                                        ? ""
                                                        : parseInt(
                                                            e.target.value
                                                        )
                                                );
                                            }}
                                            prevState="INR"
                                            prevClass="p-2"
                                            disabled={userDetails?.expectedCtc >= 0}
                                        />
                                    </Col>

                                    <Col xs="12" sm="6" lg="6" className="my-3">
                                        <InputField
                                            id="gainedMarks"
                                            name="gainedMarks"
                                            placeholder="Total Score"
                                            label="Total Score"
                                            value={grandTotal?.obtainedMarks}
                                            disabled
                                        />
                                    </Col>
                                    <Col xs="12" sm="6" lg="6" className="my-3">
                                        <InputField
                                            id="totalMarks"
                                            name="totalMarks"
                                            placeholder=" 200"
                                            label=" Max Score"
                                            value={grandTotal?.maxScore}
                                            disabled
                                        />
                                    </Col>
                                    <Col xs="12" sm="6" lg="6" className="my-3">
                                        <InputField
                                            id="7"
                                            name="interviewDuration"
                                            type="number"
                                            placeholder="Time you took for this interview"
                                            label="Interview Duration"
                                            onChange={(e) =>
                                                inputHandler(
                                                    "interviewDuration",
                                                    parseInt(e.target.value)
                                                )
                                            }
                                            value={
                                                values.userData
                                                    ?.interviewDuration
                                            }
                                            postState="Mins"
                                            postClass="p-2"
                                        />
                                    </Col>
                                    <Col xs="12" sm="6" lg="6" className="my-3">
                                        <Label
                                            name="Recommended"
                                            classused="Label_light fw-bold align-self-center"
                                        />
                                        <div className="mt-3 d-sm-flex justify-content-start align-items-center">
                                            <div className="form-check p-0 m-0 me-4 d-flex align-items-center form-check-inline">
                                                <input
                                                    className="RadioButton"
                                                    type="radio"
                                                    name="flexRadioDefault"
                                                    id="flexRadioDefault1"
                                                    onClick={() => {
                                                        inputHandler(
                                                            "isShortlisted",
                                                            true
                                                        );
                                                    }}
                                                    defaultChecked={
                                                        candidateProgressData?.isShortlisted
                                                    }
                                                />
                                                <label
                                                    className="form-check-label ms-1"
                                                    htmlFor="flexRadioDefault1"
                                                >
                                                    Selected
                                                </label>
                                            </div>
                                            <div className="form-check p-0 m-0  mt-2 mt-sm-0 d-flex align-items-center form-check-inline">
                                                <input
                                                    className="RadioButton"
                                                    type="radio"
                                                    name="flexRadioDefault"
                                                    id="flexRadioDefault2"
                                                    onClick={() => {
                                                        inputHandler(
                                                            "isShortlisted",
                                                            false
                                                        );
                                                    }}
                                                    defaultChecked={
                                                        !candidateProgressData?.isShortlisted
                                                    }
                                                />
                                                <label
                                                    className="form-check-label ms-1"
                                                    htmlFor="flexRadioDefault2"
                                                >
                                                    Rejected
                                                </label>
                                            </div>
                                        </div>
                                    </Col>

                                    <Col xs="12" lg="12" className="my-3">
                                        <TextAreaField
                                            id="feedBack"
                                            name="feedBack"
                                            type="text"
                                            placeholder="Your opinion on candidate"
                                            value={
                                                values.candidateProgressData
                                                    ?.feedback
                                            }
                                            onChange={(e) => {
                                                inputHandler(
                                                    "feedback",
                                                    e.target.value === " "
                                                        ? ""
                                                        : e.target.value
                                                );
                                            }}
                                            rows="5"
                                            label="Feedback"
                                            onBlur={() => {
                                                setFieldTouched(
                                                    "feedBack",
                                                    true
                                                );
                                            }}
                                            onKeyUp={handleChange}
                                            error={errors.feedBack}
                                            touched={touched.feedBack && String(touched.feedBack)}
                                            required={
                                                <sup className="feedback_required feedback_requiredtext mx-1 fs-6">
                                                    *
                                                </sup>
                                            }
                                        />
                                    </Col>
                                </Row>
                                <Row className="justify-content-end">
                                    <Col xs="12" lg="6">
                                        <Row className="row_gutter">
                                            <Col className="m-0 d-grid">
                                                <Button
                                                    onClick={() => {
                                                        localStorage.setItem(
                                                            "activeTab",
                                                            0
                                                        );
                                                        navigate(
                                                            "/MeetingDashboard"
                                                        );
                                                    }}
                                                    size="lg"
                                                    className="btn-block mb-2"
                                                    value="Back"
                                                    variant="secondary"
                                                    type="button"
                                                />
                                            </Col>
                                            <InterviewModal
                                                show={task1Show}
                                                handleClose={() =>
                                                    setTask1Show(false)
                                                }
                                                handleShow={() =>
                                                    setTask1Show(true)
                                                }
                                                modalTitle={`Interview is Completed`}
                                            >
                                                <Button
                                                    onClick={() => {
                                                        actionHandler();
                                                        setTask1Show(false);
                                                    }}
                                                    className=""
                                                    value="Okay"
                                                    type="button"
                                                />
                                            </InterviewModal>
                                            <Col className="d-grid">
                                                <Button
                                                    onClick={() => {
                                                        Object.keys(
                                                            errors || {}
                                                        ).length > 0 ? handleSubmit() :
                                                            UpdateCandidateDetails();
                                                    }}
                                                    size="lg"
                                                    className="btn-block mb-2"
                                                    value="Submit"
                                                    variant="secondary"
                                                    type="button"
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                        <Col xs="12" lg="3" className="align-self-end">
                            <Image
                                src={CandidateProgressDashboardImage}
                                className="fluid hide_xs mx-auto"
                                fluid="true"
                                width={300}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export { formikEnhancer as CandidateProgressDashboard };
