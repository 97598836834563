import React, { useEffect, useRef, useState } from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import './AccordionWrapper.scss';

function AccordionWrapper({ children, className }) {
  const [width, setWidth] = useState(window.innerWidth);
  const screen = useRef(null);

  const updateWidthAndHeight = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updateWidthAndHeight);
    return () => window.removeEventListener('resize', updateWidthAndHeight);
  });

  return (
    <div className="accordion__section">
      {/* {`accordion__section ${activeSize && "notActive"}`} */}
      <div className={`accordion ${className/*  && 'action' */}`}>{children}</div>
      {/* <div
        ref={screen}
        style={{ maxHeight: `${height}` }}
        className="accordion__content"
      >
        <div
          className="accordion__text"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </div> */}
    </div>
  );
}

export { AccordionWrapper };
