import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
const InterviewDashboardSkeleton = () => {
    return (
        <SkeletonTheme color="#c4cdd5" highlightColor="#f4f6f8">
            <Form>
                <Row>
                    <Col
                        xs="12"
                        md="6"
                        lg="6"
                        className="my-2 px-4"
                    >
                        <div className="mb-2">
                            <Skeleton
                                height={"100%"}
                                width={"50%"}
                                style={{ lineHeight: 1.5 }}
                            />
                        </div>
                        <div className="mb-2">
                            <Skeleton
                                height={"100%"}
                                width={"100%"}
                                style={{ lineHeight: 2.5 }}
                            />
                        </div>
                    </Col>
                    <Col
                        xs="12"
                        md="6"
                        lg="6"
                        className="my-2 px-4"
                    >
                        <div className="mb-2">
                            <Skeleton
                                height={"100%"}
                                width={"50%"}
                                style={{ lineHeight: 1.5 }}
                            />
                        </div>
                        <div className="mb-2">
                            <Skeleton
                                height={"100%"}
                                width={"100%"}
                                style={{ lineHeight: 2.5 }}
                            />
                        </div>
                    </Col>
                    <Col
                        xs="12"
                        md="6"
                        lg="6"
                        className="my-2 px-4"
                    >
                        <div className="mb-2">
                            <Skeleton
                                height={"100%"}
                                width={"50%"}
                                style={{ lineHeight: 1.5 }}
                            />
                        </div>
                        <div className="mb-2">
                            <Skeleton
                                height={"100%"}
                                width={"100%"}
                                style={{ lineHeight: 2.5 }}
                            />
                        </div>
                    </Col>
                    <Col
                        xs="12"
                        md="6"
                        lg="6"
                        className="my-2 px-4"
                    >
                        <div className="mb-2">
                            <Skeleton
                                height={"100%"}
                                width={"50%"}
                                style={{ lineHeight: 1.5 }}
                            />
                        </div>
                        <div className="mb-2">
                            <Skeleton
                                height={"100%"}
                                width={"100%"}
                                style={{ lineHeight: 2.5 }}
                            />
                        </div>
                    </Col>
                    <Col
                        xs="12"
                        md="6"
                        lg="6"
                        className="my-2 px-4"
                    >
                        <div className="mb-2">
                            <Skeleton
                                height={"100%"}
                                width={"50%"}
                                style={{ lineHeight: 1.5 }}
                            />
                        </div>
                        <div className="mb-2">
                            <Skeleton
                                height={"100%"}
                                width={"100%"}
                                style={{ lineHeight: 2.5 }}
                            />
                        </div>
                    </Col>
                    <Col
                        xs="12"
                        md="6"
                        lg="6"
                        className="my-2 px-4"
                    >
                        <div className="mb-2">
                            <Skeleton
                                height={"100%"}
                                width={"50%"}
                                style={{ lineHeight: 1.5 }}
                            />
                        </div>
                        <div className="mb-2">
                            <Skeleton
                                height={"100%"}
                                width={"100%"}
                                style={{ lineHeight: 2.5 }}
                            />
                        </div>
                    </Col>
                    <Col
                        xs="12"
                        md="6"
                        lg="6"
                        className="my-2 px-4"
                    >
                        <div className="mb-2">
                            <Skeleton
                                height={"100%"}
                                width={"50%"}
                                style={{ lineHeight: 1.5 }}
                            />
                        </div>
                        <div className="mb-2">
                            <Skeleton
                                height={"100%"}
                                width={"100%"}
                                style={{ lineHeight: 2.5 }}
                            />
                        </div>
                    </Col>
                    <Col
                        xs="12"
                        md="6"
                        lg="6"
                        className="my-2 px-4"
                    >
                        <div className="mb-2">
                            <Skeleton
                                height={"100%"}
                                width={"50%"}
                                style={{ lineHeight: 1.5 }}
                            />
                        </div>
                        <div className="mb-2">
                            <Skeleton
                                height={"100%"}
                                width={"100%"}
                                style={{ lineHeight: 2.5 }}
                            />
                        </div>
                    </Col>
                    <Col
                        xs="12"
                        md="6"
                        lg="6"
                        className="my-2 px-4"
                    >
                        <div className="mb-2">
                            <Skeleton
                                height={"100%"}
                                width={"50%"}
                                style={{ lineHeight: 1.5 }}
                            />
                        </div>
                        <div className="mb-2">
                            <Skeleton
                                height={"100%"}
                                width={"100%"}
                                style={{ lineHeight: 2.5 }}
                            />
                        </div>
                    </Col>
                    <Col
                        xs="12"
                        md="6"
                        lg="6"
                        className="my-2 px-4"
                    >
                        <div className="mb-2">
                            <Skeleton
                                height={"100%"}
                                width={"50%"}
                                style={{ lineHeight: 1.5 }}
                            />
                        </div>
                        <div className="mb-2">
                            <Skeleton
                                height={"100%"}
                                width={"100%"}
                                style={{ lineHeight: 2.5 }}
                            />
                        </div>
                    </Col>
                </Row>
                <Row className="justify-content-end mt-4 ">
                    <Col xs="12" md="6" lg="6">
                        <Row className="row_gutter px-3">
                            <Col className="m-0 d-grid">
                                {" "}
                                <div className="mb-2">
                                    <Skeleton
                                        height={"100%"}
                                        width={"100%"}
                                        style={{ lineHeight: 2.5 }}
                                    />
                                </div>
                            </Col>
                            <Col className="d-grid">
                                <div className="mb-2">
                                    <Skeleton
                                        height={"100%"}
                                        width={"100%"}
                                        style={{ lineHeight: 2.5 }}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>


        </SkeletonTheme>
    )
}

export default InterviewDashboardSkeleton