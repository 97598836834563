import React from "react";

import { useNavigate } from "react-router-dom";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

import logout from "../../Assets/Images/Home_Page/logout.svg";
import logout_white from "../../Assets/Images/Home_Page/logout _white.svg";

import Navbar from "react-bootstrap/Navbar";
import { useProvider } from "../hooks/useProvider/useProvider";

import "./Header.scss";

const Header = ({ className }) => {
    const { userDetails, setIsLoading } = useProvider();
    const navigate = useNavigate();
    const logoutHandler = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("userDetails");
        localStorage.removeItem("candidateDetails");
        localStorage.removeItem("questionDetails");
        localStorage.removeItem("activeTab");
        localStorage.removeItem("totalScores");
        setIsLoading(false);
        navigate("/", { replace: true });
    };
    const userDetailsLog = localStorage.getItem("userDetails");
    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
             LogOut
        </Tooltip>
    );
    return (
        <>
            {userDetailsLog !== null && (
                <Navbar className={`InterviewHeader ${className} `}>
                    <div className="InterviewHeader__Title hide_xs">
                        <Navbar.Toggle />
                        <Navbar.Collapse className="justify-content-end">
                            <Navbar.Text className="d-flex">
                                {/* <img
                                    className="InterviewHeader__bell"
                                    src={bell}
                                    alt=""
                                /> */}
                                <div className="InterviewHeader__text fw-bold">
                                    Hi, {userDetails?.fullName}
                                </div>{" "}
                                |
                                <div data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-title="Tooltip on bottom">
                                <OverlayTrigger
                                placement="right"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltip}
                            >
                                <img
                                    className="InterviewHeader__logout"
                                    src={logout}
                                    alt=""
                                    onClick={logoutHandler}
                                    
                                />
                                </OverlayTrigger>
                                </div>
                                
                            </Navbar.Text>
                        </Navbar.Collapse>
                    </div>
                    <div className=" hide_md align">
                        <div className=" d-flex justify-content-between px-5">
                            <div className="InterviewHeader__text text-white px-5">
                                Hi, {userDetails?.fullName}
                            </div>
                            <div className="InterviewHeader__logoutText d-flex">
                                <img
                                    className="InterviewHeader__logout mx-2"
                                    src={logout_white}
                                    alt=""
                                    onClick={logoutHandler}
                                />
                                <div
                                    className="InterviewLogut__text hide_md ps-1 text-white"
                                    onClick={logoutHandler}
                                >
                                    LogOut
                                </div>
                            </div>
                        </div>
                    </div>
                </Navbar>
            )}
        </>
    );
};

export { Header };
